<script>
import CircleLoader from '@/components/CircleLoader.vue';
import ProductVentaPostDetail from '@/components/AdminComponents/VentaDetailComponents/ProductVentaPostDetail.vue'
import InfoVentaPostDetail from '@/components/AdminComponents/VentaDetailComponents/InfoVentaPostDetail.vue';
import OrdenService from '@/services/OrdenService';
import EstadosService from '@/services/EstadosService';

    export default{
        name: 'NuevoComponente',
        components: {
            CircleLoader,
            ProductVentaPostDetail,
            InfoVentaPostDetail
        },
        mounted(){
            
            this.editOptionsPedido = JSON.parse(JSON.stringify(
                {
                    fecha_entrega: this.transformDateDMYToYMD(this.pedido.fecha_entrega),
                    id_estado: this.pedido.estado_pedido.id
                }
            ))
            console.log(this.transformDateYMDToDMY(this.editOptionsPedido.fecha_entrega))
            this.getEstados()
            this.getOrdenDetalleByID()
        },
        props: {
            id: Number,
            pedido: Object
        },
        data(){
            return{
                orden: [],
                estados_paquete: [],
                iconsMap: {
                    1: '🟠 ',
                    2: '🟡 ',
                    3: '🟢 ',
                    4: '🔵 '
                },
                editOptionsPedido: {
                    estado_pedido: {
                        id: 0,
                        estado: ''
                    }
                },
                stateCharge: false
            }
        },
        methods: {
            async getOrdenDetalleByID() {
                this.stateCharge = true
                const response = await OrdenService.getJoinOrdenDetalleByID(this.$cookies.get('token'), this.id);
                if (response.success) {
                    this.orden = response.orden;
                }
                this.stateCharge = false

            },
            async getEstados(){
                this.stateCharge = true
                const response = await EstadosService.getEstados(this.$cookies.get('token'))
                if(response.success){
                    this.estados_paquete = response.estados.data;
                    this.estados_paquete.forEach(estado_paq => {
                        if(this.iconsMap[estado_paq.id]){
                            estado_paq.estado = this.iconsMap[estado_paq.id] + estado_paq.estado
                        }
                    });
                }
                this.stateCharge = false

            },
            async updatePedido(){
                const pedidoUpdated = {}
                if(this.transformDateYMDToDMY(this.editOptionsPedido.fecha_entrega) !== this.pedido.fecha_entrega && this.editOptionsPedido.fecha_entrega !== "") 
                    pedidoUpdated.fecha_entrega = this.editOptionsPedido.fecha_entrega

                if(this.editOptionsPedido.id_estado !== this.pedido.estado_pedido.id)
                    pedidoUpdated.id_estado = this.editOptionsPedido.id_estado

                console.log({pedidoUpdated})

                const response = await OrdenService.updateOrden(this.$cookies.get('token'), this.id, pedidoUpdated)
                if(response.success){
                    this.$router.push({ name: 'pedidos' })
                    alert(`El pedido con id: ${this.id} se ha modificado`);
                }else {
                    alert('Algo ha salido mal :(, por favor intente de nuevo mas tarde');
                }
            },
            transformDateDMYToYMD(stringFecha){ // 29/02/2024 to 2024-02-29
                if(stringFecha === null || stringFecha === undefined){
                    return stringFecha
                }

                const partes = stringFecha.split('/')
                return `${partes[2]}-${partes[1]}-${partes[0]}`
            },
            transformDateYMDToDMY(stringFecha){ // 2024-02-29 to 29/02/2024
                if(stringFecha === null || stringFecha === undefined){
                    return null
                }
                
                const partes = stringFecha.split('-')
                return `${partes[2]}/${partes[1]}/${partes[0]}`
            },
            changeFormat(number){
                const formatter = new Intl.NumberFormat('es-MX',{
                    style: 'currency',
                    currency: 'MXN'
                })

                return formatter.format(number)
            }
        },
        computed: {
            domicilioToString: function (){
                const objDomicilio = this.pedido.domicilio
                let domicilio = `Ciudad: ${objDomicilio.ciudad}, Colonia: ${objDomicilio.colonia}, Calle: ${objDomicilio.calle}, #Ext: ${objDomicilio.num_ext}, #Int: ${objDomicilio.num_int}, CP: ${objDomicilio.c_p}`;

                return domicilio
            },
            fechaEntrega: function(){
                return (this.pedido.fecha_entrega===null) ? 's/f' : this.pedido.fecha_entrega
            },
            infoCliente: function(){
                return [
                    {
                        title_row: 'Nombre',
                        content_row: `${this.pedido.usuario.nombre} ${this.pedido.usuario.apellido_p} ${this.pedido.usuario.apellido_m}`
                    },
                    {
                        title_row: 'Correo',
                        content_row: `${this.pedido.usuario.correo}`
                    }
                ]
            },
            direccion: function(){
                return [
                    {
                        title_row: 'Domicilio',
                        content_row: `${this.domicilioToString}`
                    }
                ]
            },
            infoVenta: function(){
                return [
                    {
                        title_row: 'Fecha solicitud',
                        content_row: `${this.pedido.fecha_solicitud}`
                    },
                    {
                        title_row: 'Fecha entrega',
                        content_row: `${this.fechaEntrega}`
                    },
                    {
                        title_row: 'Total',
                        content_row: `${this.changeFormat(this.pedido.total)}`
                    }
                ]
            },
            fecha_actual: function() {
                const ahora = new Date();
                // Formatear la fecha en formato YYYY-MM-DD (el formato esperado por el input type="date")
                const fechaFormateada = ahora.toISOString().split('T')[0];
                return fechaFormateada;
            },
            itsCompleteToUpdate: function(){
                if(
                    (this.transformDateYMDToDMY(this.editOptionsPedido.fecha_entrega) !== this.pedido.fecha_entrega &&
                    this.editOptionsPedido.fecha_entrega !== "") ||
                    this.editOptionsPedido.id_estado !== this.pedido.estado_pedido.id
                ){
                    return true
                }
                return false
            }
        }
    }
</script>

<template>
    <article :class="['view_detail']">
        <header :class="['header-form']">
            <router-link :to="{ name: 'pedidos'}"><i class="fa-solid fa-arrow-left"></i> volver</router-link>
            <div :class="['opciones__pedido']">
                <span :class="['text']"> ID:<strong>{{ id }}</strong></span>
                <input
                    :class="['select_options']" 
                    type="date" 
                    :min="fecha_actual"
                    v-model="editOptionsPedido.fecha_entrega"
                >
                <select
                    :class="['select_options']"
                    v-model="editOptionsPedido.id_estado"
                >
                    <option 
                        disabled 
                        :value="0"
                    >
                        Cambie estado paquete
                    </option>
                    <option
                        v-for="estado_paq in estados_paquete" :key="estado_paq.id"
                        :value="estado_paq.id"
                    >
                        {{ estado_paq.estado }}
                    </option>
                </select>
                <button 
                    type="button" 
                    :class="[{'outline-btn': itsCompleteToUpdate, 'disabled-btn': !itsCompleteToUpdate},'round','bsm']"
                    @click="updatePedido"
                    :disabled="!itsCompleteToUpdate"
                >
                    Guardar
                </button>
            </div>
        </header>
        <div :class="['short-view']">
            <section
                :class="['short-view-child']"
            >
                <h2 :class="['titulo_vista']">Detalles de Pedido</h2>
                <InfoVentaPostDetail
                    :title="'Información del cliente'"
                    :rows="infoCliente"
                />

                <InfoVentaPostDetail
                    :title="'Dirección'"
                    :rows="direccion"
                />

                <InfoVentaPostDetail
                    :title="'Información del pedido'"
                    :rows="infoVenta"
                />
            </section>
            <section
                :class="['short-view-child']"
            >
                <h2 :class="['titulo_vista']">Productos</h2>
                <CircleLoader :activo="stateCharge" />
                <ProductVentaPostDetail 
                    v-for="(detalle,index) in orden" :key="index"
                    :itemOrden="detalle"
                    :index="index+1"
                />
            </section>
        </div>
    </article>
</template>

<style scoped>
    .view_detail {
        display: flex;
        flex-flow: column wrap;
        padding: 0rem 0.5rem;
        justify-content: flex-start;
        background-color: #fff;
    }

    .view_detail .header-form{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        padding: 0.3rem 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 7px 5px -3px;
        margin-bottom: 1rem;
        background-color: #fff;
    }

    .header-form a{
        color: black;
        font-family: 'Quicksand';
        font-weight: bold;
        font-size: 1rem;
    }

    .header-form .opciones__pedido{
        display: flex;
        flex-flow: row wrap;
        gap: 0.5rem;
        align-items: center;
    }
    
    .header-form .text{
        color: black;
        font-family: 'Quicksand';
        font-size: 1rem;
        background-color: transparent;
        margin: 0;
        padding: 0;
    }

    .text{
        color: black;
        font-family: 'Quicksand';
        font-size: 0.9rem;
        background-color: #fff;
        margin: 0.1rem 1rem;
        padding: 0.2rem;
    }

    .select_options{
        flex-grow: 1;
        padding: 0.3rem 0.5rem;
        border: solid 1px #e1e3e1;
        background-color: white;
        font-family: 'Roboto', sans-serif;
        border-radius: 0.3rem;
        font-size: 0.9rem;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
        text-transform: capitalize;
    }

/*division*/
    .short-view{
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        gap: 0.5rem;
        
    }

    .short-view-child{
        flex: 1;
    }

    .view-form{
        padding: 0 0.5rem;
    }

    @media (max-width: 800px){
        .short-view{
            flex-flow: column wrap;
            align-items: stretch;
            flex: 0;
        }
    }
</style>