<script>
import QRService from '@/services/QRService'
import { BCollapse } from 'bootstrap-vue'
    export default{
        name: 'QRPost',
        props: {
            qr: Object,
            selected: Boolean,
            stateCharge: Boolean
        },
        data(props){
            return{
                name_img: '',
                extension: '',
                editingName: false,
                selectedFile: null,
            }
        },
        emits:['updateNameQR','deleteImgQR','setLocalQR','resetLocalQR'],
        methods: {
            sendQR(){
                if(!this.selected){
                    this.$emit('setLocalQR',JSON.parse(JSON.stringify(this.qr)))
                }else{
                    this.$emit('resetLocalQR')
                }
            },
            getImageUrl(imageName) {
                // Asigna la ruta base de las imágenes
                const baseUrl = 'https://api.puntotuxtleco.com.mx/img/qr/';

                // Combina la ruta base con el nombre de la imagen
                return baseUrl + imageName;
            },
            async cambiarNombreImgQR(){
                let new_name = `${this.name_img}.${this.extension}`
                if(new_name === this.qr.name_qr){
                    console.log("No se han realizado cambios :|")
                    this.changeStateEditing(false)
                }else{
                    this.$emit('updateNameQR',this.qr.id, this.qr.name_qr, new_name)
                    await this.changeStateEditing(false)
                }
            },
            async deleteImgQR(){
                if(confirm(`Esta seguro que desea eliminar este QR?`)){
                    this.$emit('deleteImgQR',this.qr.id)
                }
            },
            changeStateEditing(newState){
                this.editingName = newState
            },
            activateEditing(){
                let name = this.qr.name_qr
                const splitName = name.split('.')
                this.name_img = splitName[0]
                this.extension = splitName[1]
                this.changeStateEditing(true);
            },
            cancelChangeName(){
                this.changeStateEditing(false);
                this.name_img = this.qr.name_qr
            },

        },
        computed: {
            uriImgPrueba: function(){
                const randomSuffix = Math.random().toString(36).substring(7)
                const baseUrl = 'https://api.puntotuxtleco.com.mx/img/qr/';
                return `${baseUrl}${this.qr.name_qr}?${randomSuffix}`
            },
        }
    }
</script>

<template>
    <article 
        :class="['post-img',{'activeimg':selected}]"

        @click="sendQR"
    >
        <div :class="['img-container-opts']">
            <img :src="uriImgPrueba" alt="imagenQR" loading="lazy"/>
            <div :class="['opts-container']">
                <!-- <div :class="['button-container','center']"> -->
                    <button 
                        :class="['delete-btn','round','bsm']"
                        type="button"
                        title="Eliminar"
                        @click="deleteImgQR"
                    >
                        <i class="fa-solid fa-trash"></i> Eliminar
                    </button>
                    <!-- <button 
                        :class="['normal-btn','round','bsm']"
                        type="button" 
                        title="Cambiar Imagen"
                        @click="activateInput('changefileqr')"
                    >
                        <i class="fa-solid fa-camera-rotate"></i> Editar imagen
                    </button> -->
                    <button 
                        v-show="!editingName"
                        :class="['normal-btn','round','bsm']"
                        type="button" 
                        title="Cambiar Nombre"
                        @click="activateEditing"
                    >           
                        <i class="fa-solid fa-pencil"></i> Editar nombre
                    </button>
                <!-- </div> -->
            </div>
        </div>
        <div :class="['button-container']">
            <button 
                v-show="editingName"
                :class="['outline-btn','round','bsm','save-btn']"
                type="button"
                title="Guardar nombre"
                @click="cambiarNombreImgQR"
            >
                <i class="fa-solid fa-floppy-disk"></i>
            </button>
            <button 
                v-show="editingName"
                :class="['delete-btn','round','bsm','delete-btn']"
                type="button"
                title="Cancelar"
                @click="cancelChangeName"
            >
                <i class="fa-solid fa-xmark"></i>
            </button>
            <span v-show="editingName" >ext: {{ extension }} </span>
        </div>
          
        <section v-show="editingName" :class="['change-nameqr','field_textarea','field-cyan']">
            <input
                :class="['input_field','round']"
                type="text" 
                v-model="name_img"
                placeholder="..."
                id="nombre_qr"
            >
            <label :class="['msg','m-l']" for="nombre_qr">Nombre QR</label>
        </section>
        <p v-show="!editingName" title="Nombre de la imagen"> {{ qr.name_qr }} </p>
    </article>
</template>

<style scoped>

   .post-img{
        flex-basis: 150px;
        border-radius: 0.5rem;
        border: solid 2px #0dabd3;
        transition: 
            box-shadow 0.3s ease-in,
            transform 0.3s ease-out;
        display: flex;
        flex-flow: column wrap;
    }

    .activeimg,
    .post-img:hover{
        box-shadow: rgba(46, 185, 240, 0.4) -5px 5px, rgba(46, 185, 240, 0.3) -10px 10px, rgba(446, 185, 240, 0.2) -15px 15px, rgba(46, 185, 240, 0.1) -20px 20px, rgba(46, 185, 240, 0.05) -25px 25px;
        transform: translate(4px, -4px);
    }
    .img-container-opts{
        border-radius: 0.3rem 0.3rem 0  0;
        position: relative;
        overflow: hidden;
    }

    .img-container-opts:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        background-color: #fff;
        opacity: 0;
        transition: opacity .3s ease-out;
    }

    .img-container-opts:hover:before { 
        opacity: 0.6;
    }

    .img-container-opts img {        
        width: 100%;
        height: auto;
        max-height: 125px;
        border-radius: 0.5rem 0.5rem 0 0;
        padding: 0.3rem;
        object-fit: contain;
    }

    .img-container-opts .opts-container{
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        color: #444;
        position: absolute;
        top: 100%;
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 0.3rem 0.3rem;
        transition: top 0.3s ease-in;
    }

    .img-container-opts:hover .opts-container{
        top: 0;
    }

    .post-img p {
        color: #fff;
        margin: 0;
        flex-grow: 1;
        background-color: #0bb3dd;
        font-family: 'Quicksand';
        font-weight: bold;
        font-size: 0.9rem;

        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        transition: 
            font-weight 0.3s ease-in-out
        ;
    }

    .button-container{
        margin-block: 0.3rem;
    }

    .change-nameqr input{
        max-width: 175px;
    }

    .field input {
        padding: 0.3rem;
        font-size: 0.9rem;
    }

    .field label {
        font-size: 0.8rem;
    }
</style>