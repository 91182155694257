<script>
import TiendaTable from '@/components/AdminComponents/TiendaComponents/TiendaTable.vue'
    export default{
        name: 'TiendaView',
        components: {
            TiendaTable,

        },
        data(){
            return{
                
            }
        },
        methods: {
            
        }
    }
</script>

<template>
    <div>
        <h1>Lista de tiendas</h1>
        <h2>Btn to add new Tienda</h2>
        <TiendaTable />
    </div>
</template>

<style scoped>
    
</style>