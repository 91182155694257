<template>
    <div :class="['container-pay']">
        <HeaderPay :estado="estado"/>
        <div :class="['container_components']">
            <UserInfo v-if="estado==1" @setUserData="getInfoUser" @setDomicilioSelected="getDomicilioSelected" @setNewEstado="cambiarEstado"/>
            <VentaResumen v-else-if="estado==2" :carrito="carrito" @setNewEstado="cambiarEstado" @putOrden="saveOrden"/>
            <ResolucionPago v-else-if="estado==3" />
        </div>
        
    </div>
</template>

<script>
/*Importando los servicios*/
//import ProductosService from '@/services/ProductosService'

import {getCarrito_detalle_2, getSelectedProductos} from '@/services/ProductosService'

/*Importando  componentes hijo*/
import HeaderPay from '@/components/UserComponents/HeaderPay.vue'
import VentaResumen from '@/components/UserComponents/VentaResumen.vue'
import UserInfo from '@/components/UserComponents/UserInfo.vue'
import ResolucionPago from '@/components/UserComponents/ResolucionPago.vue'

    export default {
        name: 'PayView',
        components: {
            HeaderPay,
            VentaResumen,
            UserInfo,
            ResolucionPago,
        },
        data(){
            return {
                usuario:{
                    //Estos datos son asignados por automatico
                    /*id: '',
                    nombre: '',
                    apellido_p: '',
                    apellido_m: '',
                    correo: '',*/
                },
                domicilio:{
                    
                },
                metodo: {
                    id: '1',
                    metodo: 'Tarjeta'
                },
                fecha_solicitud: '',
                fecha_entrega: '',
                estado: (this.$cookies.isKey('estado')) ? parseInt(this.$cookies.get('estado')) : 1,
                carrito: [],
            }
        },
        mounted(){
            document.title = 'PuntoTuxtleco | Pago'
            this.getIdsCarrito()
            let now = new Date()
            this.fecha_solicitud = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate()
            this.fecha_entrega = this.fecha_solicitud
            
            
            /*this.$cookies.set('data',{
                name: 'Luis',
                date: '04/06/23'
            })

            console.log("La cookie "+this.$cookies.get('data').name)*/
        },
        methods: {
            cambiarEstado(newEstado){
                this.$cookies.set('estado', newEstado)
                this.estado = newEstado
            },
            getInfoUser(usuario){
                this.usuario = usuario
            },
            getDomicilioSelected(domicilio){
                this.domicilio = domicilio
            },
            async getIdsCarrito(){
                let ids_format = ""
                //const ids 
                this.carrito = await getCarrito_detalle_2(this.$cookies.get('id_carrito'), this.$cookies.get('token'))
                
                /*for (let i = 0; i < ids.length; i++) {
                    ids_format += (i+1 == ids.length) ? "'"+ids[i].id_producto+"'" : "'"+ids[i].id_producto+"',"
                }
                
                const productos = await getSelectedProductos(ids_format)

                if(productos.success){
                    for (let i = 0; i < ids.length; i++) { //2
                        for (let j = 0; j < productos.response.length; j++) { //2
                            if(ids[i].id_producto == productos.response[j].id){
                                const imagenesProd = await ImgProductosService.getImagenesByIdProducto(productos.response[j].id)
                                if(imagenesProd.success){
                                    productos.response[j].imagenes = imagenesProd.imagenes
                                    console.log(productos.response[j])
                                }
                                ids[i].producto = productos.response[j]
                                delete ids[i].id_producto;
                                break;
                            }
                        }
                    }
                }
                this.carrito = ids*/
            },
            saveOrden(total, productos){
                this.$cookies.set('orden',{
                    total: total,
                    fecha_solicitud: this.fecha_solicitud,
                    id_usuario: this.usuario.id,
                    id_carrito: this.$cookies.get('id_carrito'),
                    id_metodo: this.metodo.id,
                    id_domicilio: this.domicilio.id,
                    id_estado: 1,
                    productos: productos
                })
            }
        }
    }
</script>

<style>
.container-pay{

    display: flex;
    flex-flow: column wrap;
}

/*Estilos HeaderPay*/
    .header-container{
        background-color: #4DD3F4;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

        .header-container header {
            max-width: 900px;
            width: 100%;
            /*border: solid 1px #000;*/
        } 

            header .title-view {
                margin: 0.5rem 0;
                font-family: 'Anton';
                font-size: 1.5rem;
                color: white;
                text-shadow: 1px 1px 2px black;
                text-align: left;
            }

            header .stage{
                padding: 1rem 0;
                border-top: solid 2px #9ae4f7;

                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
            }

                .stage .normal{
                    list-style-type: none;
                    font-family: 'Roboto';
                    
                    padding: 0.7rem;
                    -moz-border-radius: 0.5rem;
                    -webkit-border-radius: 0.5rem;
                    border-radius: 0.5rem;
                }

                .stage .active{
                    background-color: #2ab5d8;
                    font-weight: 600;
                    color: #fff;
                }
    
    /*Estilos de componentes main*/
    .container_components{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        
        padding: 1.5rem 0 0 0;
        background-color: rgb(240, 245, 246);
    }

        .container-component{
            max-width: 900px;

            display: flex;
            flex-flow: column wrap;
            flex-basis: 100%;
        }

            .container-component .title-component{
                font-family: 'Roboto';
                font-size: 1.6rem;
                font-weight: 700;
                text-align: left;
                width: 100%;
                color: rgb(8, 45, 74);
            }

            .container-component .subtitle-component{
                font-family: 'Quicksand';
                font-size: 1.2rem;
                font-weight: 700;
            }

            .container-component .subtitlelow-component{
                font-family: 'Quicksand';
                font-size: 1.1rem;
                text-align: left;
                font-weight: 500;
            }

            .container-component section{
                max-width: 800px;
                width: 100%;
                display: flex;
                flex-flow: column wrap;
                gap: 0.3rem;
                
                background-color: #fff;
                border-bottom: dashed 3px #9ae4f7/*rgb(224, 243, 247)*/;
                padding: 0.5rem 0.8rem;
            }

            .header-subtitle-low{
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: center;

                background-color: #9ae4f7;
                padding: 0.4rem 0.5rem;
            }

                .container-buttons{
                    display: flex;
                    flex-flow: row wrap;
                    gap: 0.3rem;
                }

            .domicilio-post-detail{
                font-family: 'Roboto';

                display: flex;
                flex-flow: row nowrap;
                align-items: center;

                border: solid 2px #ddd;
                border-radius: 0.5rem;

                transition-property: background-color border;
                transition-duration: 0.3s;
                transition-timing-function: ease-in;
            }

            .domicilio-post-detail:hover{
                cursor: pointer;
                border: solid 2px #0dabd3;
            }

            .domicilio-post-detail-active{
                border: solid 2px #4DD3F4;
                background-color: #9ae4f752;
            }

            .domicilio-post-detail .input-radio{
                appearance: none;
                padding: 0.45rem;
                border: 1px solid #4DD3F4;
                border-radius: 60%;
                margin: 0.5rem;
            }

            .domicilio-post-detail .input-radio:checked{
                background-image: radial-gradient(#0dabd3 0%, #0dabd3 50%, transparent 55%);
            }

            

            .domicilio-post-detail .domicilio-text{
                
                font-family: 'Quicksand';
                font-size:  0.9rem;
                font-weight: 600;
                color: #000;

                text-align: left;

            }



    /*Estilos afectados a componentes dinamicos*/
    /*contenedor de inputs que van en la misma linea*/
    .line-material-form{
        display: flex;
        flex-flow: row wrap;
        gap: 0.3rem 0.2rem;
    }

    /*container-input-domicilio*/
    .container-input-domicilio{
        display: flex;
        flex-flow: column wrap;
        gap: 0.5rem 0.2rem;
        padding: 0.5rem 0rem;
    }

    /*Estilizando input estandar*/

    .input-material-form{
        position: relative; 
        display: flex;
        flex-flow: column wrap;
        flex-grow: 1;
        flex-shrink: 1;
    }

    .input-material-form input{
        background-color: transparent;
        border: solid 2px #4DD3F4;
        border-radius: 0.3rem;
        outline: none;
        padding: 0.4rem 0.3rem 0 0.5rem;
        margin: 0.3rem 0.5rem;
    }

    .input-material-form label{
        background-color: #fff;
    }

    .input-material-form .default-value{
        top: 5px;
        font-size: 13px;
        padding: 0 2px;
        color: #0DABD3;
    }

    .input-material-form input:valid{
        border: solid 2px #0DABD3;
    }

    .input-material-form input:focus{
        box-shadow: 0 0 3px #0DABD3;
    }

    .input-material-form input:focus ~ label,
    .input-material-form input:valid ~ label{
        top: 5px;
        font-size: 13px;
        padding: 0 2px;
        color: #0DABD3;
    }

    .input-material-form input:valid ~ label{
        color: #0DABD3;
    }

    .text-span{
        font-weight: 600;
        border-radius: 0.3rem;
        padding: 0.3rem 0;
    }

    .text-warning{
        background-color: #e7cc456c;
        color: #5a4604;
    }

    .text-danger{
        background-color: #f240727b;
        color: #86092C;
    }

    .btn-material{

        border: solid 2px #0DABD3;
        border-radius: 0.4rem;
        background-color: #9fe7f9;
        text-transform: uppercase;

        font-family: 'Roboto';
        font-weight: 500;
        font-size: 0.8rem;
        padding: 0.3rem 0.5rem;


        transition-property: background-color, box-shadow, color;
        transition-duration: 0.3s;
        transition-timing-function: ease;
    }

    .btn-material:hover{
        background-color: #0dabd3;
        color: #fff;
        box-shadow: 0px 0px 5px 2px #0dabd3;
    }

    .btn-material:disabled:hover{
        background-color: #9fe7f9;
        color: #777;
        box-shadow: 0px 0px 0px 0px #0dabd3;
    }

    .btn-send{
        margin: 0.5rem 3rem;
        align-self: flex-end;
    }

    .info-message{
        font-family: 'Roboto';
        font-weight: 300;
        font-style: italic;
        align-self: flex-end;
    }
</style>