<script>
    export default{
        name: 'AgregarProductoForm',
        mounted(){
            this.nuevo_producto = JSON.parse(JSON.stringify(this.producto))
        },
        props: {
            producto: Object,
            productoresTiny: Array,
            categorias: Array,
            capacidades: Array,
            empaques: Array,
            qrs: Array,
            imagenesprod: Array,
        },
        data(){
            return{
                nuevo_producto: {},
                imagen: {
                    id:0,
                    uri_img: ''
                },
                change_imgprod: false
            }
        },
        emits: ['createProducto','editProducto'],
        methods: {
            async agregarNuevoProducto(){
                const ids = this.prepararArrayAgregarIds()
                if (ids.length>0){
                    this.nuevo_producto.ids_imagenesprod = ids
                }
                this.$emit('createProducto', this.nuevo_producto)
            },
            async actualizarProducto(){
                const edited_producto = {}
                this.nuevo_producto.activo = Number(this.nuevo_producto.activo)
                for(let [key, val] of Object.entries(this.producto)){
                    if(this.nuevo_producto.hasOwnProperty(key) && key !== 'imagenes'){
                        if(this.nuevo_producto[key] !== val){
                            edited_producto[key] = this.nuevo_producto[key]
                        }
                    }
                }
                const {ids_agregar, ids_eliminar} = this.verifyImgprodChange();
                edited_producto['ids_agregar'] = ids_agregar;
                edited_producto['ids_eliminar'] = ids_eliminar;
                console.log({edited_producto})
                this.$emit('editProducto', this.producto.id, edited_producto)
            },
            tamañoCadena(cadena){
                if(cadena===undefined){
                    return 0
                }
                return cadena.length
            },
            existeImagenConIDNuevoProducto(id){
                if(this.nuevo_producto.imagenes === undefined) return false
                return this.nuevo_producto.imagenes.some(imagen => imagen.id===id)
            },
            existeImagenConIDProducto(id){
                if (this.producto.imagenes === undefined) return false
                return this.producto.imagenes.some(imagen => imagen.id === id)
            },
            agregarNuevaImagen(){
                this.nuevo_producto.imagenes.push(this.imagen)
                this.imagen={
                    id: 0,
                    uri_img: ''
                }
                this.verifyImgprodChange()
            },
            eliminarNuevaImagen(index){
                this.nuevo_producto.imagenes.splice(index,1)
                this.verifyImgprodChange()
            },
            prepararArrayAgregarIds(){
                const ids_agregar = this.nuevo_producto.imagenes.filter(imagen => {
                    if(!this.existeImagenConIDProducto(imagen.id)){
                        return imagen.id
                    }
                }).map(imagen => imagen.id)

                return ids_agregar
            },
            prepararArrayEliminarIds(){
                const ids_eliminar = this.producto.imagenes.filter(imagen => {
                    if(!this.existeImagenConIDNuevoProducto(imagen.id)){
                        return imagen.id
                    }
                }).map(imagen => imagen.id)

                return ids_eliminar
            },
            uriImgprod(uriImg){
                const randomSuffix = Math.random().toString(36).substring(7);
                const baseUrl = 'https://api.puntotuxtleco.com.mx/img/productos/';
                return `${baseUrl}${uriImg}?${randomSuffix}`
            },
            verifyImgprodChange(){
                const ids_agregar = this.prepararArrayAgregarIds()
                const ids_eliminar = this.prepararArrayEliminarIds()
                
                if(ids_agregar.length>0 || ids_eliminar.length>0) this.change_imgprod = true;
                else this.change_imgprod = false;
                return {ids_agregar: ids_agregar, ids_eliminar: ids_eliminar}
            }
        },
        computed: {
            estadoProductoActivo: function () {
                return (this.nuevo_producto.activo) ? 'Activo' : 'Inactivo'
            },
            itsCompleteToSave: function(){
                if(!this.nuevo_producto.nombre || this.nuevo_producto.precio<=0 || this.nuevo_producto.cantidad<=0 || !this.nuevo_producto.descripcion || !this.nuevo_producto.detalles || !this.nuevo_producto.especificaciones || this.nuevo_producto.id_productor===0 || this.nuevo_producto.id_capacidad===0 || this.nuevo_producto.id_empaque===0 || this.nuevo_producto.id_categoria===0 || this.nuevo_producto.id_qr===0){
                    return false
                }
                return true
            },
            itsCompleteToUpdate: function(){
                if(
                    this.nuevo_producto.nombre !== this.producto.nombre ||
                    this.nuevo_producto.precio !== this.producto.precio ||
                    this.nuevo_producto.descripcion !== this.producto.descripcion ||
                    this.nuevo_producto.detalles !== this.producto.detalles ||
                    this.nuevo_producto.especificaciones !== this.producto.especificaciones ||
                    this.nuevo_producto.cantidad !== this.producto.cantidad ||
                    this.nuevo_producto.activo != this.producto.activo ||
                    this.nuevo_producto.id_productor !== this.producto.id_productor ||
                    this.nuevo_producto.id_capacidad !== this.producto.id_capacidad ||
                    this.nuevo_producto.id_empaque !== this.producto.id_empaque ||
                    this.nuevo_producto.id_categoria !== this.producto.id_categoria ||
                    this.nuevo_producto.id_qr !== this.producto.id_qr ||
                    this.change_imgprod
                ){
                    return true
                }
                return false
            },
            activateBtnAñadir: function() {
                if(this.imagen.id===0){
                    return false
                }
                return true
            },
            activateEditOptions: function() {
                if (this.nuevo_producto.id === 0){
                    return false
                }
                return true
            }
        }
    }
</script>

<template>
    <div :class="['template_form']">
        <form enctype="multipart/form-data">
            <section
                :class="['option-check']"
            >
                <label :class="['label_check']">Estado: <strong>{{ estadoProductoActivo }}</strong> </label>
                <div :class="['check-field']">
                    <input 
                        v-model.number="nuevo_producto.activo"
                        id="activo_producto" 
                        :class="['offscreen','original_check']" 
                        type="checkbox"
                    >
                    <label for="activo_producto" :class="['new_check']">.</label>
                </div>
            </section>

            <div :class="['field_textarea','field-cyan']">
                <input
                    v-model="nuevo_producto.nombre"
                    :class="['input_field','round']"
                    type="text"
                    id="nombre_producto"
                    placeholder="..."
                >
                <label :class="['msg','m-l']" for="nombre_producto">Nombre</label>
            </div>
            <div :class="['container-field-input']">
                <div :class="['field_textarea','field-cyan']">
                    <input
                        v-model.number="nuevo_producto.precio"
                        :class="['input_field','round']"
                        type="text"
                        id="precio_producto"
                        placeholder="..."
                    >
                    <label :class="['msg','m-l']" for="precio_producto">Precio</label>
                </div>
                <div :class="['field_textarea','field-cyan']">
                    <input
                        v-model.number="nuevo_producto.cantidad"
                        :class="['input_field','round']"
                        type="text"
                        id="cantidad_producto"
                        placeholder="..."
                    >
                    <label :class="['msg','m-l']" for="cantidad_producto">Cantidad</label>
                </div>
            </div>
            <div :class="['field_textarea','field-cyan']">
                <textarea 
                    v-model="nuevo_producto.descripcion" 
                    :class="['input_field','round','noresize']"
                    id="descripcion_producto"
                    maxlength="200"
                    placeholder="..."
                ></textarea>
                <label :class="['msg','m-l']" for="descripcion_producto">Descripcion</label>
                <span :class="['msg','m-r']"> {{ tamañoCadena(nuevo_producto.descripcion) }}/200</span>
            </div>

            <div :class="['field_textarea','field-cyan']">
                <textarea 
                    v-model="nuevo_producto.detalles" 
                    :class="['input_field','round','noresize']"
                    id="detalles_producto"
                    maxlength="200"
                    placeholder="..."
                ></textarea>
                <label :class="['msg','m-l']" for="detalles_producto">Detalles</label>
                <span :class="['msg','m-r']"> {{ tamañoCadena(nuevo_producto.detalles) }}/200</span>
            </div>

            <div :class="['field_textarea','field-cyan']">
                <textarea 
                    v-model="nuevo_producto.especificaciones"
                    :class="['input_field','round','noresize']"
                    id="especificaciones_producto"
                    maxlength="200"
                    placeholder="..."
                ></textarea>
                <label :class="['msg','m-l']" for="especificaciones_producto">Especificaciones</label>
                <span :class="['msg','m-r']"> {{ tamañoCadena(nuevo_producto.especificaciones) }}/200</span>
            </div>

            <section :class="['select-design']">
                <select 
                    :class="['select_options']" 
                    v-model="nuevo_producto.id_productor"
                >
                    <option 
                        disabled 
                        :value="0"
                    >
                        Seleccione a un productor
                    </option>
                    <option
                        v-for="productor in productoresTiny" :key="productor.id"
                        :value="productor.id"
                    >
                        {{ productor.nombre }}
                    </option>
                </select>

                <select
                    :class="['select_options']" 
                    v-model="nuevo_producto.id_categoria"
                >
                    <option 
                        disabled 
                        :value="0"
                    >
                        Seleccione una categoria
                    </option>
                    <option
                        v-for="categoria in categorias" :key="categoria.id"
                        :value="categoria.id"
                    >
                        {{ categoria.nombre }}
                    </option>
                </select>

                <select
                    :class="['select_options']" 
                    v-model="nuevo_producto.id_capacidad"
                >
                    <option 
                        disabled 
                        :value="0"
                    >
                        Seleccione una capacidad
                    </option>
                    <option
                        v-for="capacidad in capacidades" :key="capacidad.id"
                        :value="capacidad.id"
                    >
                        {{ capacidad.cantidad }} {{ capacidad.unidad_medida }}
                    </option>
                </select>

                <select
                    :class="['select_options']" 
                    v-model="nuevo_producto.id_empaque"
                >
                    <option 
                        disabled 
                        :value="0"
                    >
                        Seleccione un empaque
                    </option>
                    <option
                        v-for="empaque in empaques" :key="empaque.id"
                        :value="empaque.id"
                    >
                        {{ empaque.tipo }}
                    </option>
                </select>
                
                <select
                    :class="['select_options']" 
                    v-model="nuevo_producto.id_qr"
                >
                    <option 
                        disabled 
                        :value="0"
                    >
                        Seleccione un qr
                    </option>
                    <option
                        v-for="qr in qrs" :key="qr.id"
                        :value="qr.id"
                    >
                        {{ qr.name_qr }}
                    </option>
                </select>
            </section>
            <h2 :class="['titulo_vista']">Asociar Imagenes</h2>
            <section >
                <select
                    :class="['select_options','select_options_imgprod']" 
                    v-model="imagen"
                >
                    <option 
                        disabled 
                        :value="{id:0, uri_img:''}"
                    >
                        Seleccione una imagen
                    </option>
                    <!-- :disabled="true" -->
                    <option
                        v-for="imagenprod in imagenesprod" :key="imagenprod.id"
                        :value="imagenprod"
                        :disabled="existeImagenConIDNuevoProducto(imagenprod.id)"
                    >
                        <p> {{ imagenprod.uri_img }} </p>
                    </option>
                </select>
                <button
                    :class="[{'outline-btn':activateBtnAñadir, 'disabled-btn': !activateBtnAñadir}]"
                    :disabled="!activateBtnAñadir"
                    @click="agregarNuevaImagen"
                    type="button"
                >
                    Añadir
                </button>
            </section>
            <section :class="['box_imgprod']">
                <article 
                    v-for="(img, index) in nuevo_producto.imagenes"
                    :class="['post-img']"
                    @click="eliminarNuevaImagen(index)"
                >
                    <img
                        :src="uriImgprod(img.uri_img)"
                        loading="lazy"
                    >
                    <span>Eliminar</span>
                </article>
            </section>

            <div
                :class="['button-container','end']"
            >
                <button
                    v-if="!activateEditOptions"
                    :class="[{'success-btn': itsCompleteToSave, 'disabled-btn': !itsCompleteToSave},'round']"
                    type="button"
                    :disabled="!itsCompleteToSave"
                    @click="agregarNuevoProducto"
                >
                    Guardar
                </button>

                <button
                    v-if="activateEditOptions"
                    :class="[{'success-btn': itsCompleteToUpdate, 'disabled-btn': !itsCompleteToUpdate},'round']"
                    type="button"
                    :disabled="!itsCompleteToUpdate"
                    @click="actualizarProducto"
                >
                    Actualizar
                </button>
            </div>
        </form>
    </div>
</template>

<style scoped>

.offscreen{
    display: none;
}

.noresize{
    resize: none;
}
.template_form{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-block: 0.5rem;
}

.template_form form{
    flex-basis: 700px;

    display: flex;
    flex-flow: column wrap;

    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.check-field{
    margin-block: 0.5rem;
}

.check-field .new_check{
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
    background-color: #888;
    color: transparent;
    border-radius: 26px;
    cursor: pointer;
    margin: 0;
    transition: background-color 300ms ease 300ms;
}

.check-field .new_check::after{
    content: ' ';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #fff;
    left: 3px;
    top: 3px;
    transition: transform 300ms ease;
}

.check-field input[type='checkbox']:checked + .new_check::after{
    transform: translateX(24px);
}

.check-field input[type='checkbox']:checked + .new_check{
    background-color: #0dabd3;
}

.option-check{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding-inline: 1rem;
}

.label_check{
    font-family: 'Roboto';
    font-size: 1rem;
}

.label_check strong{
    color: #096981;
}

.select-design{
    display: flex;
    flex-flow: row wrap;
    gap: 0.6rem 0.5rem;
    margin: 0.7rem 0.5rem;
}
.select_options{
    flex-grow: 1;
    padding: 0.5rem 1rem;
    border: solid 1px #e3e5e7;
    background-color: white;
    font-family: 'Roboto', sans-serif;
    border-radius: 0.3rem;
    font-size: 0.9rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.select_options_imgprod{
    width: 200px;
}
.button-container{
    margin-block: 0.5rem;
}

.box_imgprod{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 0.5rem;
    gap: 0.5rem 0.3rem;
}
.post-img{
    position: relative;
    cursor: pointer;
    max-width: 100px;
    max-height: 100px;
    overflow: hidden;
}

.post-img img {
    width: 100%;
    height: 100%;
    border: solid 1px #ccc;
    border-radius: 0.3rem;
    padding: 0.2rem;
    object-fit: contain;
    transition: border-color 0.5s ease;
}

.post-img span{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.3rem;
    background-color: rgb(165, 57, 75, 0.3);
    transition: top 0.3s ease;
    font-weight: bold;
    font-family: 'Quicksand';
    color: white;
}

.post-img:hover > img {
    border-color: rgb(165, 57, 75);
}

.post-img:hover > span {
    top: 0;
}



</style>