<template>
    <!-- Se tiene que verificar el pago mediante la id de stripe del pago y checar si se encuentra pagado, ademas de crear la orden y limpiar el carrito -->
  <button :class="['btn-material']" @click="creandoOrden">Finalizar pedido</button>
</template>

<script>
import CarritoService from '@/services/CarritoService'
import OrdenService from '@/services/OrdenService'

    export default {
        name: 'ResolucionPagoComponent',
        data(){
            return{
                orden: this.$cookies.get('orden')
            }
        },
        mounted(){
            console.log(this.$cookies.get('orden').total)
        },
        methods: {
            async creandoOrden(){
                /* console.log('Creando la orden') */
                /* console.log(this.orden) */
                /* const response = await OrdenService.createOrden(this.$cookies.get('token'), this.orden)

                if (response.success){
                     this.productos.forEach(async producto => {
                        const response = await OrdenService.createOrdenDetalle(this.$cookies.get('token'), this.$cookies.get('id_orden'), producto)
                        console.log(this.$cookies.get('id_orden'))
                        console.log(producto)
                    });  
                }

                const resclean = await CarritoService.cleanCarrito(this.$cookies.get('token'), this.$cookies.get('id_carrito'))

                if(resclean.success){
                    window.location.href = "http://localhost:8080"
                } */

                console.log(this.$cookies.get('token'))

                const response = await OrdenService.createOrdenFull(this.$cookies.get('token'), this.orden)
                if (response.success) {
                    console.log('Se completo la compra con exito')
                    this.$cookies.set('estado',1)
                    this.$cookies.remove('orden')
                    
                    window.location.href = "https://puntotuxtleco.com.mx/"
                }
            }
        }
    }
</script>
    
<methods>

</methods>