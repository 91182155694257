<script>
    export default{
        name: 'CategoriaForm',
        props:{
            categoria: Object
        },
        data(props){
            return{
                
            }
        },
        emits:['createCategoria', 'updateCategoria', 'deleteCategoria'],
        methods: {
            async createCategoria(){
                this.$emit('createCategoria')
            },
            async updateCategoria(){
                this.$emit('updateCategoria')
            },
            async deleteCategoria(){
                if(confirm(`Esta seguro que desea eliminar esta categoria?`)){
                    this.$emit('deleteCategoria')
                }
            }
        },
        computed:{
            noHasData: function(){
                return this.categoria.id==0
            },
            isReadyToAdd: function(){
                return this.categoria.nombre !== ''
            }
        }
    }
</script>

<template>
    <form>
        <h2 :class="['titulo_vista']">Agrega una nueva categoria</h2>
        <div :class="['field_textarea','field-cyan']">
            <input 
                v-model.number="categoria.nombre" 
                :class="['input_field','round']"
                type="text" 
                id="nombre_categoria"
                title="Por favor, ingrese el nombre de la categoria" 
                placeholder="..."
            >
            <label :class="['msg','m-l']" for="nombre_categoria">Categoria</label>
        </div>
        <div :class="['button-container','end']">
            <button 
                :class="[{'normal-btn': isReadyToAdd, 'disabled-btn': !isReadyToAdd},'round']" 
                type="button" 
                title="Añade un nuevo empaque"
                :disabled="!isReadyToAdd"
                @click="createCategoria"
            > 
                Añadir 
            </button>
            <button 
                :class="[{'outline-btn': !noHasData, 'disabled-btn': noHasData},'round']" 
                type="button"
                title="Edita el empaque seleccionado"
                :disabled="noHasData"
                @click="updateCategoria"
            >
                Editar
            </button>
            <button 
                :class="[{'delete-btn': !noHasData, 'disabled-btn': noHasData},'round']"
                type="button"
                title="Elimina el empaque seleccionado"
                :disabled="noHasData"
                @click="deleteCategoria"
            >
                Eliminar
            </button>
        </div>
    </form>
</template>

<style scoped>
    .titulo_vista{
        text-align: left;
        padding-bottom: 0.5rem;
    }
</style>