<template>
    <div :class="['ventas-container']">
        <CircleLoader 
            :activo="stateCharge" 
        />
        <VentaPost 
            v-for="(venta, index) in ventas" 
            :key="index" 
            :id="venta.id"
            :venta="venta"
        />
    </div>
</template>

<script>
import VentaPost from '@/components/AdminComponents/VentaPost.vue'
import CircleLoader from '../CircleLoader.vue'
import OrdenService from '@/services/OrdenService'
    export default {
        name: 'VentasList',
        components:{
            CircleLoader,
            VentaPost
        },
        mounted(){
            this.getVentas()
        },
        data(){
            return{
                ventas: [],
                stateCharge: false
            }
        },
        methods: {
            async getVentas(){
                this.stateCharge = true
                const response = await OrdenService.getVentas(this.$cookies.get('token'))
                if (response.success) {
                    this.ventas = response.ventas
                }
                this.stateCharge = false
            }
        }
    }
</script>

<style scoped>
    .ventas-container{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 0.5rem;
        
        padding: 1rem;
    }

    @media (max-width: 900px){
        .ventas-container{
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media (max-width: 600px){
        .ventas-container{
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 500px) {
        .ventas-container{
            grid-template-columns: repeat(1, 1fr);
        }
    }
</style>