<script>
    export default{
        name: 'EmpaquePost',
        props:{
            empaque: Object,
            selected: Boolean,
        },
        data(props){
            return{
                
            }
        },
        emits:['setLocalEmpaque','resetLocalEmpaque'],
        methods: {
            sendEmpaque(){
                if(!this.selected){
                    this.$emit('setLocalEmpaque',JSON.parse(JSON.stringify(this.empaque)))
                }else{
                    this.$emit('resetLocalEmpaque')
                }
            }
        }
    }
</script>

<template>
    <article 
        :title="'Seleccione para editar al empaque [' + empaque.tipo + ']'"
        :class="['post-short-view', {'active': selected}]" 
        @click="sendEmpaque"
    >
        <span>{{ empaque.tipo }}</span>
    </article>
</template>

<style scoped>

</style>