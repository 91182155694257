import axios from "axios";
import store from '@/store';
const apiUrl =  store.state.apiRender;
const apiUploadImgSingleQR = `${apiUrl}upload/images/single/qr`;
const apiUpdateImgSingleQR = `${apiUrl}update/images/single/qr`;
const apiUpdateSingleImagenProd = `${apiUrl}update/images/single/producto`;
const apiUpdateSingleImagenProductor = `${apiUrl}update/images/single/productor`;
const apiUploadImgMultipleQR = `${apiUrl}upload/images/multiple/qr`;

async function uploadImgSingleQR(token, formData){
    try{
        const response = await axios.post(apiUploadImgSingleQR, formData,
            {
                headers:{
                    Authorization: token
                }
            }
        );
    
        return { creation: response.data, success: true }
    }catch(e){
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

async function UpdateImgSingleQR(token, formData){
    try {
        const response = await axios.patch(apiUpdateImgSingleQR, formData, 
            {
                headers:{
                    'Content-Type': 'multipart/form-data',
                    Authorization: token,
                }
            }
        )
        return { update: response.data, success: true }
    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

async function UpdateSingleImgProd(token, formData){
    try{
        const response = await axios.patch(apiUpdateSingleImagenProd, formData, 
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: token
            }
        });
        return { update: response.data, success: true }
    } catch (e){
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

async function UpdateSingleImgProductor(token, formData){
    try {
        const response = await axios.patch(apiUpdateSingleImagenProductor, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization:token
            }
        });
        return { update: response.data, success: true }
    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

export default {
    uploadImgSingleQR,
    UpdateImgSingleQR,
    UpdateSingleImgProd,
    UpdateSingleImgProductor
}