<template>
  <div class="home">
    <div class="div_title">
      <h1 class="h1_title">tu tienda colectiva</h1>
      <img class="punto_tuxtleco_img" alt="punto_tuxtleco_img" src="@/assets/home_img/PuntoTuxtleco.png" >
    </div>

    <div class="div_carousel_imagenes">
      <b-carousel id="carousel-1" v-model="slide" :interval="9000" controls indicators background="#ababab"
        img-width="100%" img-height="100%" style="text-shadow: 1px 1px 2px #333; " @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd">
        <!-- Text slides with image -->
        <b-carousel-slide style="height: 100%; background: black;">
          <template #img>
            <img class="d-block img-fluid w-100" width="1024" height="480" src="@/assets/home_img/carrusel/carrusel1.jpg"
              alt="image slot">
          </template>
        </b-carousel-slide>

        <!-- Slides with image only -->
        <b-carousel-slide style="height: 100%; background: black;">
          <template #img>
            <img class="d-block img-fluid w-100" width="1024" height="480" src="@/assets/home_img/carrusel/carrusel3.png"
              alt="image slot">
          </template>
        </b-carousel-slide>

        <!-- Slides with img slot -->
        <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
        <b-carousel-slide style="height: 100%; background: black;">
          <template #img>
            <img class="d-block img-fluid w-100" width="1024" height="480" src="@/assets/home_img/carrusel/carrusel4.jpg"
              alt="image slot">
          </template>
        </b-carousel-slide>

        <!-- Slide with blank fluid image to maintain slide aspect ratio -->
        <b-carousel-slide style="height: 100%;background: black;">
          <template #img>
            <img class="d-block img-fluid w-100" width="1024" height="480" src="@/assets/home_img/carrusel/carrusel5.jpg"
              alt="image slot">
          </template>

        </b-carousel-slide>
      </b-carousel>
    </div>

    <div class="div_info_punto_tuxtleco">
      <p class="info_punto_tuxtleco">
        La Secretaría de Economía de Tuxtla Gutiérrez, Chiapas, es una dependencia de gobierno
        que impulsa la productividad y competitividad de las unidades económicas de Tuxtla Gutiérrez.
        Tiene como objetivo impulsar la competitividad y productividad de la economía municipal a
        través de la implementación de políticas públicas orientadas a fomentar la participación.
        Esto crea empresas formales que ayudan a generar el desarrollo económico y bienestar social,
        como es el caso de la empresa Punto Tuxtleco, la cual está compuesta por diversos socios de
        microempresas de productos. Aquí se busca promover el comercio local y expandirlo por diversos
        municipios y estados para obtener un mayor ingreso económico.
      </p>
      <img style="width: 120px; height: 120px;margin: 30px;  margin-right: 40px;" class="secretaria_conomia_img"
        alt="secretaria_conomia_img" src="@/assets/home_img/secretaria_conomia.jpg">
    </div>

    <div class="div_information">
      <div class="div_info1_2">
        <div class="div_information_1">
          <h3 class="txt_titulo_gastro">Productos Chiapanecos</h3>
          <div class="div_img_info">
            <p class="txt_gastro">
              Destacan por su autenticidad y diversidad, reflejando la riqueza cultural y natural de la región. Desde el
              reconocido café de altura hasta el cacao, miel, frutas tropicales y derivados del maíz, cada producto
              encapsula la esencia única de Chiapas.
            </p>
            <img style="width: 100px; height: 100px; margin-left: 20px; border: solid 1px;" class="salsa_img"
              alt="salsa_img" src="@/assets/home_img/productos.png">
          </div>
        </div>

        <div class="div_information_2">
          <h3 class="txt_titulo_produce">Lo que produce Tuxtla Gutiérrez, Chiapas</h3>
          <div class="div_img_info">
            <p class="txt_produce">
              Es conocida por su producción agrícola y ganadera. Destacada por su cultivo de maíz, frijol, café y cacao,
              así como la cría de ganado bovino y porcino.
            </p>
            <img style="width: 100px; height: 100px;margin-left: 20px; border: solid 1px;" class="cafe_img" alt="cafe_img"
              src="@/assets/home_img/cosecha_agricola.png">
          </div>
        </div>
      </div>
      <div class="div_info3_4">
        <div class="div_information_3">
          <h3 class="txt_titulo_desarrollo">Desarrollo tecnológico de Chiapas</h3>
          <div class="div_img_info">
            <p class="txt_desarrollo">
              Se refleja a través de la incorporación de plataformas digitales y redes sociales por parte de los locales,
              quienes promueven sus auténticos sabores en línea y hacen uso de códigos QR para destacar lo mejor de los
              productos.
            </p>
            <img style="width: 100px; height: 100px;margin-left: 20px; border: solid 1px;" class="cafe_img" alt="cafe_img"
              src="@/assets/home_img/qrs.jpg">
          </div>
        </div>
        <div class="div_information_4">
          <h3 class="txt_titulo_motor">Motor económico de Chiapas</h3>
          <div class="div_img_info">
            <p class="txt_motor">
              Es impulsada por la riqueza de sus recursos agrícolas y la diversidad de productos autóctonos. La producción
              y comercialización de café, cacao, miel, frutas tropicales y productos derivados del maíz constituyen
              pilares fundamentales.
            </p>
            <img style="width: 100px; height: 100px;margin-left: 20px; border: solid 1px;" class="cafe_img" alt="cafe_img"
              src="@/assets/home_img/cosecha.jpg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  data() {
    return {
      slide: 0,
      sliding: null,
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  },
  mounted() {
    const btn_div_home = document.querySelector('.home');
    btn_div_home.addEventListener('mouseover', () => {
      this.$root.$emit('ocultar_carrito');
    });
  }, beforeUnmount() {
    // Limpiar el evento al destruir el componente para evitar posibles fugas de memoria
    if (document.querySelector('.home') != null) {
      const btn_div_home = document.querySelector('.home');
      btn_div_home.removeEventListener('mouseover', () => {
        this.$root.$emit('ocultar_carrito');
      });
    }
  }

}
</script>
<style scoped>
.home {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.div_title {
  width: 100%;
  height: 100px;
  display: flex;
  /* flex-direction: row; */
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: aliceblue;
  border-bottom: 1px solid #f6f6f6;
  padding-inline: 1rem;
  /* overflow: hidden; */
  /* white-space: nowrap; */
  max-width: 100%; /* o el valor que desees */
  word-wrap: break-word;
}

.h1_title {
  height: auto;
  width: 100%;
  align-items: center;
  font-size: 45px;
  margin-left: 10rem;
  font-weight: bolder;
  text-align: center;
  text-shadow: 5px 1px rgba(0, 0, 0, 0.233);
  font-family: 'Roboto', Arial, Helvetica, 'Anton', sans-serif;
  text-transform: capitalize;
}

.punto_tuxtleco_img {
  width: 170px;
}



#carousel-1 {
  width: 100%;
}

.div_carousel_imagenes img {
  height: 400px !important;
}

.carousel-control-prev,
.carousel-control-next {
  width: 100% !important;
}

/* Estilo para el contenedor de la imagen */
.carousel-inner {
  text-align: center !important;
}

/* Estilo para la imagen dentro del contenedor */
.carousel-inner img {
  width: 53% !important;
  display: inline-block !important;
}

/* texto informativo*/
.div_info_punto_tuxtleco {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 1.5rem;
  margin: 1rem;
  border: 2px solid #4dd3f48e;
}



.info_punto_tuxtleco {
  margin: 40px;
  font-family: 'Quicksand', sans-serif;
  margin-right: 0;
  text-align: start;
}

.div_information {
  display: flex;
  flex-direction: row;
}

.div_info1_2 {
  flex: 1;
}

.div_info3_4 {
  margin-top: 50px;
  flex: 1;
}

.div_information_1,
.div_information_2,
.div_information_3,
.div_information_4 {
  display: flex;
  flex-direction: column;
  margin: 15px;
  padding: 0.7rem;
  border-radius: 1.5rem;
  border: 2px solid #4dd3f48e;
}

.div_information_1:hover,
.div_information_2:hover,
.div_information_3:hover,
.div_info_punto_tuxtleco:hover,
.div_information_4:hover {
  background-color: #4dd3f4b0;
}

.div_information_2,
.div_information_4 {
  margin-top: 50px;
}

.txt_titulo_gastro,
.txt_titulo_produce,
.txt_titulo_desarrollo,
.txt_titulo_motor {
  width: 100%;
  text-align: center;
  font-family: 'Roboto', Arial, Helvetica, 'Anton', sans-serif;
}

.txt_gastro,
.txt_produce,
.txt_desarrollo,
.txt_motor {
  width: 70%;
  text-align: start;
  margin: 0;
  font-family: 'Quicksand', sans-serif;

}

.div_img_info {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
}

@media (max-width: 767px) {
 
  .punto_tuxtleco_img {
    width: 160px;
  }

  .h1_title {
    font-size: 28px;
    margin-right: 1%;
    margin-left: 8%;
  }

  .secretaria_conomia_img {
    margin: 0% !important;
    margin-top: 20px !important;
    margin-bottom: 15px !important;
  }

  .info_punto_tuxtleco {
    margin: 40px;
    margin-bottom: 0%;
  }

  .div_info_punto_tuxtleco,
  .div_img_info {
    flex-direction: column;
  }

  .txt_gastro,
  .txt_produce,
  .txt_desarrollo,
  .txt_motor {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .punto_tuxtleco_img {
    padding-left: 0px;
    padding-right: 0px;
  }

  .salsa_img,
  .cafe_img {
    margin: 0% !important;
  }
}
@media (max-width: 430px) {
  .div_information {
  flex-direction: column;
}
.punto_tuxtleco_img {
    width: 120px;
  }
}</style>
