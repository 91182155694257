import axios from "axios";
import store from '@/store'
let apiUrl = store.state.apiRender;

async function login(correo, contraseña) {
    try {
        //Peticion Post con Url y Data, esperando un token...
        const response = await axios.post(apiUrl + "login", {
            correo: correo,
            contraseña: contraseña
        })

        //nunca verifica aqui pero si se hace con fetch entonces si es necesario
        if ('message' in response.data) {
            return { message: response.data.message, success: false }
        }


        $cookies.set('token', response.data.token)
        $cookies.set('id_user', response.data.id)
        $cookies.set('role', (response.data.role == 1) ? 'admin' : 'user')
        $cookies.set('id_carrito', response.data.id_carrito)

        return { message: "Login success", success: true }

    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}
async function crear_cuenta(nombre, apellido_p, apellido_m, correo, contraseña,telefono) {
    try {
        //Peticion Post con Url y Data, esperando un token...
        const response = await axios.post(apiUrl + "usuario", {
            nombre: nombre,
            apellido_p: apellido_p,
            apellido_m: apellido_m,
            correo: correo,
            contraseña: contraseña,
            telefono:telefono,
            rol: 0
        })

        //nunca verifica aqui pero si se hace con fetch entonces si es necesario
        if (response.status === 204) {
            return { message: "Cuenta creada", success: true }
        } else {
            return { message: response.data.message, success: false }
        }


    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}
async function renewToken() {
    try {
        const response = await axios.post(apiUrl + "renovar-Token", {
            token: $cookies.get('token')
        })
        $cookies.set('token', response.data.token)

        console.log('actualizado')
    } catch (error) {
        if (error.response && error.response.status === 401 && error.response.data.message === 'Token expirado') {
            $cookies.remove('id_user')
            $cookies.remove('token')
            $cookies.remove('id_carrito')
            $cookies.remove('role')
            window.location.href = 'http://localhost:8080/';
        } else {
            console.error('Error en la solicitud de renewtoken:', error);
        }
    }
}
//Actualizar 5 min antes de actualizar la api, cronometro de 60s

async function obtenerUsuario(token, id) {
    try {
        const response = await axios.get(apiUrl + "usuario/" + id, {
            headers: {
                Authorization: token
            }
        })
        return { user: response, success: true }
    } catch (e) {
        console.log(e)
    }
}
async function RecuperarContraseña(correo_) {
    try {
        console.log(correo_)
        const response = await axios.post(apiUrl + "recuperar_contra", {
            correo: correo_
        })
        return { user: response, success: true }
    } catch (e) {
        // console.log(e)
        return { success: false }
    }
}
async function NuevaContraseña(token, contraseña) {
    try {
        const response = await axios.patch(apiUrl + "nueva_contra", {
            token: token,
            contraseña: contraseña
        })
        return { user: response, success: true }
    } catch (e) {
        // console.log(e)
        return { success: false }
    }
}

export default {
    login,
    crear_cuenta,
    renewToken,
    obtenerUsuario,
    RecuperarContraseña,
    NuevaContraseña
};