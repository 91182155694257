<script>
import ProductoTable from '@/components/AdminComponents/ProductoComponents/ProductoTable.vue'
    export default{
        name: 'NuevoComponente',
        components:{
            ProductoTable,
        },
        data(){
            return{
                producto: {
                    id: 0,
                    nombre: '', 
                    precio: 0, 
                    descripcion: '', 
                    detalles: '', 
                    especificaciones: '',
                    cantidad: 0,
                    activo: 1,
                    id_productor: 0,
                    id_capacidad: 0,
                    id_empaque: 0,
                    id_categoria: 0,
                    id_qr: 0,
                    imagenes: []
                }
            }
        },
        methods: {
            
        }
    }
</script>

<template>
    <div :class="['container-tab-productos']">
        <div :class="['container-head-productos']">
            <h1 :class="['titulo_vista']">Lista de productos</h1>
            <router-link 
                :class="['outline-btn','round']" 
                :to="{ name: 'agregar_producto', params: {producto: producto}}"
            >
                Agregar producto
            </router-link>
        </div>
        <ProductoTable />
    </div>
</template>

<style scoped>
.titulo_vista{
    padding: 0;
    margin: 0;
}
.container-tab-productos{
    padding-inline: 0.8rem;
}
.container-head-productos{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding-block: 0.5rem;
}

.outline-btn{
    text-decoration: none;
    border-width: 2px;
}

</style>