<template>
    <form :class="['container-component']">
        <h2 :class="['title-component']">Tus datos de envio: </h2>
        <section>
            <h3 :class="['subtitle-component']">Datos de la cuenta</h3>
            <div :class="['line-material-form']">
                <div :class="['input-material-form']">
                    <input type="text" v-model="usuario.nombre" :readonly="true" required><label :class="['default-value']">Nombre</label>
                </div>
                <div :class="['input-material-form']">
                    <input type="text" v-model="usuario.apellido_p" :readonly="true"><label :class="['default-value']">Apellido paterno</label>
                </div>
                <div :class="['input-material-form']">
                    <input type="text" v-model="usuario.apellido_m" :readonly="true"><label :class="['default-value']">Apellido materno</label>
                </div>
            </div>
            
            <div :class="['input-material-form']">
               <input type="text" v-model="usuario.correo" :readonly="true"><label :class="['default-value']">correo</label> 
            </div>
        </section>
        
        <section>
            <h3 :class="['subtitle-component']">Domiclio</h3>
            <FormDomicilio v-show="!add_domicilio" @visible="cambiarVisForm" @recargarDomicilios="getDomiciliosByUser" :domicilio_size="domicilios.length"/>

            <div :class="['header-subtitle-low']">
                <h4 :class="['subtitlelow-component']">Domicilios Registrados</h4>
                <button :class="['btn-material']" v-show="add_domicilio" type="button" @click="cambiarVisForm(false)">Añadir domicilio</button>
            </div>

            <DomicilioPost v-for="domicilio in domicilios" :key="domicilio.id" :domicilio="domicilio" :is_cheked="(domicilio.id==domicilioUtilizado.id)? true : false" @setDomicilio="setDatosDomicilio"/>

            <span :class="['text-span', {'text-warning': domicilios.length>0, 'text-danger': !domicilios.length>0} ]" v-show="domicilioUtilizado.id==''" > <i :class="['fa-solid', {'fa-triangle-exclamation': domicilios.length>0, 'fa-circle-exclamation': !domicilios.length>0}]"></i> {{ message }} </span>
        </section>

        <button :class="['btn-material', 'btn-send']" type="button" :disabled="domicilioUtilizado.id==''" @click="guardarDatos">Siguiente</button>
    </form>
</template>

<script>
/*Componentes*/
import DomicilioPost from '@/components/UserComponents/DomicilioPost.vue'
import FormDomicilio from '@/components/UserComponents/FormDomicilio.vue'

/*Servicios a utilizar*/
import AuthService from '@/services/AuthService'
import DomicilioService from '@/services/DomicilioService'


    export default{
        name: 'UserInfoComponent',
        components: {
            DomicilioPost,
            FormDomicilio
        },
        props: {
            
        },
        mounted(){
            this.getInfoUser()
        },
        data(){
            return{
                usuario:{
                    nombre: '',
                    apellido_p: '',
                    apellido_m: '',
                    correo: ''
                },
                domicilios:[],
                domicilioUtilizado: {
                    id: '',
                    calle: '',
                    colonia: '',
                    estado: 'Chiapas',
                    ciudad: '',
                    num_ext: '',
                    num_int: '',
                    pais: 'Mexico',
                    c_p: ''
                },
                message: '',
                add_domicilio: true
            }
        },
        emits: ['setUserData','setNewEstado','setDomicilioSelected'],
        methods: {
            async getInfoUser(){
                console.log('Cargando informacion del usuario...')
                //Para este punto ya deberia de tener la cookie id asignada un valor
                const response = await AuthService.obtenerUsuario(this.$cookies.get('token'), this.$cookies.get('id_user'))
                if(response.success){
                    this.usuario = response.user.data
                    console.log('Informacion cargada')
                    this.$emit('setUserData', this.usuario)
                }
                this.getDomiciliosByUser()
            },
            async getDomiciliosByUser(){
                console.log('cargando domicilios...')

                const response = await DomicilioService.getDomiciliosByUserID(this.$cookies.get('token'), this.$cookies.get('id_user'))

                await (this.domicilios = response.success ? response.domicilios : [])
                await (this.add_domicilio = (this.domicilios.length==0) ? false : true)
                
                this.message = (this.domicilios.length>0) ? 'No se ha seleccionado ningun domicilio.' : 'No se han encontrado domicilios asociados a este usuario, añada uno para poder seguir.'
                console.log('Informacion cargada')
            },
            setDatosDomicilio(domicilio){
                console.log(domicilio)
                this.domicilioUtilizado = domicilio
                this.$emit('setDomicilioSelected',domicilio)
            },
            cambiarVisForm(add_domicilio){
                this.add_domicilio = add_domicilio
            },
            guardarDatos(){

                this.$emit('setNewEstado', 2) //Guardando el estado
            },
        }
    }
</script>

<style scoped>

</style>