<template>
    <div :class="['container-form-crear_cuenta']">
        <Loading v-if="$store.state.loading"></Loading>
        <alerta v-if="$store.state.activar_alerta" :mensaje="$store.state.mensaje_alerta" @cerrarAlerta="cerrarAlerta">
        </alerta>

        <form v-if="!$store.state.loading" :class="['form-crear_cuenta']">
            <div :class="['div_campos']" @keydown.enter="Crear_cuenta_enter">
                <h1 :class="['header-crear_cuenta']">Crear Cuenta</h1>
                <p :class="['subtitle-crear_cuenta']">Nombre:</p>
                <input :class="['input-crear_cuenta']" v-model="nombre" id="in-nombre" type="text"
                    placeholder="Ingrese su Nombre">
                <p :class="['subtitle-crear_cuenta']">Apellido Paterno:</p>
                <input :class="['input-crear_cuenta']" v-model="ap" id="in-ap" type="text"
                    placeholder="Ingrese su Apellido paterno">
                <p :class="['subtitle-crear_cuenta']">Apellido Materno:</p>
                <input :class="['input-crear_cuenta']" v-model="am" id="in-am" type="text"
                    placeholder="Ingrese su Apellido materno">
                <p :class="['subtitle-crear_cuenta']">Telefono:</p>
                <input :class="['input-crear_cuenta']" v-model="telefono" id="in-telefono" type="text"
                    pattern="[0-9]{10}" minlength="10" maxlength="10" placeholder="Ingrese su numero de telefono">
                <p :class="['subtitle-crear_cuenta']">Correo:</p>
                <input :class="['input-crear_cuenta']" v-model="correo" id="in-correo" type="text"
                    placeholder="Ingrese su correo electronico">
                <p :class="['subtitle-crear_cuenta']">Contraseña:</p>
                <!-- <label for="in-contra">Ingrese contraseña</label> -->
                <div>
                    <input :class="['input-crear_cuenta']" v-model="contrasenia" id="in-contra"
                        placeholder="Ingrese su Contraseña" type="password">
                    <input style="margin-left: .5rem;" type="checkbox" id="showPassword" v-model="showPassword"
                        @change="togglePasswordVisibility" />
                </div>
            </div>
            <div :class="['div_btn']">
                <button :class="['btn-crear_cuenta']" @click="Crear_cuenta" type="button">Crear Cuenta</button>
            </div>
        </form>
        <picture v-if="!$store.state.loading" :class="['image-container']" style="height: 37.1rem;">
            <img src="../assets/categorias_img/PuntoTuxtleco_2.png" alt="imagen_bienvenida">
        </picture>
    </div>
</template>

<script>
import auth from '@/services/AuthService'
import Loading from '@/components/Loading.vue'
import alerta from '@/components/VentanaEmergente.vue'


export default {
    name: 'LoginView',
    data() {
        return {
            nombre: '',
            ap: '',
            am: '',
            correo: '',
            contrasenia: '',
            token: '',
            id_user: '',
            telefono: null,
            showPassword: false,
        }
    },
    components: {
        Loading,
        alerta
    },
    mounted() {
        document.title = 'Crear Cuenta | PuntoTuxtleco'
    },
    methods: {
        cerrarAlerta() {
            try {
                // Actualizar el estado en el componente padre        
                this.$store.commit('setactivar_alerta', false);
            } catch (error) {
            }
        },
        togglePasswordVisibility() {
            const passwordInput = document.getElementById("in-contra");

            if (this.showPassword) {
                passwordInput.type = "text";
            } else {
                passwordInput.type = "password";
            }
        },
        PrimeraLetra(input) {
            // Dividir la cadena en palabras
            let palabras = input.toLowerCase().split(' ');

            // Capitalizar la primera letra de cada palabra
            let palabrasCapitalizadas = palabras.map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            });

            // Unir las palabras capitalizadas de nuevo en una cadena
            let resultado = palabrasCapitalizadas.join(' ');

            return resultado;
        }, validarCorreo(correo) {
            // Expresión regular para validar el formato del correo electrónico
            const regexCorreo = /^[^\s@]+@[a-z]+\.[^\s@]+?[^\s@]$/i;
            // const regexCorreo = /^[a-z0-9]+@[a-z]+\.(com)$/i; //con .com validandolo

            // Verificar el formato del correo electrónico
            if (regexCorreo.test(correo)) {
                return true;
            } else {
                return false;
            }
        },
        Crear_cuenta_enter(event) {
            if (event.keyCode === 13) {
                this.Crear_cuenta();
            }
        }
        ,
        async Crear_cuenta() {
            if (!this.nombre || !this.ap || !this.am || !this.correo || !this.contrasenia || !this.telefono) {
                this.$store.commit('setactivar_alerta', true);
                this.$store.commit('setmensaje_alerta', 'Favor de llenar todos los campos');
                if (!this.nombre) {
                    document.getElementById('in-nombre').style.border = '1px solid red';
                } else {
                    document.getElementById('in-nombre').style.border = '1px solid black';
                }

                if (!this.ap) {
                    document.getElementById('in-ap').style.border = '1px solid red';
                } else {
                    document.getElementById('in-ap').style.border = '1px solid black';
                }

                if (!this.am) {
                    document.getElementById('in-am').style.border = '1px solid red';
                } else {
                    document.getElementById('in-am').style.border = '1px solid black';
                }

                if (!this.correo) {
                    document.getElementById('in-correo').style.border = '1px solid red';
                } else {
                    document.getElementById('in-correo').style.border = '1px solid black';
                }

                if (!this.contrasenia) {
                    document.getElementById('in-contra').style.border = '1px solid red';
                } else {
                    document.getElementById('in-contra').style.border = '1px solid black';
                }
                if (!this.telefono || this.telefono.length < 10 || isNaN(this.telefono)) {
                    document.getElementById('in-telefono').style.border = '1px solid red';
                } else {
                    document.getElementById('in-telefono').style.border = '1px solid black';
                }
            } else {
                document.getElementById('in-nombre').style.border = '1px solid black';
                document.getElementById('in-ap').style.border = '1px solid black';
                document.getElementById('in-am').style.border = '1px solid black';
                document.getElementById('in-correo').style.border = '1px solid black';
                document.getElementById('in-contra').style.border = '1px solid black';
                if (!this.validarCorreo(this.correo)) {
                    this.$store.commit('setactivar_alerta', true);
                    this.$store.commit('setmensaje_alerta', 'Verifica tu correo');
                    document.getElementById('in-correo').style.border = '1px solid red';
                } else if (isNaN(this.telefono)) {
                    this.$store.commit('setactivar_alerta', true);
                    this.$store.commit('setmensaje_alerta', 'Error verifica tu numero');
                    document.getElementById('in-telefono').style.border = '1px solid red';
                } else if (this.telefono.length < 10) {
                    this.$store.commit('setactivar_alerta', true);
                    this.$store.commit('setmensaje_alerta', 'Verifica tu numero de telefono\n debe de cumplir con 10 digitos');
                    document.getElementById('in-telefono').style.border = '1px solid red';
                } else {
                    try {
                        this.$store.commit('setLoading', true);
                        document.getElementById('in-correo').style.border = '1px solid black';
                        const response = await auth.crear_cuenta(this.PrimeraLetra(this.nombre), this.PrimeraLetra(this.ap),
                            this.PrimeraLetra(this.am), this.correo, this.contrasenia, this.telefono)
                        this.$store.commit('setLoading', false);
                        if (response.success) {
                            console.log(response.message)
                            window.location.href = "https://puntotuxtleco.com.mx/login"
                        } else {
                            if (response.message === 'El usuario ya existe.') {
                                this.$store.commit('setactivar_alerta', true);
                                this.$store.commit('setmensaje_alerta', 'El correo ya se encuentra registrado');
                                document.getElementById('in-correo').style.border = '1px solid red';
                            } {
                                console.log(response.message)
                            }
                        }
                    } catch (error) {
                        this.$store.commit('setLoading', false);
                    }
                }
            }

        }
    }
}
</script>

<style>
.container-form-crear_cuenta {
    background-image: radial-gradient(circle, #0dabd3ad, #9fe7f9d8), url("../assets/tiendas_fisicas/sucursal1.jpg");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    display: flex;
    flex-flow: row wrap;

    width: 100vw;
    height: 100vh;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.form-crear_cuenta {
    background-color: rgba(255, 255, 255, 0.541);
    backdrop-filter: blur(1px);
    border: solid 2px #0dabd3;
    border-radius: 0.3rem;
    /* max-width: 80%; */
    /* width:auto; */
    /* height: 62%; */

    display: flex;
    flex-flow: column;
    padding: 2rem 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;


    justify-content: center;
    gap: 0.5rem;
}

.div_campos {
    /* max-width: 550px;
    width: auto;
    height: auto; */
    display: flex;
    flex-flow: column;
}

.header-crear_cuenta {
    display: flex;
    font-size: 1.5rem;
    font-family: 'Quicksand';
    color: black;
}

.subtitle-crear_cuenta {
    border: 0;
    margin: 0;
    margin-bottom: .2rem;
    display: flex;
    text-align: start;
}

.header-crear_cuenta img {
    width: 3rem;
    height: auto;
}

.input-crear_cuenta {
    border: solid 2px #bbb;

    border-radius: 0.3rem;
    font-size: 1rem;
    padding: 0.6rem 0.7rem;
    width: auto;

    font-family: 'Quicksand';
    font-weight: 400;

    outline: none;
}

.input-crear_cuenta:focus {
    border: solid 2px #0dabd3;
    box-shadow: 0px 3px 1px #222;
}

.btn-crear_cuenta {
    border-radius: 0.5rem;
    background-color: #9fe7f9;

    border: solid 1px #333;

    font-family: 'Roboto';
    font-weight: 400;
    font-size: 1rem;
    color: #222;
    padding: 0.3rem 0.5rem;
    text-transform: capitalize;
    box-shadow: 0px 1px 3px #222;

    transition-property: background background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
}

.btn-crear_cuenta:hover {
    background-color: #0dabd3;

    color: white;
    font-weight: 600;
    box-shadow: 0px 3px 1px #222;
}



.image-container {
    background: linear-gradient(#9fe7f9, #0dabd3);
    border: solid 2px #0dabd3;
    overflow: hidden;

    max-width: 300px;
    width: 100%;

    height: 400px;

    display: flex;
    justify-content: center;
}

.image-container img {
    width: 100%;
    height: fit-content;
    align-self: center;
    padding: 0.5rem;
    /*object-fit: cover;*/

    /*transform: scale(1.4) /*translate(30px, 5%);*/
}

@media (max-width: 855px) {
    .image-container {
        display: none;
    }
}
</style>