<template>
    <div :class="['container-component']">
        <div :class="['header-resumen']">
            <div :class="['first-line-header']">
                <h2 :class="['title-component-resumen']">Tus productos en el carrito: </h2>
                <button type="button" @click="createCheckout" :class="['btn-material']">siguiente</button>
            </div>
            
            <span :class="['label-price']">precio</span>
        </div>
    
        <div v-if="have_data_c" v-for="(producto, index) in carrito" :key="index" :class="['article-product',{'non-count': producto.cantidad>producto.stock_disponible}]">
            <div :class="['container-img-producto','element']">
                <img :src="'https://api.puntotuxtleco.com.mx/img/productos/'+producto.imagenes[0].uri_img" alt="ImagenProducto" :class="['img-producto']">
            </div>
            <div :class="['container-name-producto','element']">
                <h3 :class="['name-producto']"> {{ producto.nombre }} {{ producto.capacidad }} </h3>
                <p :class="['text-description']"> {{ producto.descripcion }} </p>
                <p :class="['text-description']"> productor: {{ producto.nombre_productor }} </p>
                <p v-show="producto.cantidad>producto.stock_disponible" :class="['text-description','error_text']">Este producto no se contara en su pedido</p>
            </div>
            <div :class="['detalle-producto','element']">
                <h5 :class="['cantidad-producto']">cantidad: {{ producto.cantidad }}</h5>
            </div>
            <div :class="['price-section','element']">
                <h4 :class="['precio-producto']">{{ changeFormat(producto.precio) }}</h4>
            </div>
        </div>

        <div :class="['resume-price']">
            <span :class="['title','one-left']">Subtotal ({{ total_productos }} {{ msg_productos }}): </span><span :class="['content','one-right']">{{ changeFormat(subtotal_c) }}</span>
            <span :class="['title','two-left']">Envio: </span><span :class="['content','two-right']">{{ changeFormat(envio) }}</span>
            <span :class="['title','three-left']">Total: </span><span :class="['content','three-right']">{{ changeFormat(total) }}</span> 
        </div>
        
        <div v-if="!have_data_c">
            <h3>Al parecer aun no tienes datos en tu carrito</h3>
        </div>
    </div>
</template>

<script>

/*Importando los servicios*/
import PaymentService from '@/services/PaymentService'

    export default {
        name: 'VentaResumenComponent',
        props: {
            carrito: {
                type: Array
            }
        },
        data(props){
            return{
                have_data : false,
                total_productos: 0,
                envio: 50,
                subtotal : 0,
                total: 0,
                line_items : [],
                productos: []
            }
        },
        emits: ['setNewEstado','putOrden'],
        mounted(){
            //console.log('se puso el mounted')
            //this.imprimirCarrito()
            //this.prepare_data()
            //console.log('se quito el mounted')
        },
        methods: {
            imprimirCarrito(){
                console.log(this.carrito)
            },
            prepare_data(){
                console.log('entro a prepare_data')
                for (let i = 0; i < this.carrito.length; i++) {
                    this.total_productos += this.carrito[i].cantidad
                    this.subtotal += this.carrito[i].cantidad*this.carrito[i].producto.precio

                    this.productos.push({
                        id_producto: this.carrito[i].producto.id,
                        cantidad: this.carrito[i].cantidad,
                        precio : this.carrito[i].producto.precio
                    })

                    console.log(this.productos)

                    /*this.line_items.push({
                        price_data: {
                            product_data:{
                                name: this.carrito[i].producto.name,
                                description: this.carrito[i].producto.descripcion,
                            },
                            currency: 'mxn',
                            unit_amount: this.carrito[i].producto.precio*100,
                        },
                        quantity: this.carrito[i].cantidad
                    })*/
                }

                this.msg_productos = (this.total_productos>1) ? 'productos' : 'producto'

                this.total = this.envio+this.subtotal
                console.log('salio de prepare data')
            },
            changeFormat(number){
                const formatter = new Intl.NumberFormat('es-MX',{
                    style: 'currency',
                    currency: 'MXN'
                })

                return formatter.format(number)
            },
            async createCheckout(){
                
                console.log("Llamando metodo de pago")

                //Parseanso datos line_items
                const line_items = []
                /* for (let i = 0; i < this.carrito.length; i++) {
                    line_items.push({
                        price_data: {
                            product_data:{
                                name: this.carrito[i].producto.nombre,
                                description: this.carrito[i].producto.descripcion,
                            },
                            currency: 'mxn',
                            unit_amount: this.carrito[i].producto.precio*100,
                        },
                        quantity: this.carrito[i].cantidad
                    })
                } */

                this.carrito.forEach((producto) => {
                    if(producto.cantidad<=producto.stock_disponible){
                        line_items.push({
                            price_data: {
                                product_data:{
                                    name: producto.nombre,
                                    description: producto.descripcion,
                                },
                                currency: 'mxn',
                                unit_amount: producto.precio*100,
                            },
                            quantity: producto.cantidad
                        })

                        this.productos.push({
                            id_producto: producto.id_producto,
                            cantidad: producto.cantidad,
                            precio: producto.precio
                        })
                    }
                })
                
                    line_items.push({
                        price_data: {
                            product_data:{
                                name: 'envio',
                                description: 'Cargo del envio',
                            },
                            currency: 'mxn',
                            unit_amount: 50*100,
                        },
                        quantity: 1
                    })

                /* const line_items = [
                    {
                        price_data: {
                            product_data:{
                                name: 'Cafe',
                                description: 'Cafe Sierra Azul 500gr',
                            },
                            currency: 'mxn',
                            unit_amount: 200*100,
                        },
                        quantity: 1
                    },
                    {
                        price_data: {
                            product_data: {
                                name: 'Miel',
                                description: 'Miel Panalitos 100gr',
                            },
                            currency: 'mxn',
                            unit_amount: 100*100,
                        },
                        quantity: 2
                    }
                ]  */

                //this.line_items = line_items

                const success_url = 'https://puntotuxtleco.com.mx/pay/success'
                const cancel_url = 'https://puntotuxtleco.com.mx/pay/cancel'

                //El succ url cambiara a /pay/resolucion, donde se verificara si existe en las cookies la orden, si no existe redirigir a local, en dado caso continuar a la resolucion

                const session = await PaymentService.createCheckoutSession(line_items, success_url, cancel_url)
                if(await session.success){
                    const url = (await session.response).data.url

                    this.$emit('putOrden',this.total, this.productos)                 
                     //Guardando el estado
                    window.location.href = ""+url
                }
            }
        },
        computed: {
            subtotal_c: function(){
                let aux_subtototal = 0
                this.carrito.forEach(prodcto => {
                    if(prodcto.cantidad<=prodcto.stock_disponible){
                        aux_subtototal += prodcto.cantidad * prodcto.precio;
                        this.total_productos += prodcto.cantidad;
                    }
                });
                this.total = this.envio+aux_subtototal
                return aux_subtototal
            },
            have_data_c: function(){
                return this.carrito.length>0
            },
            msg_productos: function(){
                return (this.total_productos>1) ? 'productos' : 'producto'
            }
        }
    }
</script>

<style scoped>

    .header-resumen{
        display: flex;
        flex-flow: column wrap;
        align-items: center;

        background-color: #9ae4f7;
        padding: 0.4rem 0.5rem;
    }

    .first-line-header{
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        justify-content: space-between;
        width: 100%;
    }

    .title-component-resumen{
        font-family: 'Roboto';
        font-size: 1.6rem;
        font-weight: 700;
        text-align: left;
        color: rgb(8, 45, 74);
    }

    .label-price{
        font-family: 'Quicksand';
        font-size: 1rem;
        font-weight: 500;
        color: #000;

        align-self: flex-end;
        text-align: left;
        margin: 0;
    }
    .article-product{
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(2, minmax(75px, 75px));
        gap: 0.3rem;

        
        border-top: solid 1px #9ae4f7;
        padding: 0.5rem;
    }

    .non-count{
        border-left: solid 2px #812626;
        background-color: #c1d5db;
    }

    .text-tachado{
        text-decoration: line-through;
    }
    /*.element{
        background-color: rgba(77, 211, 244, 0.571);
        border: solid 1px #08637a;
    }*/
    .container-img-producto{
        grid-column: 1;
        grid-row: 1/3;

        display: flex;
        flex-flow: row wrap;
        align-items: center;

        border: solid 1px #d6c8c8;
    }

        .img-producto{
            width: 100%;
            max-width: 150px;
            height: auto;
            max-height: 150px;
        }

    .container-name-producto{
        grid-column: 2/6;
        grid-row: 1;
    }
    
        .name-producto{
            font-family: 'Roboto';
            font-size: 1.2rem;
            font-weight: 600;
            color: #08637a;

            text-align: left;
            margin: 0;
        }

        .text-description{
            font-family: 'Quicksand';
            font-size: 0.8rem;
            font-weight: 400;
            color: #000;

            text-align: left;
            margin: 0;
        }

        .error_text{
            font-weight: 600;
            color: #812626;
        }


    .detalle-producto{
        grid-column: 2/6;
        grid-row: 2;
    }
        .cantidad-producto{
            font-family: 'Roboto';
            font-size: 1rem;
            font-weight: 500;
            color: #000;

            text-align: right;
            padding: 0.3rem 0.5rem;
        }


    .price-section{
        grid-column: 6;
        grid-row: 1/3;
    }

        .precio-producto{
            font-family: 'Roboto';
            font-size: 1.2rem;
            font-weight: 600;
            text-align: right;
        }

    .resume-price{
        
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 0.3rem;

        border-top: solid 1px #9ae4f7;
    }

    .title{
        font-family: 'Quicksand';
        font-weight: 500;
        font-size: 1.2rem;

        text-align: right;
        padding: 0 0.3rem 0 0;

    }

    .content{
        font-family: 'Roboto';
        font-weight: 600;
        font-size: 1.2rem;

        text-align: right;
    }

    .one-left{
        grid-column: 4/6;
        grid-row: 1;

        background-color: rgba(77, 211, 244, 0.571);

    }

    .one-right{
        grid-column: 6;
        grid-row: 1;

        background-color:  rgba(77, 211, 244, 0.571);
    }

    .two-left{
        grid-column: 4/6;
        grid-row: 2;
    }

    .two-right{
        grid-column: 6;
        grid-row: 2;
    }

    .three-left{
        grid-column: 4/6;
        grid-row: 3;

        background-color: rgba(77, 211, 244, 0.571);
    }

    .three-right{
        grid-column: 6;
        grid-row: 3;

        background-color: rgba(77, 211, 244, 0.571);
    }
    



</style>