<template>
    <div>
        <Menu_navbar />
        <router-view />
        <Loading v-if="$store.state.loading"></Loading>
        <alerta v-if="$store.state.activar_alerta" :mensaje="$store.state.mensaje_alerta"  @cerrarAlerta="cerrarAlerta"></alerta>

        <Inferior_navbar />
    </div>
</template>

<script>
/* Importando componentes estaticos usuario */
import Menu_navbar from '@/components/UserComponents/Menu_navbar.vue'
import Inferior_navbar from '@/components/UserComponents/Inferior_navbar.vue'
import Loading from '@/components/Loading.vue'
import alerta from '@/components/VentanaEmergente.vue'

export default {
    name: 'UserView',
    components: {
        Menu_navbar,
        Inferior_navbar,
        Loading,
        alerta
    },
    methods:{
        cerrarAlerta() {
            try {
                // Actualizar el estado en el componente padre        
                this.$store.commit('setactivar_alerta', false);
            } catch (error) {
            }
        },
    },
    // computed: {
    //     // Utiliza una propiedad computada para obtener el estado de carga desde Vuex
    //     loading() {
    //         return this.$store.state.loading;
    //     },
    // },

    // watch: {
    //     // Utiliza un watcher para reaccionar a cambios en el estado de carga y actualizar la propiedad local
    //     loading(newLoadingState) {
    //         // Actualiza la propiedad local
    //         this.$data.loading = newLoadingState;
    //     },
    // },
    mounted() {
        document.title = 'PuntoTuxtleco'
        this.$cookies.set('estado', 1)
    }
}
</script>

<style scoped></style>