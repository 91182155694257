<script>
import CategoriaList from "@/components/AdminComponents/CategoriasComponents/CategoriaList.vue";
import CategoriaForm from "@/components/AdminComponents/CategoriasComponents/CategoriaForm.vue"

/*Impportando servicios*/
import CategoriasService from '@/services/CategoriasService'

    export default{
        name: 'CategoriasView',
        components:{
            CategoriaList,
            CategoriaForm,
        },
        data(){
            return{
                categorias: [],
                categoria: {
                    id: 0,
                    nombre: ''
                },
                stateCharge: false
            }
        },
        methods: {
            async getAllCategorias(){
                this.stateCharge = true
                const response = await CategoriasService.getCategorias()
                this.categorias = response
                this.stateCharge = false
            },
            async createCategoria(){
                this.stateCharge = true
                const response = await CategoriasService.createCategoria(this.$cookies.get('token'), this.categoria)
                if(response.success){
                    this.resetLocalCategoria()
                    this.stateCharge = false
                    this.getAllCategorias()
                }
            },
            async updateCategoria(){
                this.stateCharge = true
                const response = await CategoriasService.updateCategoria(this.$cookies.get('token'), this.categoria.id, this.categoria)
                if(response.success){
                    this.resetLocalCategoria()
                    this.stateCharge = false
                    this.getAllCategorias()
                }
            },
            async deleteCategoria(){
                this.stateCharge = true
                const response = await CategoriasService.deleteCategoria(this.$cookies.get('token'), this.categoria.id)
                if(response.success){
                    this.resetLocalCategoria()
                    this.stateCharge = false
                    this.getAllCategorias()
                }
            },
            setLocalCategoria(categoria){
                this.categoria = categoria
            },
            resetLocalCategoria(){
                this.categoria = {
                    id: 0,
                    nombre: ''
                }
            }
        }
    }
</script>

<template>
    <div>
        <h1 :class="['titulo_vista','tbg']">Lista de categorias</h1>
        <div :class="['short-view']">
            <CategoriaForm 
                :class="['short-view-child', 'view-form']"
                @createCategoria="createCategoria"
                @updateCategoria="updateCategoria"
                @deleteCategoria="deleteCategoria"
                :categoria="categoria"
            />
            <CategoriaList 
                :class="['short-view-child']"
                @getAllCategorias="getAllCategorias"
                @setLocalCategoria="setLocalCategoria"
                @resetLocalCategoria="resetLocalCategoria"
                :categoria="categoria"
                :categorias="categorias"
                :stateCharge="stateCharge"
            />
        </div>
        
    </div>
</template>

<style scoped>
    
</style>