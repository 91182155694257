import axios from "axios";
import store from '@/store';

async function createCheckoutSession(line_items, success_url, cancel_url){
    const apiUrl = store.state.apiRender+'create-checkout-session'

    try {
        const response = axios.post(apiUrl, {
            line_items : line_items,
            success_url : success_url,
            cancel_url : cancel_url
        })

        return {response : response, success: 'true'}
    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

export default{
    createCheckoutSession
}