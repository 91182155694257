<script>
import CategoriaPost from "./CategoriaPost";
import CircleLoader from "@/components/CircleLoader.vue";

    export default{
        name: 'CategoriasList',
        components:{
            CategoriaPost,
            CircleLoader,
        },
        mounted(){
            this.getAllCategorias()
        },
        props:{
            categorias: Array,
            categoria: Object,
            stateCharge: Boolean
        },
        data(props){
            return{
                
            }
        },
        emits:['getAllCategorias','setLocalCategoria','resetLocalCategoria'],
        methods: {
            async getAllCategorias(){
                this.$emit('getAllCategorias')
            },
            async setLocalCategoria(categoria){
                this.$emit('setLocalCategoria', categoria)
            },
            async resetLocalCategoria(){
                this.$emit('resetLocalCategoria')
            }
        }
    }
</script>

<template>
    <section :class="['container-post-categoria']">
        <CircleLoader 
            :activo="stateCharge"
        />

        <CategoriaPost  
            v-for="itemcategoria in categorias" :key="itemcategoria.id"
            :categoria="itemcategoria"
            :selected=" (itemcategoria.id == categoria.id) ? true : false "
            @setLocalCategoria="setLocalCategoria"
            @resetLocalCategoria="resetLocalCategoria"
        />
    </section>
</template>

<style scoped>
    .container-post-categoria{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        gap: 0.5rem;

        padding: 1rem;
    }
</style>