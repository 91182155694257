<script>
    export default{
        name: 'ProductorAgregarForm',
        props: {
            productor: Object,
            qrs: Array
        },
        mounted(){
            this.getQRs()
        },
        data(){
            return{
                selectedFile: null
            }
        },
        emits: ['getQRs','createProductor'],
        methods: {
            async createProductor(){
                const formData = new FormData();
                formData.append('nombre', this.productor.nombre);
                formData.append('descripcion', this.productor.descripcion);
                formData.append('telefono', this.productor.telefono);
                formData.append('correo', this.productor.correo);
                formData.append('img', this.productor.img);
                formData.append('activo', this.productor.activo);
                formData.append('id_qr', this.productor.id_qr);
                formData.append('file', this.selectedFile);

                console.log({ formData })
                this.$emit('createProductor',formData)
            },
            async getQRs(){
                this.$emit('getQRs')
            },
            activateInput(elementId){
                document.getElementById(elementId).click()
            },
            handleFileChange(event){
                this.selectedFile = event.target.files[0];
                this.productor.img = this.selectedFile.name;
                const img = document.getElementById('imgPreviewProductor');
                const reader = new FileReader()
                reader.onload = function (e) {
                    img.src = e.target.result;
                }
                reader.readAsDataURL(event.target.files[0])
                console.log(this.selectedFile.name)
            },
        },
        computed: {
            hasFileSelected: function(){
                return (this.selectedFile===null) ? false : true
            },
            lengthOfDescripcion: function(){
                return this.productor.descripcion.length
            },
            itsComplete: function(){
                if(!this.hasFileSelected || !this.productor.nombre || !this.productor.descripcion || !this.productor.correo || !this.productor.telefono /* || !this.productor.img */|| this.productor.id_qr===0){
                    return false
                }
                return true
            },
            estadoProductorActivo: function () {
                return (this.productor.activo) ? 'Activo' : 'Inactivo'
            }
        }
    }
</script>

<template>
    <div :class="['template_form']">
        <form enctype="multipart/form-data">
            <input
                :class="['input-file-img']"
                type="file" 
                id="fileimgproductor"
                ref="fileImageInputImgProductor" 
                accept="image/*" 
                @change="handleFileChange"
            />
            <button
                v-show="!hasFileSelected"
                :class="['normal-btn','bbg','round']" 
                type="button"
                @click="activateInput('fileimgproductor')"
            >
                Cargar imagen
            </button>

            <section
                v-show="hasFileSelected"
                :class="['preview-upload-img']"
            >
                <img 
                    id="imgPreviewProductor" 
                    alt="Imagen productor" 
                    loading="lazy"
                    @click="activateInput('fileimgproductor')"
                />
            </section>

            <section
                :class="['option-check']"
            >
                <label :class="['label_check']">Estado: <strong>{{ estadoProductorActivo }}</strong> </label>
                <div :class="['check-field']">
                    <input 
                        v-model="productor.activo"
                        id="activo_productor" 
                        :class="['offscreen','original_check']" 
                        type="checkbox"
                    >
                    <label for="activo_productor" :class="['new_check']">.</label>
                </div>
            </section>

            <div :class="['field_textarea','field-cyan']">
                <input
                    v-model="productor.nombre"
                    :class="['input_field','round']"
                    type="text"
                    id="nombre_productor"
                    placeholder="..."
                >
                <label :class="['msg','m-l']" for="nombre_productor">Nombre</label>
            </div>

            <div :class="['container-field-input']">
                <div :class="['field_textarea','field-cyan']">
                    <input
                        v-model.number="productor.telefono"
                        :class="['input_field','round']"
                        type="text"
                        id="telefono_productor"
                        maxlength="10"
                        placeholder="..."
                    >
                    <label :class="['msg','m-l']" for="telefono_productor">Telefono</label>
                </div>
                <div :class="['field_textarea','field-cyan']">
                    <input
                        v-model="productor.correo"
                        :class="['input_field','round']"
                        type="text"
                        id="correo_productor"
                        placeholder="..."
                    >
                    <label :class="['msg','m-l']" for="correo_productor">Correo</label>
                </div>
            </div>

            <div :class="['field_textarea','field-cyan']">
                <textarea 
                    v-model="productor.descripcion" 
                    :class="['input_field','round']"
                    id="descripcion_productor"
                    maxlength="250"
                    placeholder="..."
                ></textarea>
                <label :class="['msg','m-l']" for="descripcion_productor">Descripcion</label>
                <span :class="['msg','m-r']"> {{ lengthOfDescripcion }}/250</span>
            </div>

            <section :class="['select-design']">
                <select 
                    :class="['select_options']" 
                    v-model="productor.id_qr"
                >
                    <option 
                        disabled 
                        :value="0"
                    >
                        Seleccione un qr
                    </option>
                    <option
                        v-for="qr in qrs" :key="qr.id"
                        :value="qr.id"
                    >
                        {{ qr.name_qr }}
                    </option>
                </select>
            </section>

            <div
                :class="['button-container','end']"
            >
                <button
                    :class="[{'success-btn': itsComplete, 'disabled-btn': !itsComplete},'round']"
                    type="button"
                    :disabled="!itsComplete"
                    @click="createProductor"
                >
                    Guardar
                </button>
            </div>
            
        </form>
    </div>
</template>

<style scoped>
form .input-file-img {
    display: none;
}

.offscreen{
    display: none;
}

.template_form{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-block: 0.5rem;
}

.template_form form{
    flex-basis: 700px;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.preview-upload-img{
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    justify-content: center;
    gap: 1rem 0.3rem;
}

.preview-upload-img img{
    align-self: center;
    max-width: 200px;
    min-width: 150px;
    max-height: 200px;
    padding: 0.3rem;
    object-fit: contain;

    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.50) 0px 5px 15px;
    cursor: pointer;
}
.select-design{
    display: flex;
    flex-flow: row wrap;
}
.select_options{
    flex-basis: 100%;

    margin-inline: 0.5rem;
    padding: 0.5rem 1rem;
    border: solid 1px #e3e5e7;
    background-color: white;
    font-family: 'Roboto', sans-serif;
    border-radius: 0.3rem;
    font-size: 0.9rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.option-check{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding-inline: 1rem;
}

.label_check{
    font-family: 'Roboto';
    font-size: 1rem;
}

.label_check strong{
    color: #096981;
}

.check-field{
    margin-block: 0.5rem;
}
.check-field .new_check{
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
    background-color: #888;
    color: transparent;
    border-radius: 26px;
    cursor: pointer;
    margin: 0;
    transition: background-color 300ms ease 300ms;
}

.check-field .new_check::after{
    content: ' ';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #fff;
    left: 3px;
    top: 3px;
    transition: transform 300ms ease;
}

.check-field input[type='checkbox']:checked + .new_check::after{
    transform: translateX(24px);
}

.check-field input[type='checkbox']:checked + .new_check{
    background-color: #0dabd3;
}


.button-container{
    margin-block: 0.5rem;
}

</style>