<script>
import QRList from "@/components/AdminComponents/QRComponents/QRList.vue";
import QRForm from "@/components/AdminComponents/QRComponents/QRForm.vue";

import QRService from "@/services/QRService";
import UploadImgService from "@/services/UploadImgService";
    export default{
        name: 'QRView',
        components:{
            QRForm,
            QRList
        },
        data(){
            return{
                qrs: [],
                qr: {
                    id: 0,
                    name_qr: ''
                },
                stateCharge: false
            }
        },
        methods: {
            async getQRs(){
                this.stateCharge = true
                const response = await QRService.getQRs()
                if (response.success){
                    this.qrs = response.qrs
                }
                this.stateCharge = false
            },
            async createQR(formData){
                this.stateCharge = true
                const response = await QRService.createQR(this.$cookies.get('token'), formData)
                if(response.success){
                    this.resetLocalQR()
                    this.getQRs()
                    alert("El archivo se ha subido satisfactoriamente :)")
                }else{
                    alert("Oh no, algo ha salido mal :(, por favor intentelo de nuevo mas tarde");
                }
                this.stateCharge = false
            },
            async updateNameQR(idQR, name_qr, new_name_qr){
                this.stateCharge = true
                const response = await QRService.updateQR(this.$cookies.get('token'),idQR, name_qr, new_name_qr)
                if(response.success){
                    console.log(`Se ha cambiando nombre de ${name_qr} por ${new_name_qr}`)
                    this.resetLocalQR()
                    this.getQRs()
                }
                this.stateCharge = false
            },
            async updateImgQR(formData){
                this.stateCharge = true
                const response = await UploadImgService.UpdateImgSingleQR(this.$cookies.get('token'), formData)
                if(response.success){
                    this.resetLocalQR()
                    this.getQRs()
                    alert("El archivo actualizo satisfactoriamente :)")
                }else{
                    alert("Oh no, algo ha salido mal :(, por favor intentelo de nuevo mas tarde");
                }
                this.stateCharge = false
            },
            async deleteImgQR(idQR){
                this.stateCharge = true
                const response = await QRService.deleteQR(this.$cookies.get('token'), idQR)
                if (response.success){
                    this.resetLocalQR()
                    this.getQRs()
                    alert(`El QR con id ${idQR} se ha eliminado`)
                }else{
                    alert("Oh no, algo ha salido mal :(, por favor intentelo de nuevo mas tarde");
                }
                this.stateCharge = false
            },
            setLocalQR(qr){
                this.qr = qr
            },
            resetLocalQR(){
                this.qr = {
                    id: 0,
                    name_qr: ''
                }
            }
        }
    }
</script>

<template>
    <div>
        <h1 :class="['titulo_vista','tbg']">Lista de QRs</h1>
        <div :class="['short-view']">
            <QRForm 
                :class="['short-view-child','view-form']"
                @createQR="createQR"
                @updateImgQR="updateImgQR"
                :qr="qr"
            />

            <QRList 
                :class="['short-view-child']"
                @setLocalQR="setLocalQR"
                @resetLocalQR="resetLocalQR"
                @getQRs="getQRs"
                @updateNameQR="updateNameQR"
                @deleteImgQR="deleteImgQR"
                :qrs="qrs"
                :qr="qr"
                :stateCharge="stateCharge"
            />
        </div>
    </div>
</template>

<style scoped>
.short-view{
    display: flex;
    flex-flow: row-reverse wrap;
    align-items: flex-start;
    gap: 0.5rem;
}

.short-view-child{
    flex: 3;
}

.view-form{
    padding: 0 0.5rem;
    flex: 1;
}

@media (max-width: 800px){
    .short-view{
        flex-flow: column wrap;
        align-items: stretch;
        flex: 0;
    }
}
</style>