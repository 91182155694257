<script>
    export default{
        name: 'QRForm',
        props: {
            qr: Object
        },
        data(props){
            return{
                selectedFile: null,
            }
        },
        emits: ['createQR','updateImgQR'],
        methods: {
            getImageUrl(imageName) {
                return imageName
            },
            async createQR(){
                console.log(this.selectedFile)
                if (this.selectedFile) {
                    const formData = new FormData();
                    formData.append('name_qr', this.selectedFile.name)
                    formData.append('file', this.selectedFile);
                    console.log({ formData })

                    this.$emit('createQR',formData)
                    this.selectedFile = null
                }
            },
            async updateImgQR(){
                console.log(this.selectedFile)
                if (this.selectedFile) {
                    const formData = new FormData();
                    formData.append('name_qr', this.qr.name_qr)
                    formData.append('file', this.selectedFile);
                    console.log({ formData })

                    this.$emit('updateImgQR', formData)
                    this.selectedFile = null
                }
            },
            cancelUpload(){
                this.selectedFile = null
            },
            handleFileChange(event) {
                this.selectedFile = event.target.files[0];
                const img = document.getElementById('imgPreviewQR');
                const reader = new FileReader()
                reader.onload = function (e) {
                    img.src = e.target.result;
                }
                reader.readAsDataURL(event.target.files[0])
                console.log(this.selectedFile.name)
            },
            activateInput(elementId){
                document.getElementById(elementId).click()
            },
            
        },
        computed: {
            hasFileSelected: function(){
                return (this.selectedFile===null) ? false : true
            },
            noHasData: function(){
                return this.qr.id===0
            }
        }
    }
</script>

<template>
    <form enctype="multipart/form-data">
        <input type="file" id="fileqr" ref="fileImageInputQR" accept="image/*" @change="handleFileChange" > <!--  -->
        <button
            v-show="!hasFileSelected"
            :class="['normal-btn','bbg']" 
            type="button"
            @click="activateInput('fileqr')"
        >
            Subir Imagen
        </button>
        <article 
            v-show="hasFileSelected"
            :class="['preview-upload-img']"
        >
            <img id="imgPreviewQR" alt="Imagen cargada" @click="activateInput('fileqr')">
            <!-- <section :class="['post-upload-img']">

            </section> -->
            <div :class="['button-container','start']">
                <button 
                    :class="['normal-btn','round']" 
                    type="button"
                    @click="createQR"
                >
                    <i class="fa-solid fa-cloud-arrow-up"></i> Guardar
                </button>
                <button 
                    :class="[{'outline-btn': !noHasData, 'disabled-btn': noHasData},'round']" 
                    type="button"
                    @click="updateImgQR"
                    :disabled="noHasData"
                >
                    Actualizar QR: {{ qr.name_qr }}  
                </button>
                <button 
                    :class="['delete-btn','round']" 
                    type="button"
                    @click="cancelUpload"
                >
                    Cancelar
                </button>
            </div>
        </article>

        <!-- <p>QR ID: {{ qr.id }} </p> -->
    </form>
</template>

<style scoped>
    form input {
        display: none;
    }
    .preview-upload-img{
        display: flex;
        flex-flow: column wrap;
        align-content: center;
        justify-content: center;
        gap: 1rem 0.3rem;
    }

    .preview-upload-img img{
        align-self: center;
        max-width: 200px;
        min-width: 150px;
        padding: 0.3rem;
        
        border-radius: 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.50) 0px 5px 15px;
        cursor: pointer;
    }
    .post-upload-img{
        width: 200px;
        flex-basis: 150px;
        border: solid 1px #000;
    }
</style>