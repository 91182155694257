import { render, staticRenderFns } from "./PedidoList.vue?vue&type=template&id=5f98c8fa&scoped=true&"
import script from "./PedidoList.vue?vue&type=script&lang=js&"
export * from "./PedidoList.vue?vue&type=script&lang=js&"
import style0 from "./PedidoList.vue?vue&type=style&index=0&id=5f98c8fa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f98c8fa",
  null
  
)

export default component.exports