<script>
import CapacidadList from "@/components/AdminComponents/CapacidadComponents/CapacidadList.vue";
import CapacidadForm from "@/components/AdminComponents/CapacidadComponents/CapacidadesForm.vue"

/*importando servicios*/
import CapacidadService from "@/services/CapacidadService";

    export default{
        name: 'CapacidadesView',
        components:{
            CapacidadForm,
            CapacidadList
        },
        data(){
            return{
                capacidades: [],
                capacidad: {
                    id: 0,
                    cantidad: 1,
                    unidad_medida:""
                },
                stateCharge: false
            }
        },
        methods: {
            async getCapacidades(){
                this.stateCharge = true
                const response = await CapacidadService.getCapacidades()
                if(response.success){
                    this.capacidades = response.capacidades
                }
                this.stateCharge = false
            },
            async createCapacidad(){
                this.stateCharge = true
                const response = await CapacidadService.createCapacidad(this.$cookies.get('token'), this.capacidad)
                if(response.success){
                    this.resetLocalCapacidad()
                    this.getCapacidades()
                }else{
                    alert('Algo ha salido mal :(, por favor intente de nuevo mas tarde...')
                }
                this.stateCharge = false
            },
            async updateCapacidad(){
                this.stateCharge = true
                const response = await CapacidadService.updateCapacidad(this.$cookies.get('token'), this.capacidad.id, this.capacidad)
                if(response.success){
                    this.resetLocalCapacidad()
                    this.getCapacidades()
                }else {
                    alert('Algo ha salido mal :(, por favor intente de nuevo mas tarde...')
                }
                this.stateCharge = false
            },
            async deleteCapacidad(){
                this.stateCharge = true
                const response = await CapacidadService.deleteCapacidad(this.$cookies.get('token'), this.capacidad.id)
                if(response.success){
                    this.resetLocalCapacidad()
                    this.getCapacidades()
                }else {
                    alert('Algo ha salido mal :(, por favor intente de nuevo mas tarde...')
                }
                this.stateCharge = false
            },
            setLocalCapacidad(capacidad){
                this.capacidad = capacidad
            },
            resetLocalCapacidad(){
                this.capacidad = {
                    id: 0,
                    cantidad: 1,
                    unidad_medida:""
                }
            }
        }
    }
</script>

<template>
    <div>
        <h1 :class="['titulo_vista','tbg']">Lista de Capacidades</h1>
        <div :class="['short-view']">
            <CapacidadForm 
                :class="['short-view-child', 'view-form']"
                @createCapacidad="createCapacidad"
                @updateCapacidad="updateCapacidad"
                @deleteCapacidad="deleteCapacidad"
                :capacidad="capacidad"
            />
            <CapacidadList 
                :class="['short-view-child']"
                @setLocalCapacidad="setLocalCapacidad"
                @resetLocalCapacidad="resetLocalCapacidad"
                @callCapacidades="getCapacidades"
                :capacidades="capacidades"
                :capacidad="capacidad"
                :stateCharge="stateCharge"
            />
        </div>
    </div>
</template>

<style scoped>
    .short-view{
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .short-view-child{
        flex: 1;
    }

    .view-form{
        padding: 0 0.5rem;
    }

    @media (max-width: 800px){
        .short-view{
            flex-flow: column wrap;
            align-items: stretch;
            flex: 0;
        }
    }
</style>