<script>
import EmpaqueList from "@/components/AdminComponents/EmpaquesComponents/EmpaqueList.vue"
import EmpaqueForm from "@/components/AdminComponents/EmpaquesComponents/EmpaqueForm.vue"

/*Importando servicios*/
import EmpaqueService from "@/services/EmpaqueService";
    export default{
        name: 'EmpquesView',
        components:{
            EmpaqueList,
            EmpaqueForm
        },
        mounted(){

        },
        data(){
            return{
                empaques: [],
                empaque: {
                    id: 0,
                    tipo:""
                },
                stateCharge: false
            }
        },
        methods: {
            async getEmpaques(){
                this.stateCharge = true
                const response = await EmpaqueService.getEmpaques()
                if(response.success){
                    this.empaques = response.empaques
                }
                this.stateCharge = false
            },
            async createEmpaque(){
                this.stateCharge = true
                const response = await EmpaqueService.createEmpaque(this.$cookies.get('token'), this.empaque)
                if(response.success){
                    this.resetLocalEmpaque()
                    this.getEmpaques()
                }else {
                    alert('Algo ha salido mal :(, por favor intente de nuevo mas tarde...')
                }
                this.stateCharge = false
            },
            async editEmpaque(){
                if (this.empaque.id!=0) {
                    this.stateCharge = true
                    const response = await EmpaqueService.updateEmpaque(this.$cookies.get('token'), this.empaque.id, this.empaque)
                    if(response.success){
                        this.resetLocalEmpaque()
                        this.getEmpaques()
                    }else{
                        alert('Algo ha salido mal :(, por favor intente de nuevo mas tarde...')
                    }
                    this.stateCharge = false
                }else{
                    alert("Seleccione un elemento de la lista")
                }

            },
            async deleteEmpaque(){
                this.stateCharge = true
                const response = await EmpaqueService.deleteEmpaque(this.$cookies.get('token'), this.empaque.id)
                if(response.success){
                    this.resetLocalEmpaque()
                    this.getEmpaques()
                }else{
                    alert('Algo ha salido mal :(, por favor intente de nuevo mas tarde...')
                }
                this.stateCharge = false
            },
            setLocalEmpaque(empaque){
                this.empaque = empaque
            },
            resetLocalEmpaque(){
                this.empaque = {
                    id: 0,
                    tipo: ''
                }
            }
        }
    }
</script>

<template>
    <div>
        <h1 :class="['titulo_vista','tbg']">Lista de empaques</h1>
        <div :class="['short-view']">
            <EmpaqueForm 
                :class="['short-view-child', 'view-form']" 
                @createEmpaque="createEmpaque"
                @editEmpaque="editEmpaque"
                @deleteEmpaque="deleteEmpaque"
                :empaque="empaque" 
            />
            <EmpaqueList 
                :class="['short-view-child']" 
                @setLocalEmpaque="setLocalEmpaque" 
                @resetLocalEmpaque="resetLocalEmpaque"
                @callEmpaques="getEmpaques"
                :empaques="empaques"
                :empaque="empaque"
                :stateCharge="stateCharge"
            />
        </div>
    </div>
</template>

<style>
    .short-view{
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .short-view-child{
        flex: 1;
    }

    .view-form{
        padding: 0 0.5rem;
    }

    @media (max-width: 800px){
        .short-view{
            flex-flow: column wrap;
            align-items: stretch;
            flex: 0;
        }
    }
</style>