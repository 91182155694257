<template>
    <div :class="['container-form-login']">
        <Loading v-if="$store.state.loading"></Loading>
        <alerta v-if="$store.state.activar_alerta" :mensaje="$store.state.mensaje_alerta" @cerrarAlerta="cerrarAlerta">
        </alerta>

        <form v-if="!$store.state.loading && !$store.state.activar_alerta" :class="['form-login']" @keydown.enter="loginOnEnter">
            <h1 :class="['header-login']">Iniciar sesión</h1>

            <!-- <label for="in-correo">Ingrese correo</label> -->
            <input :class="['input-login']" style="margin-left: 7px;" v-model="correo" id="in-correo" type="text"
                placeholder="Direccion de correo electronico" >

            <!-- <label for="in-contra">Ingrese contraseña</label> -->
            <div>
                <input :class="['input-login']" v-model="contrasenia" id="in-contra" placeholder="Contraseña"
                    type="password" >
                <input style="margin-left: .5rem;" type="checkbox" id="showPassword" v-model="showPassword"
                    @change="togglePasswordVisibility" />
            </div>
            <button :class="['btn-login']" @click="login" type="button">acceder</button>
            <button :class="['btn-login']" @click="Redireccionar_createAccount" type="button">crear cuenta</button>

            <a :href="'/recuperar_contrasena'">¿Olvido su contraseña?</a>
        </form>
        <picture v-if="!$store.state.loading && !$store.state.activar_alerta" :class="['image-container']">
            <img src="@/assets/categorias_img/PuntoTuxtleco_2.png" alt="imagen_bienvenida">
        </picture>
    </div>
</template>

<script>
import auth from '@/services/AuthService'
import Loading from '@/components/Loading.vue'
import alerta from '@/components/VentanaEmergente.vue'

export default {
    name: 'LoginView',
    data() {
        return {
            correo: '',
            contrasenia: '',
            token: '',
            showPassword: false,
            id_user: '',
        }
    },
    components: {
        Loading,
        alerta
    },
    mounted() {
        document.title = 'Login | PuntoTuxtleco'
    },
    methods: {
        togglePasswordVisibility() {
            const passwordInput = document.getElementById("in-contra");

            if (this.showPassword) {
                passwordInput.type = "text";
            } else {
                passwordInput.type = "password";
            }
        },
        cerrarAlerta() {
            try {
                // Actualizar el estado en el componente padre        
                this.$store.commit('setactivar_alerta', false);
            } catch (error) {
            }
        },
        loginOnEnter(event) {
      if (event.keyCode === 13) {
        this.login();
      }
    },
        async login() {
            try {
                this.$store.commit('setLoading', true);
                const response = await auth.login(this.correo, this.contrasenia)
                if (response.success) {
                    console.log(response.message)
                    window.location.href = "/"
                } else {
                    this.$store.commit('setactivar_alerta', true);
                    this.$store.commit('setmensaje_alerta', 'Verifique su correo');
                }
                this.$store.commit('setLoading', false);
            } catch (error) {
                this.$store.commit('setLoading', false);
            }
        },
        Redireccionar_createAccount() {
            const nuevaRuta = {
                name: 'crear_cuenta'
            }
            const nuevaRutaCompleta = this.$router.resolve(nuevaRuta).href;
            if (this.$router.currentRoute.path.replace(/\//g, '') !== nuevaRutaCompleta.replace(/\//g, '')) {
                this.$router.push(nuevaRuta);
            }
        }
    }
}
</script>

<style>
.container-form-login {
    background-image: radial-gradient(circle, #0dabd3ad, #9fe7f9d8), url("../assets/tiendas_fisicas/sucursal1.jpg");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    display: flex;
    flex-flow: row wrap;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.form-login {
    background-color: rgba(255, 255, 255, 0.541);
    backdrop-filter: blur(1px);
    border: solid 2px #0dabd3;
    border-radius: 0.3rem;
    max-width: 400px;
    width: 100%;
    height: 400px;

    display: flex;
    flex-flow: column wrap;
    padding: 2rem 1rem;

    justify-content: center;
    gap: 0.5rem;
}

.header-login {
    font-size: 1.5rem;
    font-family: 'Quicksand';
    color: black;

}

.header-login img {
    width: 3rem;
    height: auto;
}

.input-login {
    border: solid 2px #bbb;
    width: 90%;
    border-radius: 0.3rem;
    font-size: 1rem;
    padding: 0.6rem 0.7rem;


    font-family: 'Quicksand';
    font-weight: 400;

    outline: none;
}

.input-login:focus {
    border: solid 2px #0dabd3;
    box-shadow: 0px 3px 1px #222;
}

.btn-login {
    border-radius: 0.5rem;
    background-color: #9fe7f9;

    border: solid 1px #333;

    font-family: 'Roboto';
    font-weight: 400;
    font-size: 1rem;
    color: #222;
    padding: 0.3rem 0.5rem;
    text-transform: capitalize;
    box-shadow: 0px 1px 3px #222;

    transition-property: background background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
}

.btn-login:hover {
    background-color: #0dabd3;

    color: white;
    font-weight: 600;
    box-shadow: 0px 3px 1px #222;
}



.image-container {
    background: linear-gradient(#9fe7f9, #0dabd3);
    border: solid 2px #0dabd3;
    overflow: hidden;

    max-width: 300px;
    width: 100%;

    height: 400px;

    display: flex;
    justify-content: center;
}

.image-container img {
    width: 100%;
    height: fit-content;
    align-self: center;
    padding: 0.5rem;
    /*object-fit: cover;*/

    /*transform: scale(1.4) /*translate(30px, 5%);*/
}

@media (max-width: 750px) {
    .image-container {
        display: none;
    }
}
</style>