<template>
    <div>
        <h1 :class="['titulo_vista']">Lista de ventas</h1>
        <VentasList/>
    </div>
</template>

<script>
import VentasList from '@/components/AdminComponents/VentasList.vue'

    export default {
        name: 'VentasView',
        components:{
            VentasList
        }
    }
</script>

<style>

</style>