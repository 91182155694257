<template>
  <div class="container-components">
    <!-- <TablesHead />
    <router-view /> -->
    <!-- <SelectTable @getTypeTable="getTypeTable" @getShowForm="getShowForm" :add_new="add_new"/>
    
    <div :class="['container-form']" v-show=" add_new ">
      <ProductosForm v-if="type_table == 'Productos'"/>
      <ProductoresForm v-else-if="type_table == 'Productores'"/>
      <CapacidadesForm v-else-if="type_table == 'Capacidades'"/>
      <EmpaquesForm v-else-if="type_table == 'Empaques'"/>
      <TiendasForm v-else-if="type_table == 'Tiendas'"/>

      <div class="container-btn">
        <button :class="['btn-rounded', 'btn-save']" type="button"><i class="fa-solid fa-floppy-disk"></i> Guardar</button>
        <button :class="['btn-rounded', 'btn-cancel']" type="button"><i class="fa-solid fa-xmark upi"></i> Cancelar</button>
        </div>
    </div>
    <TableAdmin :table="type_table"/> -->
  </div>
</template>

<script>
  /* import TablesHead from '@/components/AdminComponents/TablesHead.vue' */

  /* import SelectTable from '@/components/AdminComponents/SelectTable.vue'
  import TableAdmin from '@/components/AdminComponents/TableAdmin.vue' */

  /*Forms para agregar nvs objs*/
  /* import ProductosForm from '@/components/AdminComponents/FormObjects/ProductosForm.vue'
  import ProductoresForm from '@/components/AdminComponents/FormObjects/ProductoresForm.vue'
  import CapacidadesForm from '@/components/AdminComponents/FormObjects/CapacidadesForm.vue'
  import EmpaquesForm from '@/components/AdminComponents/FormObjects/EmpaquesForm.vue'
  import TiendasForm from '@/components/AdminComponents/FormObjects/TiendasForm.vue' */

    export default {
      name: 'AdministrarView',
      components: {
        /* TablesHead, */
        /* SelectTable,
        TableAdmin,
        ProductosForm,
        ProductoresForm,
        CapacidadesForm,
        EmpaquesForm,
        TiendasForm */
      }/*,
      data(){
        return{
          type_table: 'Productos',
          add_new: false,
        }
      },
      methods: {
        getTypeTable(type_table){
          this.type_table = type_table //asignamos new val -> type_table
        },
        getShowForm(add_new){
          this.add_new = add_new //asignamos new val -> add_new
        }
      } */
    }
</script>

<style>
  .container-components{
    display: flex;
    flex-flow: column wrap;
    padding-inline: 0.5rem;
    /*gap: 0.5rem;
    margin: 0 1rem;*/
  }

  .container-components .container-form{
    background-color: #ECFAFE;
    border: solid 1px #eee;
    border-radius: 0.5rem;
    padding: 1rem 0.5rem;

    display: flex;
    flex-flow: column wrap;

  }

  .container-btn{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }

  .btn-rounded{
    font-family: 'Roboto';
    font-weight: 600;
    border-radius: 1.5rem;
    padding: 0.5rem 0.8rem;
    border: solid 1px #9FE7F9;

    transition-property: background-color color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
  }

  .btn-rounded:hover{
    background-color: #075E73;
    color: #fff;
  }

  .btn-save{
    color: #fff;
    background-color: #0DABD3;
    
  }

  .btn-cancel{
    background-color: #9FE7F9;
  }


  /*elementos de los formularios*/
  .form{
    max-width: 800px;
    width: 100%;
    margin-bottom: 0.5rem;

    align-self: center;
    
    display: flex;
    flex-flow: column wrap;
  }

  .title-form{
    color: #fff;
    text-shadow: 1px 1px 2px #075E73;
    font-family: 'Anton';
    font-size: 1.2rem;
    text-align: start;

    background-color: #0DABD3;
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 0.5rem;
    margin: 0;
  }

  .description-form{
    color: #444;
    font-family: 'Quicksand';
    font-size: 0.9rem;
    text-align: start;
    margin: 0 0.5rem 0.5rem;
  }

  /*contenedor de inputs que van en la misma linea*/
  .line-material-form{
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
  }

  /*Estilizando input estandar*/

  .input-material-form{
    position: relative; 
    display: flex;
    flex-flow: column wrap;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .input-material-form input{
    background-color: transparent;
    border: solid 2px #4DD3F4;
    border-radius: 0.3rem;
    outline: none;
    padding: 0.4rem 0.3rem 0 0.5rem;
    margin: 0.3rem 0.5rem;
  }

  .input-material-form label{
    color: #888;
    background-color: #ECFAFE;
    border-radius: 0.3rem;
    font-family: 'Roboto';
    font-size: 1rem;
    text-align: start;
    position: absolute;
    margin: 0 0.5rem;

    top: 50%;
    left: 0.5rem;
    transform: translateY(-50%);
    pointer-events: none;
    transition: .5s;
  }

  .input-material-form input:valid{
    border: solid 2px #0DABD3;
  }

  .input-material-form input:focus{
    box-shadow: 0 0 3px #0DABD3;
  }

  .input-material-form input:focus ~ label,
  .input-material-form input:valid ~ label{
    top: 5px;
    font-size: 13px;
    padding: 0 2px;
    color: #0DABD3;
  }

  .input-material-form input:valid ~ label{
    color: #0DABD3;
  }

/*------------------<*/

  .component-table{
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    font-size: 0.9rem;
}

.component-table thead{
    background-color: #0dabd3;
    color: white;
}

.component-table tbody .text-left{
    text-align: left;
}

.component-table tbody tr{
    border-bottom: solid 1px #9fe7f9;
}

.component-table td, th {
    padding: 0.3rem;
    word-wrap: break-word;
}

.component-table tbody .element-img {
    overflow: hidden;
    height: 100%;
    max-height: 30px;
}

.component-table tbody .element-img img{
    object-fit: cover;
    height: 100%;
    max-height: 80px;
    max-width: 100px;
}
</style>