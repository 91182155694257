import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // Data
    api_url : "mi_api",
    // apiRender : "https://api-puntotuxtleco.onrender.com/",
    apiRender : "https://api.puntotuxtleco.com.mx/",
    user_data: {
      id: '',
      nombre: '',
      apellido_p:'',
      apellido_m: '',
      correo:'',
      token: '',
    },

    cantidad_productos:0,
    cantidadStock_productos:0,

    loading: false,
    
    mensaje_alerta: '',    
    activar_alerta: false,
  },
  getters: {
    // Computed, es decir hacer modificaciones al dato
    getApiVentas(state){
      return state.api_url+'/ventas'
    },
  },
  mutations: {
    
    setactivar_alerta(state,activo) {
      state.activar_alerta=activo;
    },
    setmensaje_alerta(state,mensaje) {
      state.mensaje_alerta=mensaje;
    },
    setLoading(state, value) {
      state.loading = value;
    },
    setCantidadStockProductos(state, cantidad){
      state.cantidadStock_productos = cantidad;
    },
    setCantidadProductos(state, cantidad){
      state.cantidad_productos = cantidad;
    },
    // Set sync, hacer modificaciones de forma sincrona
    changeApiUrl(state, new_url_api){
      state.api_url = new_url_api;
    },
    setToken(state, token){
      state.user_data.token = token
    },
    setId(state, id){
      state.user_data.id = id
    },
    setDataUser(state, nombre, apellido_p, apellido_m, correo){
      state.user_data.nombre = nombre
      state.user_data.apellido_p = apellido_p
      state.user_data.apellido_m = apellido_m
      state.user_data.correo = correo
    }
  },
  actions: {
    // Set async, hace modificaciones de forma mas compleja
    
  },
  modules: {
    //Nos ayuda a modularizar el store aunque alfinal se carga todo al importarlo
  }
})
