import axios from "axios";

import store from '@/store'
/*Crear el modulo de eliminar carrito detalle, si no hay en la api crearlo, pd. tambien crear el metodo de crear orden y orden_detalle, 

    Recuerda el proceso es:
    1. Crear la orden (id orden se retorna en la api cuando esta es creada)
    2. agrega a orden detalle todos los productos relacionados, verificar
    3. limpia el carrito (es decir elimina las coincidencias de carrito_detalle)

*/
const apiUrl = store.state.apiRender+'carrito_detalle/'

async function cleanCarrito(token, id_carrito){

    try {
        const response = await axios.delete(apiUrl+id_carrito, {
            headers: {
                Authorization: token
            }
        })

        return { response: response, success: true }

    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : e, success: false }
    }

}

export default {
    cleanCarrito,
    
}
