<script>
    export default{
        name: 'CancelPayView',
        mounted(){
            if(this.$cookies.isKey('orden')){
                this.$cookies.remove('orden')
            }
            this.$router.push({ name: 'home' })
        },
        data(){
            return{
                
            }
        },
        methods: {
            
        }
    }
</script>

<template>
    <span>redirigiendo a home</span>
</template>