import axios from "axios";
import store from '@/store'
let apiUrl = store.state.apiRender
let apiCategoria = apiUrl + 'categoria/'

     async function getCategorias(){
        const apiUrl = apiCategoria;
        try {
        // Realizar la solicitud GET a la API
            const response = await axios.get(apiUrl)
            return response.data
        } catch (error) {
            console.log('Error al obtener los datos de categoria '+error)
        }
    }

    async function createCategoria(token, categoria){
        try {
            const response = await axios.post(apiCategoria,
                {
                    nombre: categoria.nombre
                },
                {
                    headers:{
                        Authorization: token
                    }
                }    
            )
            return { creation:response.data, success:true }
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

    async function updateCategoria(token, id, categoria){
        try {
            const response = await axios.patch(apiCategoria+id, 
                {
                    nombre : categoria.nombre
                },
                {
                    headers: {
                        Authorization: token
                    }
                }
            )
            
            return { update: response.data, success: true }

        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

    async function deleteCategoria(token, id){
        try {
            const response = await axios.delete(apiCategoria+id,
                {
                    headers:{
                        Authorization: token
                    }
                }
            )

            return{ delete:response.data, success:true }

        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

export default {
    getCategorias,
    createCategoria,
    updateCategoria,
    deleteCategoria
}