import axios from "axios";
import store from '@/store'

let apiUrl = store.state.apiRender;//'http://localhost:3000/';

    async function getDomiciliosByUserID(token, id){
        try {
            const response = await axios.get(apiUrl+'domicilio-by-user/'+id,
            {
                headers:{
                    Authorization: token
                }
            })
            return { domicilios: response.data.domicilios, success: true }
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

    async function createDomicilio(token, id_usuario, domicilio){
        try{
            const response = await axios.post(
                apiUrl+'domicilio',
                { 
                    calle: domicilio.calle,
                    colonia: domicilio.colonia,
                    estado: domicilio.estado,
                    ciudad: domicilio.ciudad,
                    num_ext: domicilio.num_ext,
                    num_int: domicilio.num_int,
                    pais: domicilio.pais,
                    c_p: domicilio.c_p,
                    id_usuario: id_usuario
                },
                {
                    headers:{
                        Authorization: token
                    }
                }
            )
            return  { message:response.data.message, success: true }

        }catch(e){
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

export default{
    getDomiciliosByUserID,
    createDomicilio
}