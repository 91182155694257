<template>
    <form>
        <div :class="['header-subtitle-low']">
            <h4 :class="['subtitlelow-component']">Añade un domicilio</h4>
            <div :class="['container-buttons']">
                <button :class="['btn-material']" v-show="!domicilio_size == 0" type="button"
                    @click="cambiarVisFormCancel(false)">Cancelar</button>
                <button :class="['btn-material']" type="button" @click="comprobarCamposDomicilio">Guardar</button>
            </div>
        </div>

        <div :class="['container-input-domicilio']">
            <span :class="['text-danger']" v-show="!campos == ''">verifique los campos {{ campos }}, por favor...</span>
            <div :class="['line-material-form']">
                <div :class="['input-material-form']">
                    <input type="text" v-model="domicilio.pais" required><label>Pais</label>
                </div>
                <div :class="['input-material-form']">
                    <input type="text" v-model="domicilio.estado" required><label>Estado</label>
                </div>
            </div>

            <div :class="['line-material-form']">
                <div :class="['input-material-form']">
                    <input type="text" v-model="domicilio.ciudad" required><label>Ciudad</label>
                </div>
                <div :class="['input-material-form']">
                    <input type="text" v-model="domicilio.colonia" required><label>Colonia</label>
                </div>
                <div :class="['input-material-form']">
                    <input type="text" v-model="domicilio.calle" required><label>Calle</label>
                </div>
            </div>

            <div :class="['line-material-form']">
                <div :class="['input-material-form']">
                    <input type="text" v-model="domicilio.num_ext" required><label>Numero exterior</label>
                </div>
                <div :class="['input-material-form']">
                    <input type="text" v-model="domicilio.num_int" required><label>*Numero interior</label>
                </div>
                <div :class="['input-material-form']">
                    <input type="text" v-model="domicilio.c_p" required><label>Codigo Postal</label>
                </div>
            </div>

            <span :class="['info-message']">Los elementos con * son campos opcionales</span>
        </div>
    </form>

    <!-- domicilioUtilizado: {
                    id: '',
                    calle: '',
                    colonia: '',
                    estado: 'Chiapas',
                    ciudad: '',
                    num_ext: '',
                    num_int: '',
                    pais: 'Mexico',
                    c_p: ''
                } -->
</template>

<script>

/*importando servicios*/
import DomiclioService from '@/services/DomicilioService'

export default {
    name: 'FormDomicilioComponent',
    props: {
        domicilio_size: {
            required: true
        }
    },
    data(props) {
        return {
            domicilio: {
                pais: 'Mexico',
                estado: 'Chiapas',
                ciudad: '',
                colonia: '',
                calle: '',
                num_ext: '',
                num_int: '0',
                c_p: ''
            },
            campos: ''
        }
    },
    emits: ['visible', 'recargarDomicilios'],
    methods: {
        async cambiarVisForm(add_domicilio) {
            //Tengo que mandar a llamar al domicilio service y al metodo para actualizar los domicilios en UserInfo
            const response = await DomiclioService.createDomicilio(this.$cookies.get('token'), this.$cookies.get('id_user'), this.domicilio)
            this.$emit("recargarDomicilios")
            this.$emit("visible", !add_domicilio)
        },
        cambiarVisFormCancel(add_domicilio) {
            this.$emit("visible", !add_domicilio)
        },
        comprobarCamposDomicilio() {
            this.campos = ''
            if (this.domicilio.pais && this.domicilio.estado && this.domicilio.ciudad && this.domicilio.colonia && this.domicilio.calle && this.domicilio.num_ext && this.domicilio.c_p) {
                this.domicilio.num_int = (this.domicilio.num_int == '') ? '0' : this.domicilio.num_int
                this.cambiarVisForm(false)
            } else {
                this.campos += (this.domicilio.pais == '') ? 'Pais, ' : ''
                this.campos += (this.domicilio.estado == '') ? 'Estado, ' : ''
                this.campos += (this.domicilio.ciudad == '') ? 'Ciudad, ' : ''
                this.campos += (this.domicilio.colonia == '') ? 'Colonia, ' : ''
                this.campos += (this.domicilio.calle == '') ? 'Calle, ' : ''
                this.campos += (this.domicilio.num_ext == '') ? 'Numero exterior, ' : ''
                this.campos += (this.domicilio.c_p == '') ? 'C.P' : ''
            }
        },
    }
}
</script>