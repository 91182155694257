<script>
import ProductorAgregarForm from "@/components/AdminComponents/ProductorComponents/ProductorAgregarForm.vue";
import ProductorEditForm from "@/components/AdminComponents/ProductorComponents/ProductorEditForm.vue";

import QRService from "@/services/QRService";
import { createProductor, updateProductor } from "@/services/ProductoresService";
import UploadImgService from "@/services/UploadImgService";

    export default{
        name: 'ProductoresDetail',
        components: {
            ProductorAgregarForm,
            ProductorEditForm
        },
        props: {
            productor: Object
        },
        data(){
            return{
                qrs: []
            }
        },
        methods: {
            async getQRs(){
                const response = await QRService.getQRs()
                if (response.success){
                    this.qrs = response.qrs
                }
            },
            async createProductor(formData){
                const response = await createProductor(this.$cookies.get('token'), formData);
                if(response.success){
                    alert('Se añadio al productor')
                    this.$router.push({ name: 'admin_productores' })
                }else{
                    alert('Algo ha salido mal :(, por favor intente de nuevo mas tarde')
                }
            },
            async updateImgProductor(formData){
                const response = await UploadImgService.UpdateSingleImgProductor(this.$cookies.get('token'), formData)
                if(response.success){
                    alert('Se actualizo la imagen del productor')
                    this.$router.push({ name: 'admin_productores' })
                }else{
                    alert('Algo ha salido mal :(, por favor intente de nuevo mas tarde')
                }
            },
            async updateProductor(id_productor, new_data_productor){
                const response = await updateProductor(this.$cookies.get('token'), id_productor, new_data_productor);

                if(response.success){
                    alert('Se actualizaron los datos del productor')
                    this.$router.push({ name: 'admin_productores' })
                }else{
                    alert('Algo ha salido mal :(, por favor intente de nuevo mas tarde')
                }
            },
        },
        computed: {
            msg_btn: function(){
                return (this.productor.id===0) ? 'Agregar' : 'Actualizar'
            },
            activateEdit: function(){
                return this.$route.params.hasOwnProperty('id')
            },
        }
    }
</script>

<template>
    <div :class="['view_detail']">
        <header :class="['header-form']">
            <router-link :to="{ name: 'admin_productores'}"><i class="fa-solid fa-arrow-left"></i> Volver</router-link>
        </header>
        <ProductorEditForm
            v-if="activateEdit"
            @getQRs="getQRs"
            @updateImgProductor="updateImgProductor"
            @updateProductor="updateProductor"
            :productor="productor"
            :qrs="qrs"
        />
        <ProductorAgregarForm 
            v-else-if="!activateEdit"
            @getQRs="getQRs"
            @createProductor="createProductor"
            :productor="productor"
            :qrs="qrs"
        />
    </div>
</template>

<style scoped>
    .view_detail {
        display: flex;
        flex-flow: column wrap;
        padding: 0rem 0.5rem;
        justify-content: flex-start;
    }

    .view_detail .header-form{
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        padding: 0.3rem 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 7px 5px -3px;
        margin-bottom: 1rem;
    }

    .header-form a{
        color: black;
        font-family: 'Quicksand';
        font-weight: bold;
        font-size: 1.2rem;
    }
</style>