<template>
    <article class="container-venta">
        <section :class="['header-venta']">
            <h2> {{ nombreUser }} </h2>
            <span>ID: {{ id }} </span>
        </section>
        <section :class="['body-venta']">
            <div :class="['fecha-container']">
                <p :class="['fecha']">Fecha solicitud: <strong> {{ venta.fecha_solicitud }} </strong> </p>
                <p :class="['fecha']">Fecha entrega: <strong> {{ venta.fecha_entrega }} </strong> </p>
            </div>
            <p :class="['domicilio']">
                {{ domicilioToString }}
            </p>
            <footer>
                <p :class="['total-venta']">Total venta: <strong> {{ changeFormat(venta.total) }} </strong></p>
                <router-link :to="{name: 'ventaDetail', params: {id: id, venta: venta}}" :class="['btn-detail']"><span>DETALLES <i class="fa-solid fa-circle-arrow-right fa-lg"></i></span></router-link>
            </footer>
        </section>
    </article>
</template>

<script>
    export default{
        name: 'VentaPost',
        props: {
            id: Number,
            venta: Object
        },
        data(){
            return{
                
            }
        },
        methods: {
            changeFormat(number){
                const formatter = new Intl.NumberFormat('es-MX',{
                    style: 'currency',
                    currency: 'MXN'
                })

                return formatter.format(number)
            }
        },
        computed: {
            domicilioToString: function (){
                const objDomicilio = this.venta.domicilio
                let domicilio = `Ciudad: ${objDomicilio.ciudad}, Colonia: ${objDomicilio.colonia}, Calle: ${objDomicilio.calle}, #Ext: ${objDomicilio.num_ext}, #Int: ${objDomicilio.num_int}, CP: ${objDomicilio.c_p}`;

                return domicilio
            },
            nombreUser: function (){
                return `${this.venta.usuario.nombre} ${this.venta.usuario.apellido_p} ${this.venta.usuario.apellido_m}`
            }
        }
    }
</script>

<style scoped>

p {
    margin: 0;
}

.container-venta{
    align-self: start;

    background-color: #0DABD3;
    font-family: 'Roboto';
    border-radius: 0.5rem;
    border: solid 1px #e1e3e1;
    overflow: hidden;

    transition-property: box-shadow;
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
}

.container-venta:hover{
    box-shadow: 5px 5px 3px #e1e3e1;
}
        .header-venta{
            color: white;
            font-weight: 600;
            padding: 0 0.5rem;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
        }

            .header-venta h2{
                padding: 0;
                margin: 0;
                font-size: 0.95rem;
            }

            .header-venta span{
                font-size: 0.95rem;
                text-transform: capitalize;
            }
        .body-venta{
            background-color: white;

            display: flex;
            flex-flow: row wrap;
            padding: 0 0.5rem;
        }
        
            .body-venta .fecha-container{
                margin-bottom: 0.3rem;

                flex-grow: 1;

                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                gap: 0 0.5rem;

            }

                .fecha-container .fecha{
                    align-items: center;
                    font-size: 0.9rem;
                }

            .body-venta .domicilio{
                text-align: left;
                font-family: 'QuickSand';
                font-weight: 500;
                font-size: 0.9rem;
            }
            
            .body-venta footer{
                margin-bottom: 0.3rem;

                flex-grow: 1;

                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: center;
                gap: 0 0.5rem;
            }

                .body-venta footer .total-venta{
                    margin: 0;
                    font-size: 0.9rem;
                }

                .body-venta footer .btn-detail{
                    background-color: #4dd3f4;
                    border-radius: 1rem;
                    border: solid 2px #0DABD3;
                    color: #000;
                    
                    font-weight: 600;
                    font-size: 0.8rem;
                    
                    padding: 0.3rem 0.5rem;

                    transition: .5s ease color;
                    position: relative;
                    overflow: hidden;
                }

                .btn-detail:hover {
                    text-decoration: none;
                }

                    .btn-detail span {
                        position: relative;
                        z-index: 2;
                        transition: .5s ease-in color;
                    }

                    .btn-detail:hover span{
                        color: aliceblue;
                    }

                .btn-detail::after{
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: #0DABD3;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    right: -200px;
                    transition: .5s ease-in-out all;
                }
                .btn-detail:hover::after{
                    right: 0;
                }
</style>