import axios from "axios";
import store from '@/store'
const apiUrl = store.state.apiRender;
const apiUrlImgProd = apiUrl+'imagenprod/';
const apiUrlProdHasImg = apiUrl+'Productos_has_imagenes_producto/';

//llamadas a imagenprod
async function getImagenes(){
    try {
        const response = await axios.get(apiUrlImgProd);
        
        return { imagenes: response.data, success: true}
    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

async function getImageById(id){
    try {
        const response = await axios.get(apiUrlImgProd+id);

        return { image: response.data, success: true}
    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

async function createImagenProd(token, formData){
    try{
        const response = await axios.post(apiUrlImgProd, formData, {
            headers: {
                Authorization: token
            }
        });

        return { created: response.data, success: true }
    }catch(e){
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

async function updateImagenProd(token, id, id_productor, name_img, old_name_img){
    try{
        const response = await axios.patch(apiUrlImgProd+id, {
            id_productor: id_productor,
            name_img: name_img,
            old_name_img: old_name_img
        },
        {
            headers:{
                Authorization: token
            }
        });

        return {
            updated: response.data,
            success: true
        }
    }catch(e){
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

async function deleteImagenProd(token, id){
    try{
        const response = await axios.delete(apiUrlImgProd+id, {
            headers: {
                Authorization: token
            }
        });
        return {deleted: response.data, success: true}
    }catch (e){
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}


//llamadas a productos_has_imagenes_producto
async function getImagenesByIdProducto(id_producto){
    try {
        const response = await axios.get(apiUrlProdHasImg+id_producto)
        return {imagenes: response.data, success: true}
    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

export default{
    getImagenes,getImageById,
    createImagenProd,
    updateImagenProd,
    deleteImagenProd,
    getImagenesByIdProducto
}