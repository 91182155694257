import Vue from 'vue'
import VueRouter from 'vue-router'

/* Importacion componentes Usuario */
import UserView from '@/views/UserView.vue'
import HomeView from '../views/UserViews/HomeView.vue'

/* Importacion componentes Administrador */
import AdminView from '../views/AdminView.vue'
import Administrar from '../views/AdminViews/AdministrarView.vue'

import ProductosView from '../views/AdminViews/ProductosView.vue'
import ProductosDetailView from '../views/AdminViews/ProductosDetailView.vue'
import ProductoresView from '../views/AdminViews/ProductoresView.vue'
import ProductoresDetailView from '../views/AdminViews/ProductoresDetailView.vue'
import TiendasView from '../views/AdminViews/TiendaView.vue'
import CapacidadesView from '../views/AdminViews/CapacidadesView.vue'
import EmpaquesView from '../views/AdminViews/EmpaquesView.vue'
import CategoriasView from '../views/AdminViews/CategoriasView.vue'
import QRView from '../views/AdminViews/QRView.vue'
import ImagenesView from '../views/AdminViews/ImagenesView.vue'


import VentasView from '@/views/AdminViews/VentasView.vue'
import CuentaView from '@/views/AdminViews/CuentaView.vue'
import PedidosView from '@/views/AdminViews/PedidosView.vue'
import VentaDetailView from '@/views/AdminViews/VentaDetailView.vue'
import PedidoDetailView from '@/views/AdminViews/PedidoDetailView.vue'

/* Importacion componentes pago */
import PayView from '@/views/PayView.vue'
import CancelPayView from '@/views/CancelPayView.vue'
import SuccessPayView from '@/views/SuccessPayView.vue'

/* Importacion componentes generales */
import LoginView from '@/views/LoginView.vue'
import crear_cuenta from '@/views/CrearCuentaView.vue'
import recuperar_contraseña from '@/views/RecuperarContraseña.vue'
import nueva_contraseña from '@/views/NuevaContraseña.vue'
import Categorias from '@/services/CategoriasService';
import { getProductos } from '@/services/ProductosService.js';
import Orden from '@/services/OrdenService.js'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: UserView,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
        meta: {
          requireAdmin: false
        }
      },
      {
        path: 'productos/:categoria/:id_producto?',
        name: 'productos',
        component: () => import(/* webpackChunkName: "about" */ '../views/UserViews/ProductosView.vue'),
        beforeEnter: async (to, from, next) => {
          try {
            const aux_c = await Categorias.getCategorias(); // Agrega tus categorías válidas aquí
            const categoria = to.params.categoria;
            const categoriasValidas = []
            const aux_p = await getProductos();
            const producto = to.params.id_producto;
            const productosValidos = []
            aux_c.forEach(element => {
              categoriasValidas.push(element.nombre)
            });
            aux_p.forEach(element => {
              productosValidos.push(element.id)
            });
            if (categoriasValidas.includes(categoria) || categoria === 'Todos') {
              // // La categoría es válida, continúa con la navegación
              if (producto) {
                if (productosValidos.includes(Number(producto))) {
                  next();
                } else {
                  next({ name: 'productos', params: { categoria: 'Todos' } }); // Ajusta esto según tus necesidades
                }
              } else {
                next(); // Ajusta esto según tus necesidades
              }
            } else {
              // La categoría no es válida, redirige a una página de error o a la página principal
              next({ name: 'productos', params: { categoria: 'Todos' } }); // Ajusta esto según tus necesidades
            }
          } catch (e) {
            console.log("corroboro url y el error es: " + e)
          }
        },
      },
      {
        path: 'conocenos',
        name: 'conocenos',
        component: () => import(/* webpackChunkName: "about" */ '../views/UserViews/ConocenosView.vue')
      },
      {
        path: 'tiendas_fisicas',
        name: 'tiendas_fisicas',
        component: () => import(/* webpackChunkName: "about" */ '../views/UserViews/TiendasFisicasView.vue')
      },
      {
        path: 'productores',
        name: 'productores',
        component: () => import(/* webpackChunkName: "about" */ '../views/UserViews/ProductoresView.vue')
      },
      {
        path: 'compras/:compra?/:id_orden?',
        name: 'compras',
        component: () => import(/* webpackChunkName: "about" */ '../views/UserViews/ComprasView.vue'),
        beforeEnter: async (to, from, next) => {
          try {
            const id_user = $cookies.get('id_user')
            const aux = await Orden.getOrden(id_user)
            const responseOrden = []
            const token = $cookies.get('token')
            const acceso_orden=to.params.id_orden
            aux.forEach(element => {
              responseOrden.push(element.id_orden)
            });
            if (to.params.compra == 'compra' && token && id_user) {
              if (to.params.id_orden) {
                if (responseOrden.includes(Number(acceso_orden))) {
                  next();
                } else {
                  next({ name: 'compras' });
                }
              }else{
                next({ name: 'home' }); 
              }
            } if (token && id_user && to.path.startsWith('/compras')) {              
              next();
            } else {
              // La categoría no es válida, redirige a una página de error o a la página principal
              next({ name: 'home' }); 
            }
          } catch (e) {
            next({ name: 'home' }); 
          }
        },
      },
    ]
  },
  {
    path: '/administrar',
    component: AdminView,
    children: [
      {
        path: '',
        redirect: { name: 'pedidos' },
        meta: {
          requireAdmin: true
        }
      },
      {
        path: 'productos',
        name: 'admin_productos',
        component: ProductosView,
        meta: {
          requireAdmin: true
        },
      },
      {
        path: 'productos/agregar',
        name: 'agregar_producto',
        component: ProductosDetailView,
        meta: {
          requireAdmin: true
        },
        props: true
      },
      {
        path: 'productos/:id',
        name: 'editar_producto',
        component: ProductosDetailView,
        meta: {
          requireAdmin: true
        },
        props: true
      },
      {
        path: 'productores',
        name: 'admin_productores',
        component: ProductoresView,
        meta: {
          requireAdmin: true
        },
      },
      {
        path: 'productores/agregar',
        name: 'agregar_productor',
        component: ProductoresDetailView,
        meta: {
          requireAdmin: true
        },
        props: true
      },
      {
        path: 'productores/:id',
        name: 'editar_productor',
        component: ProductoresDetailView,
        meta: {
          requireAdmin: true
        },
        props: true
      },
      {
        path: 'qr',
        name: 'admin_qr',
        component: QRView,
        meta: {
          requireAdmin: true
        },
      },
      {
        path: 'imagenes',
        name: 'admin_imagenes',
        component: ImagenesView,
        meta: {
          requireAdmin: true
        },
      },
      {
        path: 'categorias',
        name: 'admin_categorias',
        component: CategoriasView,
        meta: {
          requireAdmin: true
        },
      },
      {
        path: 'capacidades',
        name: 'admin_capacidades',
        component: CapacidadesView,
        meta: {
          requireAdmin: true
        },
      },
      {
        path: 'empaques',
        name: 'admin_empaque',
        component: EmpaquesView,
        meta: {
          requireAdmin: true
        },
      },
      {
        path: 'tiendas',
        name: 'admin_tiendas',
        component: TiendasView,
        meta: {
          requireAdmin: true
        },
      },
      {
        path: 'ventas',
        name: 'ventas',
        component: VentasView,
        meta: {
          requireAdmin: true
        },
      },
      {
        path: 'ventas/:id',
        name: 'ventaDetail',
        component: VentaDetailView,
        meta: {
          requireAdmin: true
        },
        props: true
      },
      {
        path: 'pedidos',
        name: 'pedidos',
        component: PedidosView,
        meta: {
          requireAdmin: true
        },
      },
      {
        path: 'pedidos/:id',
        name: 'pedidoDetail',
        component: PedidoDetailView,
        meta: {
          requireAdmin: true
        },
        props: true
      },
      {
        path: 'cuenta',
        name: 'cuenta',
        component: CuentaView,
        meta: {
          requireAdmin: true
        },
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requireAdmin: false
    }
  },
  {
    path: '/CrearCuenta',
    name: 'crear_cuenta',
    component: crear_cuenta,
    meta: {
      requireAdmin: false
    }
  },
  {
    path: '/recuperar_contrasena',
    name: 'recuperar_contraseña',
    component: recuperar_contraseña,
    meta: {
      requireAdmin: false
    }
  },
  {
    path: '/nueva_contrasena/:token',
    name: 'nueva_contraseña',
    component: nueva_contraseña,
    meta: {
      requireAdmin: false
    }
  },
  {
    path: '/pay',
    name: 'desfile_pago',
    component: PayView,
    meta: {
      requireAdmin: false
    }
  },
  {
    path: '/pay/success',
    name: 'success_pay',
    component: SuccessPayView,
    meta: {
      requireAdmin: false
    }
  },
  {
    path: '/pay/cancel',
    name: 'cancel_pay',
    component: CancelPayView,
    meta: {
      requireAdmin: false
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  //verifica si es administrador o no 
  const isUserAdmin = ($cookies.get('role')==='admin') ? true : false
  //verifica si ruta necesita administrador
  const needAdmin = to.meta.requireAdmin

  
  if(needAdmin && !isUserAdmin){ //ruta necesita admin y usuario no es admin
    next({ name: 'home' }) //ruta se desvia a home
  }else { 
    next() //ruta sigue flujo normal
  }
})

export default router
