    function getOrdenarPalabras(palabras){
        palabras.sort((a, b) => {
            const nombreA = a.nombre.toLowerCase(); // Convertir a minúsculas para ordenar sin distinción entre mayúsculas y minúsculas
            const nombreB = b.nombre.toLowerCase();

            if (nombreA < nombreB) {
              return -1;
            }
            if (nombreA > nombreB) {
              return 1;
            }
            return 0; // Los nombres son iguales
          });
        return palabras
    }
    function getOrdenarPalabrasCategoria(palabras){
      palabras.sort((a, b) => {
          const nombreA = a.nombre_cat.toLowerCase(); // Convertir a minúsculas para ordenar sin distinción entre mayúsculas y minúsculas
          const nombreB = b.nombre_cat.toLowerCase();

          if (nombreA < nombreB) {
            return -1;
          }
          if (nombreA > nombreB) {
            return 1;
          }
          return 0; // Los nombres son iguales
        });
      return palabras
  }
    function primeraLetraMayuscula(cadena) {
      // Verificar si la cadena es válida y no está vacía
      if(cadena){
        if (cadena && typeof cadena === 'string' && cadena.length > 0) {
          // Convertir la primera letra a mayúscula y concatenar el resto de la cadena
          return cadena.charAt(0).toUpperCase() + cadena.slice(1);
        } else {
          // Devolver la cadena original si no es válida
          return cadena;
        }
      }
      return cadena;
    }
    function convertirAMinusculas(cadena) {
      // Verificar si la cadena es válida y no está vacía
      if (cadena && typeof cadena === 'string') {
        // Convertir toda la cadena a minúsculas
        return cadena.toLowerCase();
      } else {
        // Devolver la cadena original si no es válida
        return cadena;
      }
    }

export  {
    getOrdenarPalabras,convertirAMinusculas,primeraLetraMayuscula,
    getOrdenarPalabrasCategoria
};