<script>
import CapacidadPost from "./CapacidadPost.vue";
import CircleLoader from "@/components/CircleLoader.vue";
    export default{
        name: 'CapacidadList',
        components:{
            CapacidadPost,
            CircleLoader,
        },
        mounted(){
            this.callCapacidades()
        },
        props:{
            capacidades: Array,
            capacidad: Object,
            stateCharge: Boolean
        },
        data(props){
            return{
                
            }
        },
        emits: ['callCapacidades','setLocalCapacidad','resetLocalCapacidad'],
        methods: {
            async callCapacidades (){
                this.$emit("callCapacidades")
            },
            setLocalCapacidad(capacidad){
                this.$emit("setLocalCapacidad",capacidad)
            },
            resetLocalCapacidad(){
                this.$emit("resetLocalCapacidad")
            }
        }
    }
</script>

<template>
    <section :class="['container-post-capacidad']">
        <CircleLoader 
            :activo="stateCharge"
        />

        <CapacidadPost 
            v-for="cap in capacidades" :key="cap.id"
            :capacidad="cap"
            :selected="(cap.id == capacidad.id)? true : false"
            @setLocalCapacidad="setLocalCapacidad"
            @resetLocalCapacidad="resetLocalCapacidad"
        />
    </section>
</template>

<style scoped>
    .container-post-capacidad{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        gap: 0.5rem;

        padding: 1rem;
    }
</style>