import axios from "axios";
import store from '@/store';

const apiUrl = store.state.apiRender;
const apiUrlOrden = apiUrl+'orden/'
const apiUrlOrdenDetalle = apiUrl+'orden_detalle'
const apiUrlOrdenDetalleVenta = apiUrl+'orden_detalle_venta/'
const apiUrlVenta = apiUrl+'ventas/'
const apiUrlPedido = apiUrl+'pedidos/'

/* Empiezan los metodos de orden */

    async function createOrden(token, orden){
        try {
            const response = await axios.post(apiUrlOrden, {
                total: orden.total,
                fecha_solicitud: orden.fecha_solicitud,
                id_usuario: orden.id_usuario,
                id_metodo: orden.id_metodo,
                id_domicilio: orden.id_domicilio,
                id_estado: orden.id_estado
            },
            {
                headers: {
                    Authorization: token
                }
            })
            console.log(response.data.insertId)
            $cookies.set('id_orden',response.data.insertId)

            return {orden: response, success: true}
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : e, success: false }
        }
    }

    async function updateOrden(token, idOrden, ordenUpdated){
        try {
            const response = await axios.patch(apiUrlOrden+idOrden, ordenUpdated, 
                {
                    headers: {
                        Authorization: token
                    }
                }
            );
            return {updated: response, success: true}
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : e, success: false }
        }
    }

    async function createOrdenFull(token, orden){
        try {
            const response = await axios.post(apiUrlOrden, {
                total: orden.total,
                fecha_solicitud: orden.fecha_solicitud,
                id_usuario: orden.id_usuario,
                id_carrito: orden.id_carrito,
                id_metodo: orden.id_metodo,
                id_domicilio: orden.id_domicilio,
                id_estado: orden.id_estado,
                productos: orden.productos
            },
            {
                headers: {
                    Authorization: token
                }
            })
            return {orden: response, success: true}
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : e, success: false }
        }
    }


/* Empiezan los metodos de orden detalle */

async function createOrdenDetalle(token, id_orden, orden_detalle){
    try {
        const response = axios.post(apiUrlOrdenDetalle, {
            id_orden : id_orden,
            id_producto: orden_detalle.id_producto,
            cantidad : orden_detalle.cantidad,
            precio: orden_detalle.precio
        },
        {
            headers: {
                Authorization: token
            }
        })

        return {orden_detalle: response, success: true}
    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : e, success: false }
    }
}
async function getOrden(id_usuario) {
    try {
        // Realizar la solicitud GET a la API
        const respons = await axios.get(apiUrlOrden+'compras/'+id_usuario)
        const data = respons.data

        return data
    } catch (error) {
        if (error.response && error.response.status === 404) {
            // alert('Aun no realizas una compra')
            store.commit('setactivar_alerta', true);
            store.commit('setmensaje_alerta', 'Aun no realizas una compra');

        } else {
            console.log('Error al obtener los datos de las compras ' + error)
        }
    }
}

async function getOrden_detalle(idOrden) {
    try {
        // Realizar la solicitud GET a la API
        const respons = await axios.get(apiUrlOrdenDetalle+'_compra/'+idOrden)
        const data = respons.data
        return data
    } catch (error) {
        console.log('Error al obtener los datos de Productos ' + error)
    }
}

async function getJoinOrdenDetalleByID(token, idOrden){
    try {
        const response = await axios.get(apiUrlOrdenDetalleVenta+idOrden, 
            {
                headers: {
                    Authorization: token
                }
            }
        );

        return { orden: response.data, success: true }
    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : e, success: false }
    }
}

async function getVentas(token) {
    try {
        const response = await axios.get(apiUrlVenta,
            {
                headers: {
                    Authorization: token
                }
            }
        );
        return { ventas: response.data, success: true }
    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : e, success: false }
    }
}

async function getPedidos(token){
    try {
        const response = await axios.get(apiUrlPedido,
            {
                headers: {
                    Authorization: token
                }
            }
        );
        return { pedidos: response, success: true }
    } catch (e) {
        return { message: e, success: false }
    }
}

export default {
    getOrden,
    createOrden,
    createOrdenFull,
    updateOrden,
    /* servicio de orden detalle */
    createOrdenDetalle,
    getOrden_detalle,
    getJoinOrdenDetalleByID,
    /* servicio de ventas */
    getVentas,
    /* servicio de pedidos */
    getPedidos
}
