import axios from "axios";
import store from '@/store'

const apiUrl = store.state.apiRender;//'http://localhost:3000/';
const apiUrlEmpaque = apiUrl+"empaque/";

    async function getEmpaques() {
        try {
            const response = await axios.get(apiUrlEmpaque)

            return {empaques: response.data, success: true}
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

    async function getEmpaque(id){
        try {
            const response = await axios.get(apiUrlEmpaque+id)
            return {empaque: response.data, success: true}
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

    async function createEmpaque(token, empaque){
        try {
            const response = await axios.post(apiUrlEmpaque,
                {
                    tipo: empaque.tipo
                },
                {
                    headers:{
                        Authorization: token
                    }
                }
            )

            return {creation:response.data, success:true}
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

    async function updateEmpaque(token, id, empaque){
        try {
            const response = await axios.patch(apiUrlEmpaque+id,
                {
                    tipo: empaque.tipo
                },
                {
                    headers:{
                        Authorization: token
                    }
                }    
            )
            return{update: response.data, success: true}
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

    async function deleteEmpaque(token, id){
        try {
            const response = await axios.delete(apiUrlEmpaque+id,
                {
                    headers:{
                        Authorization: token
                    }
                }    
            )
            return{delete:response.data, success:true}
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

export default {
    getEmpaques,
    getEmpaque,
    createEmpaque,
    updateEmpaque,
    deleteEmpaque
}