<script>
import ImagenesForm from "@/components/AdminComponents/ImagenesComponents/ImagenesForm.vue";
import ImagenesList from "@/components/AdminComponents/ImagenesComponents/ImagenesList.vue";

import ImgProductosService from "@/services/ImgProductosService";
import { getProductores } from "@/services/ProductoresService";
import UploadImgService from "@/services/UploadImgService";
    export default{
        name: 'ImagenesView',
        components: {
            ImagenesForm,
            ImagenesList,
        },
        data(){
            return{
                imagenesProd: [],
                imagenProd:{
                    id: 0,
                    uri_img: ''
                },
                productores:[],
                stateCharge:false
            }
        },
        methods: {
            async getAllProductores(){
                const response = await getProductores()
                this.productores = response
            },
            async getAllImagenesProd(){
                this.stateCharge = true
                const response = await ImgProductosService.getImagenes()
                if (response.success){
                    this.imagenesProd = response.imagenes
                }
                this.stateCharge = false
            },
            async createImagenProd(formData){
                const response = await ImgProductosService.createImagenProd(this.$cookies.get('token'), formData);
                if(response.success){
                    this.getAllImagenesProd()
                    alert('La imagen se añadio correctamente :) ')
                }else {
                    alert('Algo ha salido mal, por favor intente denuevo mas tarde :( ')
                }
            },
            async updateNameImagenProd(id, id_productor, name_img, old_name_img){
                const response = await ImgProductosService.updateImagenProd(this.$cookies.get('token'), id, id_productor, name_img, old_name_img);
                if(response.success){
                    this.resetLocalImagenProd();
                    this.getAllImagenesProd();
                    alert(`El nombre ha cambiado de ${old_name_img} a ${name_img}`);
                }
            },
            async updateImagenProd(formData){
                const response = await UploadImgService.UpdateSingleImgProd(this.$cookies.get('token'),formData);
                if(response.success){
                    this.resetLocalImagenProd();
                    this.getAllImagenesProd();
                    alert('La imagen se ha actualizado');
                }
            },
            async deleteImagenProd(id){
                const response = await ImgProductosService.deleteImagenProd(this.$cookies.get('token'), id)
                if(response.success){
                    this.resetLocalImagenProd();
                    this.getAllImagenesProd();
                    alert('se ha eliminado la imagen con id '+id);
                }
            },
            setLocalImagenProd(imagenProd){
                this.imagenProd = imagenProd
            },
            resetLocalImagenProd(){
                this.imagenProd = {
                    id: 0,
                    uri_img: ''
                }
            },
        }
    }
</script>

<template>
    <div>
        <h1 :class="['titulo_vista','tbg']">Lista de Imagenes</h1>
        <div :class="['short-view']">
            <ImagenesForm 
                :class="['short-view-child','view-form']"
                @getAllProductores="getAllProductores"
                @createImagenProd="createImagenProd"
                @updateNameImagenProd="updateNameImagenProd"
                @updateImagenProd="updateImagenProd"
                @deleteImagenProd="deleteImagenProd"
                :imagenProd="imagenProd"
                :productores="productores"
            />

            <ImagenesList 
                :class="['short-view-child']"
                @getAllImagenesProd="getAllImagenesProd"
                @setLocalImagenProd="setLocalImagenProd"
                @resetLocalImagenProd="resetLocalImagenProd"
                :imagenesProd="imagenesProd"
                :imagenProd="imagenProd"
                :stateCharge="stateCharge"
            />
        </div>
    </div>
</template>

<style scoped>
.short-view{
    display: flex;
    flex-flow: row-reverse wrap;
    align-items: flex-start;
    gap: 0.5rem;
}

.short-view-child{
    flex: 3;
}

.view-form{
    padding: 0 0.5rem;
    flex: 1;
}

@media (max-width: 800px){
    .short-view{
        flex-flow: column wrap;
        align-items: stretch;
        flex: 0;
    }
}
</style>