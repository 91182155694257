<script>
    export default{
        name: 'PostVentaDetailProd',
        props: {
            itemOrden: Object,
            index: {
                type: Number,
                default: 0
            },
        },
        data(){
            return{
                
            }
        },
        methods: {
            changeFormat(number){
                const formatter = new Intl.NumberFormat('es-MX',{
                    style: 'currency',
                    currency: 'MXN'
                })

                return formatter.format(number)
            }
        },
        computed:{
            getTotalItem: function(){
                return this.itemOrden.cantidad*this.itemOrden.precio
            },
            uriImg: function(){
                const baseUrlImagenes = 'https://api.puntotuxtleco.com.mx/img/productos/';
                    
                if( this.itemOrden.producto.imagenes === undefined ||
                    this.itemOrden.producto.imagenes.length <= 0 ||
                    this.itemOrden.producto.imagenes[0].uri_img === null
                ){
                    return require('../../../assets/img/img_default.jpg')
                }
                return `${baseUrlImagenes}${this.itemOrden.producto.imagenes[0].uri_img}`
            }
        }
    }
</script>

<template>
    <section :class="['post_detail_venta']">
        <p :class="['number__post']">{{ index }}</p>
        <img :class="['img__product']" :src="uriImg" alt="imagen producto">
        <div :class="['product__info']">
            <p :class="['nombre__product']"> {{ itemOrden.producto.nombre }} </p>
            <p :class="['details__product']"> Cantidad: {{ itemOrden.cantidad }} • Precio: {{ changeFormat(itemOrden.precio) }} • Total: {{ changeFormat(getTotalItem) }} </p>
        </div>
    </section>
</template>

<style scoped>
p {
    margin: 0;
    padding: 0;
}
.post_detail_venta{
    display:flex;
    flex-flow: row wrap;
    gap: 0.5rem;

    border: solid 1px #e1e3e1;
    border-radius: 0.5rem;
    margin-block: 0.5rem;
    padding-inline: 0.5rem;

    font-family: 'Roboto';
}

.post_detail_venta:hover{
    background-color: #f6f6f6;
}

.post_detail_venta .number__post{
    align-self: center;
    color: #929292;
    font-size: 0.8rem;
    font-weight: bold;
}

.post_detail_venta .img__product{
    width: 100px;
    height: 70px;
    margin-block: 0.3rem;
    border-radius: 0.3rem;
    object-fit: contain;
}

.product__info {
    display: flex;
    flex-flow: column wrap;
}

.product__info .nombre__product{
    text-align: left;
    font-weight: bold;
    color: #242424;
    /*color: #0dabd3;*/
}

.product__info .details__product{
    font-size: 0.8rem;
    text-align: left;
    color: #737373;
}

</style>