<script>
    export default{
        name: 'CategoriasPost',
        props:{
            categoria: Object,
            selected: Boolean
        },
        data(){
            return{
                
            }
        },
        emits:['setLocalCategoria','resetLocalCategoria'],
        methods: {
            sendCategoria(){
                if (!this.selected) {
                    this.$emit('setLocalCategoria', JSON.parse(JSON.stringify(this.categoria)))
                }else{
                    this.$emit('resetLocalCategoria')
                }
            }
        }
    }
</script>

<template>
    <article
        :title="'Seleccione para poder editar la categoria ['+categoria.nombre+']'"
        :class="['post-short-view', {'active': selected}]"
        @click="sendCategoria"
    >
        <span> {{ categoria.nombre }} </span>
    </article>
</template>

<style scoped>
    
</style>