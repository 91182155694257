<script>
import ImagenesPost from '@/components/AdminComponents/ImagenesComponents/ImagenesPost.vue'
import CircleLoader from "@/components/CircleLoader.vue";
    export default{
        name: 'ImageList',
        components: {
            ImagenesPost,
            CircleLoader
        },
        mounted(){
            this.getAllImagenesProd()
        },
        props:{
            imagenesProd: Array,
            imagenProd: Object,
            stateCharge: Boolean,
        },
        data(props){
            return{
                
            }
        },
        emits: ['getAllImagenesProd','setLocalImagenProd','resetLocalImagenProd'],
        methods: {
            async getAllImagenesProd(){
                this.$emit('getAllImagenesProd')
            },
            setLocalImagenProd(imagenProd){
                this.$emit('setLocalImagenProd',imagenProd)
            },
            resetLocalImagenProd(){
                this.$emit('resetLocalImagenProd')
            }
        },
        computed: {

        }
    }
</script>

<template>
    <div :class="['container-post-imagen']">
        <CircleLoader 
            :activo="stateCharge"
        />

        <ImagenesPost 
            v-for="imgProd in imagenesProd" :key="imgProd.id"
            :imagenProd="imgProd"
            :selected="(imgProd.id===imagenProd.id)"
            @setLocalImagenProd="setLocalImagenProd"
            @resetLocalImagenProd="resetLocalImagenProd"
        />
    </div>
</template>

<style scoped>
    .container-post-imagen{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        gap: 0.5rem;

        padding: 0.3rem 1rem;
        align-items: flex-start;
    }
</style>