<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import authService from '@/services/AuthService.js'

export default {
  name: 'Aplication',
  data() {
    return {
      time: 15, // tiempo inicial en segundos
      timer: null // variable para almacenar el identificador del temporizador
    }
  },
  methods: {
    startTimer() {
      if (this.$cookies.get('token') && this.$cookies.get('id_user')) {
        this.timer = setInterval(() => {
          if (this.time > 0) {
            this.time--;
          } else if (this.$cookies.get('token') && this.$cookies.get('id_user')){
            authService.renewToken();
            this.resetTimer();
          }
        }, 1000); // actualiza cada segundo
      }
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    resetTimer() {
      this.stopTimer(); // Detiene el temporizador actual
      this.time = 15; // Restablece el tiempo a 15 segundos
      this.startTimer(); // Inicia el nuevo temporizador
    }
  },
  mounted() {
    this.startTimer();
  }

}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

/*nav a.router-link-exact-active {
  color: #42b983;
}*/
</style>
