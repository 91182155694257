<script>
    export default{
        name: 'InfoVentaPostDetail',
        props: {
            title: {
                type: String,
                default: 'Your Title'
            },
            rows: {
                type: Array,
                /* ,required: true */
                default: [
                    {
                        title_row: 'Title row',
                        content_row: 'Your content row here'
                    }
                ]
            }
        },
        data(){
            return{
                
            }
        },
        methods: {
            
        }
    }
</script>

<template>
    <section :class="['info__post']">
        <h3 :class="['titulo_vista','text-left']"> {{ title }} </h3>
        
        <div 
            :class="['row__item__info']"
            v-for="(item,index) in rows" :key="index"
        >
            <p :class="['title__row']"> {{ item.title_row }} </p> 
            <p :class="['content__row']"> {{ item.content_row }} </p>
        </div>
    </section>
</template>

<style scoped>
.info__post{
    border: solid 1px #e1e3e1;
    border-radius: 0.5rem;
    margin-block: 0.5rem;
    overflow: hidden;
}
    .titulo_vista{
        font-weight: normal;
        font-size: 1.2rem;
        padding: 0;
        margin-block: 1rem;
        padding-inline: 1rem;
    }

    .row__item__info{
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 0.3rem;
        font-family: 'Roboto';
        text-align: left;
        padding: 0.5rem 1rem;
        border-bottom: solid 1px #e1e3e1;
    }

    .row__item__info:last-child {
        border-bottom: solid 1px transparent;
    }

    .row__item__info:hover{
        background-color: #f6f6f6;
    }

    .title__row{
        margin: 0;
        padding: 0;
        font-size: 0.8rem;
        font-weight: bold;
        color: #4a4c4b;
        flex-basis: 125px;
    }

    .content__row{
        margin: 0;
        padding: 0;

        color: #1f1f1f;

    }


</style>