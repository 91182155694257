<script>
    export default{
        name: 'CircleLoader',
        props: {
            activo: Boolean
        },
        data(){
            return{
                
            }
        },
        methods: {
            
        }
    }
</script>

<template>
    <div v-if="activo" :class="['container-spinner']">
        <div :class="['spinner']"></div>
    </div>
    
</template>

<style scoped>
.container-spinner{
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
}
.spinner{
    border: 0.4rem solid #c1c7e6;
    border-top-color: #0dabd3;
    border-top-style: groove;
    height: 3rem;
    width: 3rem;
    border-radius: 50%;

    position: absolute;
    animation: loader 0.7s infinite ease;
}

@keyframes loader{
    to{
        transform: rotate(360deg);
    }
}
</style>