import axios from "axios";
import store from '@/store'
let apiUrl = store.state.apiRender;//'http://localhost:3000/';
let apiUrlCapacidad = apiUrl+"capacidad/";

    async function getCapacidades(){
        try {
            const response = await axios.get(apiUrlCapacidad)

            return { capacidades: response.data, success: true }
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

    async function getCapacidad(id){
        try {
            const response = await axios.get(apiUrlCapacidad+id)

            return { capacidad: response.data, success: true }
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

    async function createCapacidad(token, capacidad){
        try {
            const response = await axios.post(apiUrlCapacidad,
                {
                    cantidad: capacidad.cantidad,
                    unidad_medida: capacidad.unidad_medida
                },
                {
                    headers: {
                        Authorization: token
                    }
                }
            )

            return { creation:response.data, success:true }
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

    async function updateCapacidad(token, id, capacidad){
        try {
            const response = await axios.patch(apiUrlCapacidad+id,
                {
                   cantidad: capacidad.cantidad,
                   unidad_medida: capacidad.unidad_medida 
                },
                {
                    headers:{
                        Authorization: token
                    }
                }
            )

            return { update: response.data, success: true }
    
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

    async function deleteCapacidad(token, id){
        try {
            const response = await axios.delete(apiUrlCapacidad+id,
                {
                    headers:{
                        Authorization: token
                    }
                }    
            )
            return{ delete:response.data, success:true }
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

export default {
    getCapacidades,getCapacidad,
    createCapacidad,
    updateCapacidad,
    deleteCapacidad
}



