<script>
    export default{
        name:'EmpaqueForm',
        components: {

        },
        props:{
            empaque: Object
        },
        data(props){
            return{
                
            }
        },
        emits:['createEmpaque', 'editEmpaque', 'deleteEmpaque'],
        methods:{
            async createEmpaque(){
                this.$emit("createEmpaque")
            },
            async editEmpaque(){
                this.$emit("editEmpaque")
            },
            async deleteEmpaque(){
                if(confirm(`Esta seguro que desea eliminar este empaque?`)){
                    this.$emit('deleteEmpaque')
                }
            }
        },
        computed:{
            noHasData: function(){
                return this.empaque.id==0
            },
            isReadyToAdd: function(){
                return this.empaque.tipo !== ''
            }
        }
    }
</script>

<template>
    <form>
        <h2 :class="['titulo_vista']">Agrega un nuevo empaque</h2>
        
        <div :class="['field_textarea','field-cyan']">
            <input 
                :class="['input_field','round']" 
                type="text"
                v-model="empaque.tipo" 
                id="nombre_empaque" 
                title="Ingrese un nombre de empaque" 
                placeholder="..."
            >
            <label :class="['msg','m-l']" for="nombre_empaque">Nombre del empaque</label>
        </div>

        <div :class="['button-container','end']">
            <button 
                :class="[{'normal-btn': isReadyToAdd, 'disabled-btn': !isReadyToAdd},'round']" 
                type="button" 
                title="Añade un nuevo empaque"
                :disabled="!isReadyToAdd"
                @click="createEmpaque"
            > 
                Añadir 
            </button>
            <button 
                :class="[{'outline-btn': !noHasData, 'disabled-btn': noHasData},'round']" 
                type="button"
                title="Edita el empaque seleccionado" 
                :disabled="noHasData" 
                @click="editEmpaque"
            >
                Editar
            </button>

            <button 
                :class="[{'delete-btn': !noHasData, 'disabled-btn': noHasData},'round']"
                type="button"
                title="Elimina el empaque seleccionado"
                :disabled="noHasData"
                @click="deleteEmpaque"
            >
                Eliminar
            </button>
        </div>
        
        <!-- <p>El nombre del empaque es: {{ empaque.id }} </p> -->
    </form>
</template>

<style scoped>
    .titulo_vista{
        text-align: left;
        padding-bottom: 0.5rem;
    }
</style>