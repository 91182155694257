<script>
    export default{
        name:'CapacidadForm',
        props:{
            capacidad: Object
        },
        data(props){
            return{
            }
        },
        emits:['createCapacidad','updateCapacidad','deleteCapacidad'],
        methods:{
            createCapacidad(){
                this.$emit('createCapacidad')
            },
            updateCapacidad(){
                this.$emit('updateCapacidad')
            },
            deleteCapacidad(){
                if(confirm(`Esta seguro que desea eliminar esta capacidad?`)){
                    this.$emit('deleteCapacidad')
                }
            },
            onlyNumbers(e){
                this.capacidad.cantidad = e.target.value
                if (this.capacidad.cantidad <= 0){
                    this.capacidad.cantidad = 1
                }
                this.capacidad.cantidad = Math.round(this.capacidad.cantidad);
            }
        },
        computed:{
            noHasData: function(){
                return this.capacidad.id==0
            },
            isReadyToAdd: function(){
                return (this.capacidad.cantidad !== '' && this.capacidad.unidad_medida !== '')
            }
        }
    }
</script>

<template>
    <form>
        <h2 :class="['titulo_vista']">Agregar una nueva capacidad</h2>
        <div :class="['container-field-input']">
            <div :class="['field_textarea','field-cyan']">
                <input 
                    v-model.number="capacidad.cantidad" 
                    :class="['input_field','round']"
                    type="number" 
                    id="cantidad_capacidad"
                    title="Por favor, ingrese la cantidad" 
                    placeholder="..."
                    @input="onlyNumbers"
                >
                <label :class="['msg','m-l']" for="cantidad_capacidad">Cantidad</label>
            </div>
    
            <div :class="['field_textarea','field-cyan']">
                <input  
                    :class="['input_field','round']"
                    type="text" 
                    v-model="capacidad.unidad_medida"
                    id="unidad_capacidad"
                    title="Por favor, ingrese unidad de medida (kg, g, l, ml)"
                    placeholder="..."
                >
                <label :class="['msg','m-l']" for="unidad_capacidad">Unidad de medida</label>
            </div>
        </div>
        <div :class="['button-container','end']">
            <button 
                :class="[{'normal-btn' : isReadyToAdd, 'disabled-btn': !isReadyToAdd},'round']" 
                type="button" 
                title="Añade un nuevo empaque"
                :disabled="!isReadyToAdd"
                @click="createCapacidad"
            > 
                Añadir 
            </button>
            <button 
                :class="[{'outline-btn': !noHasData, 'disabled-btn': noHasData},'round']" 
                type="button"
                title="Edita el empaque seleccionado"
                :disabled="noHasData"
                @click="updateCapacidad"
            >
                Editar
            </button>
            <button 
                :class="[{'delete-btn': !noHasData, 'disabled-btn': noHasData},'round']"
                type="button"
                title="Elimina el empaque seleccionado"
                :disabled="noHasData"
                @click="deleteCapacidad"
            >
                Eliminar
            </button>
        </div>
    </form>
</template>

<style scoped>

    .titulo_vista{
        text-align: left;
        padding-bottom: 0.5rem;
    }
</style>