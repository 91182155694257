import axios from "axios";
import store from '@/store';
const apiUrl =  store.state.apiRender;
const apiUrlQR = apiUrl+"imagenqr/";

async function getQRs(){
    try {
        const response = await axios.get(apiUrlQR)

        return { qrs: response.data, success: true }
    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

async function getQR(id){
    try{
        const response = await axios.get(apiUrlQR+id)

        return { qr: response.data, success: true }
    } catch(e){
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

async function createQR(token, formData){
    try{
        const response = await axios.post(apiUrlQR, formData,
            {
                headers:{
                    Authorization: token
                }
            }
        );
    
        return { creation: response.data, success: true }
    }catch(e){
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

/*Falta configurar editar y eliminar ya que se debe verificar la imagen*/
async function updateQR(token, idQR, name_qr, new_name_qr ){
    try {
        const response = await axios.patch(apiUrlQR+idQR,
            {
                name_qr: name_qr,
                new_name_qr: new_name_qr
            },
            {
                headers: {
                    Authorization: token
                }
            }
        );

        return { update: response.data, success: true }
    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

async function deleteQR(token, idQR){
    try {
        const response = await axios.delete(apiUrlQR+idQR,
            {
                headers:{
                    Authorization: token
                }
            }
        );

        return { deleted: response.data, success: true }
    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

export default {
    getQRs, getQR,
    createQR,
    updateQR,
    deleteQR
}

