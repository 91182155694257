<script>
import CircleLoader from "@/components/CircleLoader.vue";

import { getJoinProductos, deleteProducto } from '@/services/ProductosService'
    export default{
        name: 'ProductosTable',
        components: {
            CircleLoader,
        },
        mounted(){
            this.getProductos()
        },
        data(){
            return{
                productos: [],
                stateCharge: false
            }
        },
        methods: {
            async getProductos(){
                this.stateCharge = true
                const response = await getJoinProductos()
                response.forEach(
                    (producto) => {
                        if(producto.imagenes[0].id===null){
                            producto.imagenes = []
                        }
                    }
                )
                this.productos = response
                this.stateCharge = false
            },
            async deleteProducto(id_producto){
                if(confirm(`Esta seguro que desea eliminar al producto con id ${id_producto}?`)){
                    this.stateCharge = true
                    const response = await deleteProducto(this.$cookies.get('token'), id_producto)
                    if (response.success){
                        alert(`El producto con id: ${id_producto} se ha eliminado`);
                        this.getProductos();
                    }else {
                        alert('Algo ha salido mal :(, por favor intente de nuevo mas tarde...')
                    }
                    this.stateCharge = false
                }
            },
            uriImgQR(nameImgQR){
                const randomSuffix = Math.random().toString(36).substring(7)
                const uriImgQR = 'https://api.puntotuxtleco.com.mx/img/qr/'
                return `${uriImgQR}${nameImgQR}?${randomSuffix}`
            }
        }
    }
</script>

<template>
    <table :class="['component-table']">
        
        <thead>
            <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Precio</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Descripcion</th>
                <th scope="col">Categoria</th>
                <th scope="col">QR</th>
                <th scope="col">Opciones</th>
            </tr>
        </thead>

        <tbody>
            <tr v-for="producto in productos" :key="producto.id">
                <td><strong> {{ producto.nombre }} {{ producto.cantidad_medida }} {{ producto.unidad_medida }} </strong></td>
                <td>{{ producto.precio }}</td>
                <td> {{ producto.cantidad }} </td>
                <td :class="['text-left']"> <p :class="['descripcion-text']"> {{ producto.descripcion }} </p> </td>
                <td> {{ producto.nombre_cat }} </td>
                <td :class="['element-img']"> <img :src="uriImgQR(producto.name_qr)" alt="imagen_productor" loading="lazy"> </td>
                <td :class="['container-hiper']">
                    <router-link
                        :class="['hiper_vin-warning']"
                        :to="{ name: 'editar_producto', params: {id: producto.id, producto: producto} }"
                    > 
                        Editar
                    </router-link>
                    <a
                        :class="['hiper_vin-error']"
                        @click="deleteProducto(producto.id)"
                        href="#"
                    >
                        Eliminar
                    </a>
                </td>
            </tr>
            <tr v-show="stateCharge">
                <td colspan="7"> 
                    <CircleLoader 
                        :activo="stateCharge" 
                    /> 
                </td>
            </tr>
        </tbody>
    </table>
</template>

<style scoped>
.element-img img{
    border-radius: 0.3rem;
    border: solid 1px #ccc;
}

.descripcion-text{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-wrap: pretty;
}
</style>