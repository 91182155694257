<script>
    export default{
        name: 'CapacidadPost',
        props:{
            capacidad: Object,
            selected: Boolean
        },
        data(props){
            return{
                
            }
        },
        emits: ['setLocalCapacidad','resetLocalCapacidad'],
        methods: {
            sendCapacidad(){
                if(!this.selected){
                    this.$emit('setLocalCapacidad',JSON.parse(JSON.stringify(this.capacidad)))
                }else{
                    this.$emit('resetLocalCapacidad')
                }
            }
        }
    }
</script>

<template>
    <article
        :title="'seleccione para poder editar la capacidad ['+capacidad.cantidad+capacidad.unidad_medida+']'"
        :class="['post-short-view', {'active': selected}]"
        @click="sendCapacidad"
    >
        <span> {{ capacidad.cantidad }}{{ capacidad.unidad_medida }} </span>
    </article>
</template>

<style scoped>
    
</style>