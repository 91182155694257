<script>
import ProductorTable from '@/components/AdminComponents/ProductorComponents/ProductorTable.vue'
/* import ProductorForm from '@/components/AdminComponents/ProductorComponents/ProductorForm' */
    export default{
        name: 'ProductoresView',
        components:{
            ProductorTable,
        },
        data(){
            return{
                productor: {
                    id: 0,
                    nombre: '',
                    descripcion: '',
                    telefono: '',
                    correo: '',
                    img: '',
                    activo: 1,
                    id_qr:0
                }
            }
        },
        methods: {
            
        }
    }
</script>

<template>
    <div :class="['container-tab-productores']">
        <div :class="['container-head-productores']">
            <h1 :class="['titulo_vista']">Lista de productores</h1>
            <router-link 
                :class="['outline-btn','round']" 
                :to="{ name: 'agregar_productor', params: {productor: productor}}"
            >
                Agregar productor
            </router-link>
        </div>
        <ProductorTable />
    </div>
</template>

<style scoped>

    .titulo_vista{
        padding: 0;
        margin: 0;
    }
    
    .container-tab-productores{
        padding-inline: 0.8rem;
    }
    .container-head-productores{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        padding-block: 0.5rem;
    }

    .outline-btn{
        text-decoration: none;
        border-width: 2px;
    }
</style>