<script>
import EmpaquePost from "./EmpaquePost.vue";
import CircleLoader from "@/components/CircleLoader.vue";

    export default {
        name:'EmpqueList',
        components:{
            EmpaquePost,
            CircleLoader
        },
        mounted(){
            this.callEmpaques()
        },
        props:{
            empaques: Array,
            empaque: Object,
            stateCharge: Boolean
        },
        data(props){
            return {

            }
        },
        emits: ['callEmpaques','setLocalEmpaque','resetLocalEmpaque'],
        methods:{
            async callEmpaques(){
                this.$emit("callEmpaques")
            },
            setLocalEmpaque(empaque){
                this.$emit("setLocalEmpaque",empaque)
            },
            resetLocalEmpaque(){
                this.$emit('resetLocalEmpaque')
            }
        }
    }
</script>

<template>
    <div :class="['container-post-empaque']">
        <CircleLoader 
            :activo="stateCharge"
        />

        <EmpaquePost 
            v-for="emp in empaques" :key="emp.id" 
            :empaque="emp" 
            :selected="(emp.id==empaque.id)? true : false" 
            @setLocalEmpaque="setLocalEmpaque"
            @resetLocalEmpaque="resetLocalEmpaque"
        />
    </div>
</template>

<style scoped>
    .container-post-empaque{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        gap: 0.5rem;

        padding: 1rem;
    }

    .options{
        width: calc(100vw - 1rem);
        border: solid 1px lime;
        overflow: auto;
    }

    .options::-webkit-scrollbar{
        height: 5px;
    }

    .options::-webkit-scrollbar-thumb{
        background-color: #4dd3f4;
        border-radius: 0.5rem
    }

    .options ul{
        display: flex;
        gap: 0.5rem;
        padding: 0;
        margin: 0;
    }

    .options ul li{
        list-style-type: none;
        background-color: brown;
        color: white;
        padding: 0.3rem;
        border-radius: 0.3rem;
        text-wrap: nowrap;
    }
</style>