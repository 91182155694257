<template>
    <header :class="['title-container',{'padding-zero' : isActive}]">
        <input type="checkbox" id="trigger-opts" v-model="isActive">
        <h1 class="title"> <router-link :to="{ name : 'home'}"><img src="../../assets/menu_img/logo_flor.png"/></router-link>PuntoTuxtleco Admin</h1>

        <nav class="nav-options">
            <!-- <router-link :to="{ name: 'administrar'}">Administrar <i class="fa-solid fa-caret-down"></i></router-link> 
            <router-link :to="{ name: 'ventas'}"><i class="fa-solid fa-receipt"></i> Ventas</router-link>
            <router-link :to="{ name: 'pedidos'}"><i class="fa-solid fa-cubes-stacked"></i> Pedidos</router-link> -->
            <router-link :to="{ name: 'cuenta'}"><i class="fa-solid fa-user-large"></i> Cuenta</router-link>
        </nav>

        <!-- <div class="options-compressed">1</div> -->
        <label for="trigger-opts" :class="['button-options']"><i :class="['fa-solid', {'fa-bars' : !isActive, 'fa-xmark' : isActive}, 'fa-lg']"></i></label>
    </header>
</template>

<script>
export default {
    name:'TitleHeadComponent',
    data() {
        return {
            isActive : false,
        }
    },
    /* methods: {
        
    },
    computed: {

    },
    watch: {
        isActive: function(){
            console.log(window.innerWidth);
            if(window.innerWidth > 420) {
                this.isActive = false;
            }
        }
    } */
}
</script>
 
<style scoped>
    .title-container{
        background-color: #4DD3F4;
        padding: 0 1rem;
        
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
        gap: 0.7rem;
    }
        .title{
            margin: 0.5rem 0rem;
            font-family: 'Roboto';
            font-size: 1.2rem;
            font-weight: bold;
            color: white;
            text-shadow: 1px 1px 2px black;

            display: flex;
            flex-flow: row wrap;
            gap: 0.5rem;
        }

            .title-container a img{
                width: 1.5rem;
                height: auto;
            }
            .title-container a img:hover{
                animation-name: girab;
                animation-duration: 1.5s;
                animation-iteration-count: 1;
                animation-timing-function: ease-in-out;
            }
    
        .title-container .nav-options{
            height: 3rem;

            display: flex;
            flex-flow: row wrap;
            gap: 0.5rem;
            align-items: center;
            justify-content: space-between;

            flex-grow: 0;
        }

        /*.title-container .nav-options a.router-link-exact-active {
            color: #000;
        }*/

            .nav-options a{
                color: #eee;
                font-size: 0.9rem;

                border-radius: 1.5rem;
                padding: 0.5rem 0.7rem;

                transition-property: border color;
                transition-duration: 0.5s;
                transition-timing-function: ease-in-out;
            }

            .nav-options a:hover{
                color: #fff;
                background-color: rgba(0, 0, 255, 0.1);

                transition-property: border color;
                transition-duration: 0.5s;
                transition-timing-function: ease-in-out;
                text-decoration: none;
            }

            .title-container .nav-options a.router-link-active {
                color: #000;
                background-color: #90e5fa;
            }
            
        #trigger-opts{
            display: none;
        }

        .button-options {
            width: 2rem;
            height: 2rem;
            cursor: pointer;
            border: 2px solid #000;
            border-radius: 0.3rem;
            margin: 0;

            display: none;
            flex-flow: row wrap;
            justify-content: space-around;
            align-items: center;
        }

        .button-options:hover{
            border: 1px solid #eee;
        }

        .button-options:hover i{
            color: #eee;
        }

    @keyframes girab{
        0%{
            transform:rotate(0deg);
        }
        100%{
            transform:rotate(360deg);
        }
    }

    @media (max-width: 680px) {
        .title-container .nav-options{
            flex-grow: 1;
            margin-bottom: 0.5rem;
        }        
    }

    @media  (max-width: 430px){
        .button-options{
            display: flex;
        }

        .title-container .nav-options{
            display: none;
            flex-flow: column wrap;
            flex-basis: 100vw;

            height: auto;
        }

        .nav-options a{
            flex: 1;
            width: 100%;

        }

        #trigger-opts:checked ~ .nav-options{
            display: flex;
        }

        #trigger-opts:checked ~ .button-options{
            margin-bottom: 0.5rem;

            flex: 1;
            
        }
    }

    .padding-zero{
        padding: 0 0.5rem;
    }

    /*  
        Media queries a utilizar 
        *>0 && <375 - debe aparecer el icono del menu desplegable y configurar el menu desplegable
        *>=330 && <560 - se debe de poner el flex-grow en 1 y desaparecer el icono desplegable
        *>=560 -  se debe de poner el flex-grow en 0
    */
</style>