<template>
    <div class="admin container-admin">
        <div class="header"><TitleHead/></div>
        <div class="options-move"><NavOptions/></div>
        <div class="main"><router-view/></div>
    </div>
</template>

<script>
/* Importando componentes estaticos admin */
import TitleHead from '@/components/AdminComponents/TitleHead.vue';
import NavOptions from '@/components/AdminComponents/NavigationOptions.vue';
    export default {
        name: 'AdminVue',
        components: {
            TitleHead,
            NavOptions,
        },
        mounted() {
            document.title = 'PuntoTuxtleco Admin'
        }
    }
</script>

<style>

    *{
        padding: 0;
        margin: 0;
    }

    .container-admin {
        min-width: 100%;
        width: 100%;
        padding: 0px;
        display: grid;
        grid-template-areas: /*Se define area del template*/
            "header"
            "options-move"
            "main" 
        ;
        
    }

    .header{grid-area: header;}
    .options-move{grid-area: options-move;
        place-self: center;
    }
    .main{grid-area: main;}
    
    .footer{
        background-color: aqua;
        grid-area: footer;
    }

    /*------ Titulo ------*/
    .titulo_vista{ /*Estilo de titulo*/
        font-family: 'Quicksand';
        font-size: 1.5rem;
        font-weight: bold;
        color: #0dabd3;
        text-align: center;
        padding-inline: 1rem;
    }

    .tbg{ /*Tamaño de titulo grande*/
        font-size: 2rem;
    }

    .tsm{ /*Tamaño de titulo pequeño*/
        font-size: 1rem;
    }

    .text-left{
        text-align: left;
    }

    /*------ Input ------*/
    .container-field-input{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

    .field{ /* contenedor input & label*/
        position: relative;
        display: flex;
        margin: 0.8rem 0.5rem;
        flex-grow: 1;
    }

    .field input{ 
        color: #363636;
        background: none;
        padding: 0.625rem 0.4rem 0.4rem 0.625rem;
        border: solid 2px #0dabd3;
        flex-basis: 100%;
        outline: none;
    }

    .field label,
    .field span{
        position: absolute;
        top: 0;
        color: #0e88a7;
        background-color: #ffffff;
        padding: 0rem 0.25rem;
        border: solid 2px transparent;
        pointer-events: none;
        transition: translate 250ms, 
                    background-color 250ms,
                    border-color 250ms,
                    font-size 250ms;
    }
    .field label{
        left: 0;
        translate: 0.625rem 0.625rem;
    }
    .field span {
        right: 0;
        translate: -0.625rem 0.625rem;
    }

    .field:focus-within label,
    .field:not(:has(:placeholder-shown)) label{
        translate: 0.625rem -0.8rem;
        border-color: #0dabd3;
        border-radius: 0.2rem;
        background-color: #fff;
        color: #000;
        font-size: 0.8rem;
    }

    .field:focus-within span,
    .field:not(:has(:placeholder-shown)) span{
        translate: -0.625rem -0.8rem;
        border-color: #0dabd3;
        border-radius: 0.2rem;
        background-color: #fff;
        color: #000;
        font-size: 0.8rem;
    }

    .field ::placeholder{
        color: transparent;
    }

    

    /*------ Buttons ------*/
    /*Contenedor de botones en linea*/
    .button-container{
        display: flex;
        flex-flow: row wrap;
        margin-inline: 0.5rem;
        gap: 0.3rem 0.3rem;
    }

    .center{
        justify-content: center;
    }

    .end{
        justify-content: flex-end;
    }

    /*Normal-btn*/
    .normal-btn{
        border: solid 1px #0dabd3;
        background-color: #0dabd3;
        padding: 0.3rem 0.7rem;
        color: #fff;
        transition: background-color 300ms;
    }

    .normal-btn:hover{
        background-color: #0e88a7;
    }
    /*Outline-btn*/
    .outline-btn{
        border: solid 1px #0dabd3;
        background-color: transparent;
        padding: 0.3rem 0.7rem;
        color: #0dabd3;
        transition: background-color 300ms,
                    color 250ms;
    }

    .outline-btn:hover{
        background-color: #0dabd3;
        color: #ffffff;
    }
    /*Delete-btn*/
    .delete-btn{
        border: solid 1px #86092C;
        background-color: #f24072a2;
        padding: 0.3rem 0.7rem;
        color: #86092C;
        transition: background-color 250ms,
                    color 250ms;
    }

    .delete-btn:hover{
        color: #fff;
        background-color: #86092C;
    }

    .success-btn{
        border: solid 1px #2f8132;
        background-color: #4caf50;
        padding: 0.3rem 0.7rem;
        color: #fff;
        transition: background-color 250ms,
                    color 250ms;
    }

    .success-btn:hover{
        background-color: #2f8132;
    }

    /*Disabled-btn*/
    .disabled-btn{
        border: 1px solid #666;
        background-color: #aaa;
        color: #666;
        padding: 0.3rem 0.7rem;
        transition: background-color 300ms,
                    color 300ms;
    }

    .disabled-btn:hover{
        background-color: #666;
        color: #aaa;
    }

    .round{ /*Redondeado de boton*/
        border-radius: 0.5rem;
    }
    
    .circle{
        border-radius: 100%;
    }

    .bbg{ /*Boton de tamaño grande*/
        font-size: 1.2rem;
    }

    .bsm{ /*Boton de tamaño pequeño*/
        font-size: 0.8rem;
        padding: 0.4rem;
    }

    /*Post short view en Capacidad, Empaque & CAtegorias*/
    .post-short-view{
        border: solid 2px #0dabd3;
        border-radius: 0.5rem;

        font-family: 'Quicksand';
        font-weight: bold;
        font-size: 1rem;

        padding: 0.5rem 1rem;

        transition: background-color 0.3s linear,
                    transform 0.3s linear            
        ;
        cursor: pointer;
    }

    .active, .post-short-view:hover{
        background-color: #4dd3f486;
        transform: translate(0px, -4px);
    }
    .active{
        background-color: #4dd3f4ce;
        font-weight: bold;
    }


    .field_textarea{
        position: relative;
        display: flex;
        margin: 0.8rem 0.5rem;
        flex-grow: 1;
    }
    
    .field_textarea .input_field{
        flex-basis: 100%;
        background: none;
        border: solid 2px #888;
        color: #363636;
        padding: 0.625rem 0.4rem 0.4rem 0.625rem;
        transition: border-color 300ms ease,
                    outline, 300ms ease;
    }
    
    .field_textarea .msg{
        position: absolute;
        top: 0;
        background-color: #ffffff;
        border: solid 2px transparent;
        color: #444;
        padding: 0rem 0.25rem;
        pointer-events: none;
        transition: translate 250ms, 
                    background-color 250ms,
                    border-color 250ms,
                    font-size 250ms;
    }
    
    .field_textarea .m-l{
        left: 0;
        translate: 0.625rem 0.625rem;
    }
    .field_textarea .m-r{
        right: 0;
        translate: -0.625rem 0.625rem;
    }
    
    .field_textarea ::placeholder{
        color: transparent;
    }
    
    .field_textarea:focus-within .input_field{
        outline: 5px solid rgba(9, 117, 145, 0.25);
        border-color: rgba(9, 117, 145, 1);
    }
    
    .field_textarea:focus-within .msg,
    .field_textarea:not(:has(:placeholder-shown)) .msg{
        border-radius: 0.2rem;
        background-color: #fff;
        color: #444;
        border-color: #888;
        font-size: 0.8rem;
    }
    
    .field_textarea:focus-within .m-l,
    .field_textarea:not(:has(:placeholder-shown)) .m-l{
        translate: 0.625rem -0.8rem;
    }
    .field_textarea:focus-within .m-r,
    .field_textarea:not(:has(:placeholder-shown)) .m-r{
        translate: -0.625rem -0.8rem;
    }

    .field-cyan:not(:has(:placeholder-shown)) .input_field{
        border-color: #0dabd3;
    }
    
    .field-cyan:not(:has(:placeholder-shown)) .msg{
        border-color: #0dabd3;
        color: #000;
    }

    .container-hiper{
        display: flex;
        flex-flow: row wrap;
        gap: 0.3rem;
        justify-content: center;
    }
    
    .hiper_vin-error{
        outline: none;
        padding: 2px 5px 0;
        color: #530101;
        font-family: 'Roboto';
        text-transform: uppercase;
    }
    
    
    .hiper_vin-error:hover {
        border-bottom: 1px solid;
        color: #530101;
        background: #feaab8;
        border-radius: 0.1rem;
        text-decoration: none;
    }
    
    .hiper_vin-warning{
        outline: none;
        padding: 2px 5px 0;
        color: #525301;
        font-family: 'Roboto';
        text-transform: uppercase;
    }
    
    .hiper_vin-warning:hover {
        color: #525301;
        text-decoration: none;
        border-bottom: 1px solid;
        background: #fefdaa;
        border-radius: 0.1rem;
    }
</style>

