<script>
import OrdenService from '@/services/OrdenService'
import CircleLoader from '@/components/CircleLoader.vue'
    export default{
        name: 'SuccessPayView',
        components: {
            CircleLoader
        },
        mounted(){
            if(this.$cookies.isKey('orden')){
                this.creandoOrden()
            }else {
                this.$router.push({ name: 'home' })
            }
        },
        data(){
            return{
                orden: this.$cookies.get('orden'),
                reintentar: false,
                stateCharge: false
            }
        },
        methods: {
            async creandoOrden(){
                this.stateCharge = true
                const response = await OrdenService.createOrdenFull(this.$cookies.get('token'), this.orden)
                if (response.success) {
                    console.log('Se completo la compra con exito')
                    this.$cookies.set('estado',1)
                    this.$cookies.remove('orden')
                    this.$router.push({ name: 'home' })
                }else {
                    this.reintentar = true
                }
                this.stateCharge = false
            }
        },
        computed: {
            msgUser: function (){
                return (this.reintentar) ? 'Por favor verifique su conexion a internet y de click al boton Finalizar pedido y espere a que se redireccione automaticamente' : 'Resolviendo compra, por favor no actualice esta pagina, se le redirigira pronto'
            }
        }
    }
</script>

<template>
    <!-- this.$router.push({ name: 'home' }) -->
    <div>
        <br><br><CircleLoader :activo="stateCharge" /><br><br>
        <p 
            :class="['info-message',{'info-message__error':reintentar, 'info-message__ok': !reintentar}]"
        > 
            {{ msgUser }} 
        </p>
        <button
            v-if="reintentar"
            :class="['btn-material']"
            
        >
            Finalizar pedido
        </button>
    </div>
</template>

<style scoped>
    .info-message{
        margin: 0.5rem;
        padding-inline: 0.5rem;
        border-radius: 0.5rem;
        font-weight: bold;
    }

    .info-message__ok{
        background-color: #6cbf6d8c;
    }

    .info-message__error{
        background-color: #bf6c6c8c;
    }
</style>