<template>
  <div>
    <h1 :class="['titulo_vista','tbg']">Cuenta</h1>
    <!-- <span> {{ token }} </span> -->
    <img style="width:100px" src="https://puntotuxtleco.com.mx/img/productores/cafelasjoyas.jpg" alt="imagen de productor">
    <form @submit.prevent="saveImage" enctype="multipart/form-data" >
      <input type="file" ref="fileImageInput" accept="image/*" @change="handleFileChange">
      <button type="submit">Guardar Imagen</button>
    </form>
  </div>
</template>

<script>
import UploadImgService from '@/services/UploadImgService';

export default {
  name: 'CuentaView',
  data(){
    return{
      token: this.$cookies.get('token'),
      response: {},
      selectedFile: null
    }
  },
  mounted() {
    //this.get_categories();
  },
  methods: {

    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
      console.log(this.selectedFile.name)
    },
    async saveImage(){
      console.log(this.selectedFile)
      if (this.selectedFile) {
        const formData = new FormData();
        formData.append('file', this.selectedFile);
        formData.append('name_qr', this.selectedFile.name)
        console.log({ formData })

        
        const response = await UploadImgService.uploadImgSingleQR(this.$cookies.get('token'), formData)
        if(response.success){
          this.$refs.fileImageInput.value = null
          alert("El archivo se ha subido satisfactoriamente :)")
        }else{
          alert("Oh no, algo ha salido mal :(, por favor intentelo de nuevo mas tarde");
        }
        /*
          fetch(endpoint, {
            method: "post",
            body: formData
          }).catch(console.error)

        
        */
      } 
    }
    /*...mapMutations(['changeApiUrl']),
    setNewValue() {
      this.changeApiUrl(this.new_url)
    },
    async get_categories(){
      const categorias = await Categorias.getCategorias()
    }*/
  },
  computed: {
    /*...mapState(['api_url']),
    ...mapGetters(['getApiVentas'])*/
  },
  
}
</script>

<style>

</style>