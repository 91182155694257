<script>
    export default{
        name: 'ImagePost',
        props:{
            imagenProd: Object,
            selected: Boolean,
        },
        data(){
            return{
                nameImg: '',
                idProductor: '',
                ext: ''
            }
        },
        emits: ['setLocalImagenProd','resetLocalImagenProd'],
        methods: {
            sendImagenProd(){
                if(!this.selected){
                    this.$emit('setLocalImagenProd',JSON.parse(JSON.stringify(this.imagenProd)))
                }else{
                    this.$emit('resetLocalImagenProd')
                }
            },

        },
        computed:{
            uriImg: function(){
                const randomSuffix = Math.random().toString(36).substring(7)
                const baseUrl = 'https://api.puntotuxtleco.com.mx/img/productos/';
                const splitUri = this.imagenProd.uri_img.split('/')
                this.idProductor = splitUri[0]
                const splitName = splitUri[1].split('.')
                this.nameImg = splitName[0]
                this.ext = splitName[1]
                return `${baseUrl}${this.imagenProd.uri_img}?${randomSuffix}`
            }
        }
    }
</script>

<template>
    <article
        :class="['post-img',{'activeimg':selected}]"
        @click="sendImagenProd"
    >
        <img 
            :src="uriImg" 
            alt="Imagen Producto" 
            loading="lazy"
        />
        <p> {{ nameImg }}.{{ ext }} </p>
    </article>
    
</template>

<style scoped>
    .post-img{
        flex-basis: 150px;
        border-radius: 0.5rem;
        border: solid 2px #0dabd3;
        transition: 
            box-shadow 0.3s ease-in,
            transform 0.3s ease-out;
        display: flex;
        flex-flow: column wrap;
    }

    .activeimg,
    .post-img:hover{
        box-shadow: rgba(46, 185, 240, 0.4) -5px 5px, rgba(46, 185, 240, 0.3) -10px 10px, rgba(446, 185, 240, 0.2) -15px 15px, rgba(46, 185, 240, 0.1) -20px 20px, rgba(46, 185, 240, 0.05) -25px 25px;
        transform: translate(4px, -4px);
    }

    .post-img img {
        width: 100%;
        height: auto;
        max-height: 125px;
        border-radius: 0.5rem 0.5rem 0 0;
        padding: 0.3rem;
        object-fit: contain;
    }

    .post-img p {
        color: #fff;
        margin: 0;
        padding: 0.15rem;
        flex-grow: 1;
        background-color: #0bb3dd;
        font-family: 'Quicksand';
        font-weight: bold;
        font-size: 0.8rem;

        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
        
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;

        transition: 
            font-weight 0.3s ease-in-out
        ;
    }
</style>