<script>
import QRPost from "@/components/AdminComponents/QRComponents/QRPost.vue"
import CircleLoader from "@/components/CircleLoader.vue";
    export default{
        name: 'QRList',
        components:{
            QRPost,
            CircleLoader
        },
        mounted(){
            this.getQRs()
        },
        props: {
            qrs: Array,
            qr: Object,
            stateCharge: Boolean
        },
        data(props){
            return{
                
            }
        },
        emits:['setLocalQR','resetLocalQR','getQRs','updateNameQR','deleteImgQR'],
        methods: {
            async getQRs(){
                this.$emit('getQRs')
            },
            async updateNameQR(idQR, name_qr, new_name_qr){
                this.$emit('updateNameQR',idQR, name_qr, new_name_qr);
            },
            async deleteImgQR(idQR){
                this.$emit('deleteImgQR',idQR)
            },
            setLocalQR(qr){
                console.log("QRPost -> QRList")
                this.$emit('setLocalQR',qr)
            },
            resetLocalQR(){
                this.$emit('resetLocalQR')
            }
        }
    }
</script>

<template>
    <div :class="['container-post-imagen']">
        <CircleLoader 
            :activo="stateCharge"
        />
        <QRPost 
            v-for="elmqr in qrs" :key="elmqr.id"
            @updateNameQR="updateNameQR"
            @deleteImgQR="deleteImgQR"
            @setLocalQR="setLocalQR"
            @resetLocalQR="resetLocalQR"
            :qr="elmqr"
            :selected="(elmqr.id == qr.id) ? true : false"
            :stateCharge="stateCharge"
        />
    </div>
</template>

<style scoped>
    .container-post-imagen{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        gap: 0.5rem;

        padding: 1rem;
        align-items: flex-start;
    }
</style>