import axios from "axios";
import store from '@/store';
const baseUrlApi =  store.state.apiRender;
const urlApiProducto = `${baseUrlApi}producto/`;

async function getJoinProductos() {
    const apiUrl = baseUrlApi+ 'productos'; 
    try {
        // Realizar la solicitud GET a la API
        const respons = await axios.get(apiUrl)
        const data = respons.data

        return data
    } catch (error) {
        console.log('Error al obtener los datos de Productos ' + error)
    }
}

async function createProducto(token, new_producto){
    try {
        const response = await axios.post(urlApiProducto, new_producto,
            {
                headers: {
                    Authorization: token
                }
            }
        );
        return {created:response.data, success: true}
    } catch (e){
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

async function updateProducto(token, id_producto, edited_producto){
    try {
        const response = await axios.patch(urlApiProducto+id_producto, edited_producto,
            {
                headers: {
                    Authorization: token
                }
            }
        );
        return {updated: response.data, success: true}
    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

async function deleteProducto(token, id_producto){
    try {
        const response = await axios.delete(urlApiProducto+id_producto, 
            {
                headers: {
                    Authorization: token
                }
            }
        );
        return {deleted: response.data, success: true}
    } catch (error) {
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

async function getSelectedProductos(ids){
    const apiUrl = baseUrlApi+ 'producto-selected/'
    try {
        //Realizar la solicitud POST para obtener ids seleccionadas
        const response = await axios.post(
            apiUrl, {
                ids : ids
            }
        )

        return {response: response.data.productos, success: true}
    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

async function getProductos() {
    const apiUrl = baseUrlApi+'producto/';
    try {
        // Realizar la solicitud GET a la API
        const respons = await axios.get(apiUrl)
        const data = respons.data

        return data
    } catch (error) {
        console.log('Error al obtener los datos de Productos ' + error)
    }
}

async function getProducto(id) {
    const apiUrl = baseUrlApi+'producto/' + id;
    try {
        // Realizar la solicitud GET a la API
        const respons = await axios.get(apiUrl)
        const data = respons.data

        return data
    } catch (error) {
        console.log('Error al obtener los datos de Productos ' + error)
    }
}
async function getImagen(id) {
    const apiUrl = baseUrlApi+'imagenqr/' + id;
    try {
        // Realizar la solicitud GET a la API
        const respons = await axios.get(apiUrl)
        return respons.data
    } catch (error) {
        console.log('Error al obtener los datos de Imagen Productos ' + error)
    }
}

async function getCategoria_producto(id) {

    const apiUrl = baseUrlApi+'categoria/' + id;
    try {
        // Realizar la solicitud GET a la API
        const respons = await axios.get(apiUrl)
        return respons.data
    } catch (error) {
        console.log('Error al obtener los datos de categoria ' + error)
    }
}

async function getCarrito(id_user, token) {
    const apiUrl = baseUrlApi+'carrito/' + id_user;
    try {
        // Realizar la solicitud GET a la API
        const respons = await axios.get(apiUrl, { headers: { Authorization: `${token}` } })
        return respons.data
    } catch (error) {
        console.log('Error al obtener los datos del carrito ' + error)
    }
}
 async function getCarrito_detalle(id_carrito, id_producto, token) {
    const apiUrl = baseUrlApi+'carrito_detalle/' + id_carrito + '/' + id_producto;
    try {
        // Realizar la solicitud GET a la API
        const respons = await axios.get(apiUrl, { headers: { Authorization: `${token}` } })
        return respons.data
    } catch (error) {
        return false
    }
}
async function getAdd_Car(id_carrito, id_producto, token) {
    const apiUrl = baseUrlApi+'carrito_detalle_getAdd_Car/' + id_carrito + '/' + id_producto;
    try {
        // Realizar la solicitud GET a la API
        const respons = await axios.get(apiUrl, { headers: { Authorization: `${token}` } })
        
        return respons.data
    } catch (error) {
        return false
    }
}
async function getCars_details(id_carrito, token) {
    const apiUrl = baseUrlApi+'carrito_detalle_getCars_details/' + id_carrito;
    try {
        // Realizar la solicitud GET a la API
        const respons = await axios.get(apiUrl, { headers: { Authorization: `${token}` } })        
        return respons.data
    } catch (error) {
        return false
    }
}
async function getCarrito_detalle_2(id_carrito, token) { 
    const apiUrl = baseUrlApi+'carrito_detalle/' + id_carrito
    try {
        // Realizar la solicitud GET a la API+
        const respons = await axios.get(apiUrl, { headers: { Authorization: `${token}` } })
        return respons.data
    } catch (error) {
        console.log('Error al obtener los datos del carrito detalle ' + error)
    }
}
async function setCarrito_detalle(id_carrito, id_producto, cantidad, token) {
    const apiUrl = baseUrlApi+'carrito_detalle';
    try {
        const datos = {
            id_carrito: id_carrito,
            id_producto: id_producto,
            cantidad: cantidad
        };
        // Realizar la solicitud GET a la API
        const respons = await axios.post(apiUrl, datos, { headers: { Authorization: `${token}` } })
        return respons.data
    } catch (error) {
        console.log('Error al subir los datos del carrito ' + error)
    }
}
async function updateCarrito_detalle(id_carrito, id_producto, cantidad, token) {
    const apiUrl = baseUrlApi+'carrito_detalle/' + id_carrito + '/' + id_producto;
    try {
        const datos = {
            cantidad: cantidad
        };
        const respons = await axios.patch(apiUrl, datos, { headers: { Authorization: `${token}` } })
        return respons.data
    } catch (error) {
        console.log('Error al subir los datos del carrito ' + error)
    }
}

async function updateStock( id_producto_, cantidad_, token) {
    const apiUrl = baseUrlApi+'producto/'+ id_producto_;
    try {
        const datos = {
            cantidad: cantidad_,
        };
        const respons = await axios.patch(apiUrl, datos, { headers: { Authorization: `${token}` } })
        return respons.data
    } catch (error) {
        console.log('Error al subir los datos del carrito ' + error)
    }
}

async function Delete_Producto_carrito(id_carrito, id_producto_, token) {
    const apiUrl = baseUrlApi+'carrito_detalle/'+ id_carrito+'/'+id_producto_;
    try {
        const respons = await axios.delete(apiUrl, { headers: { Authorization: `${token}` } })
        return respons.data
    } catch (error) {
        console.log('Error al eliminar el producto del carrito (service) ' + error)
    }
}

export {
    getJoinProductos, createProducto, updateProducto,deleteProducto,
    getCars_details,getAdd_Car,
    getProductos, getProducto, getImagen, getCategoria_producto,
    getCarrito, getCarrito_detalle, getCarrito_detalle_2, updateCarrito_detalle,
    setCarrito_detalle, getSelectedProductos,Delete_Producto_carrito,updateStock
};