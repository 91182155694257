<template>
  <div class="custom-scroll">
    <h1 :class="['titulo_vista']">Lista de pedidos</h1>
    <PedidoList/>
  </div>
</template>

<script>
import PedidoList from "@/components/AdminComponents/PedidoList.vue";
  export default {
    name: 'PedidosView',
    components: {
      PedidoList
    },
    data(){
      return{

      }
    }
  }
</script>

<style>

</style>