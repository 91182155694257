<script>
import CircleLoader from '@/components/CircleLoader.vue';
import ProductVentaPostDetail from '@/components/AdminComponents/VentaDetailComponents/ProductVentaPostDetail.vue'
import InfoVentaPostDetail from '@/components/AdminComponents/VentaDetailComponents/InfoVentaPostDetail.vue';
import OrdenService from '@/services/OrdenService';

export default{
    name: 'VentaDetailView',
    components: {
        CircleLoader,
        ProductVentaPostDetail,
        InfoVentaPostDetail
    },
    mounted(){
        this.getOrdenDetalleByID()
    },
    props: {
        id: Number,
        venta: Object
    },
    data() {
        return {
            orden: [],
            stateCharge: false
        }
    },
    methods: {
        async getOrdenDetalleByID() {
            this.stateCharge = true
            const response = await OrdenService.getJoinOrdenDetalleByID(this.$cookies.get('token'), this.id);
            if (response.success) {
                this.orden = response.orden;
            }
            this.stateCharge = false
        },
        changeFormat(number){
            const formatter = new Intl.NumberFormat('es-MX',{
                style: 'currency',
                currency: 'MXN'
            })

            return formatter.format(number)
        }
    },
    computed: {
        domicilioToString: function () {
            const objDomicilio = this.venta.domicilio;
            let domicilio = `Ciudad. ${objDomicilio.ciudad}, Colonia. ${objDomicilio.colonia}, Calle. ${objDomicilio.calle}, #Ext. ${objDomicilio.num_ext}, #Int. ${objDomicilio.num_int}, CP. ${objDomicilio.c_p}`;
            return domicilio;
        },
        infoCliente: function(){
            return [
                {
                    title_row: 'Nombre',
                    content_row: `${this.venta.usuario.nombre} ${this.venta.usuario.apellido_p} ${this.venta.usuario.apellido_m}`
                },
                {
                    title_row: 'Correo',
                    content_row: `${this.venta.usuario.correo}`
                }
            ]
        },
        direccion: function(){
            return [
                {
                    title_row: 'Domicilio',
                    content_row: `${this.domicilioToString}`
                }
            ]
        },
        infoVenta: function(){
            return [
                {
                    title_row: 'Fecha solicitud',
                    content_row: `${this.venta.fecha_solicitud}`
                },
                {
                    title_row: 'Fecha entrega',
                    content_row: `${this.venta.fecha_entrega}`
                },
                {
                    title_row: 'Total',
                    content_row: `${this.changeFormat(this.venta.total)}`
                }
            ]
        },
        fecha_actual: function() {
            const ahora = new Date();
            // Formatear la fecha en formato YYYY-MM-DD (el formato esperado por el input type="date")
            const fechaFormateada = ahora.toISOString().split('T')[0];
            return fechaFormateada;
        }
    },
}
</script>

<template>
    <div :class="['view_detail']">
        <!-- 
            🟠 #ff6723
            🟡 #fcd53f
            🟢 #00d26a
            🔵 #0074ba
         -->
        <header :class="['header-form']">
            <router-link :to="{ name: 'ventas'}"><i class="fa-solid fa-arrow-left"></i> volver</router-link>
            <span :class="['text']"> ID: <strong> {{ id }} </strong></span>
        </header>
        <div :class="['short-view']">
            <div
                :class="['short-view-child']"
            >
                <h2 :class="['titulo_vista']">Detalles de venta</h2>
                <InfoVentaPostDetail
                    :title="'Información del cliente'"
                    :rows="infoCliente"
                />

                <InfoVentaPostDetail
                    :title="'Dirección'"
                    :rows="direccion"
                />

                <InfoVentaPostDetail
                    :title="'Información de la venta'"
                    :rows="infoVenta"
                />
            </div>
            <div
                :class="['short-view-child']"
            >
                <h2 :class="['titulo_vista']">Productos</h2>
                <CircleLoader :activo="stateCharge" />
                <ProductVentaPostDetail 
                    v-for="(detalle,index) in orden" :key="index"
                    :itemOrden="detalle"
                    :index="index+1"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>
    .view_detail {
        display: flex;
        flex-flow: column wrap;
        padding: 0rem 0.5rem;
        justify-content: flex-start;
        background-color: #fff;
    }

    .view_detail .header-form{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 0.3rem 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 7px 5px -3px;
        margin-bottom: 1rem;
        background-color: #fff;
    }

    .header-form a{
        color: black;
        font-family: 'Quicksand';
        font-weight: bold;
        font-size: 1rem;
    }
    
    .header-form .text{
        color: black;
        font-family: 'Quicksand';
        font-size: 1rem;
        background-color: transparent;
        margin: 0;
        padding: 0;
    }

    .text{
        color: black;
        font-family: 'Quicksand';
        font-size: 0.9rem;
        background-color: #fff;
        margin: 0.1rem 1rem;
        padding: 0.2rem;
    }

/*division*/
    .short-view{
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        gap: 0.5rem;
        
    }

    .short-view-child{
        flex: 1;
    }

    .view-form{
        padding: 0 0.5rem;
    }

    @media (max-width: 800px){
        .short-view{
            flex-flow: column wrap;
            align-items: stretch;
            flex: 0;
        }
    }
</style>