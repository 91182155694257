<script>
    export default{
        name:'ProductoresForm',
        mounted(){
            this.getQRs()
            this.productor_edited = JSON.parse(JSON.stringify(this.productor))
        },
        props: {
            productor: Object,
            qrs: Array
        },
        data(){
            return{
                productor_edited: {
                    nombre: '',
                    descripcion: ''
                },
                selectedFile: null
            }
        },
        emits: ['getQRs','updateImgProductor','updateProductor'],
        methods: {
            async getQRs(){
                this.$emit('getQRs')
            },
            async updateImgProductor(){
                const formData = new FormData();
                formData.append('name_img', this.productor.img)
                formData.append('file', this.selectedFile)

                console.log({formData})
                this.$emit('updateImgProductor', formData)
            },
            async updateDataProductor(){
                const productor_data = {}
                if(this.productor.nombre !== this.productor_edited.nombre) productor_data.nombre = this.productor_edited.nombre;
                if(this.productor.descripcion !== this.productor_edited.descripcion) productor_data.descripcion = this.productor_edited.descripcion
                if(this.productor.telefono !== this.productor_edited.telefono) productor_data.telefono = this.productor_edited.telefono
                if(this.productor.correo !== this.productor_edited.correo) productor_data.correo = this.productor_edited.correo
                if(this.productor.img !== this.productor_edited.img){
                    productor_data.img = this.productor_edited.img
                    productor_data.old_img = this.productor.img
                }
                if(this.productor.activo != this.productor_edited.activo) {
                    productor_data.activo = this.productor_edited.activo
                }
                if(this.productor.id_qr !== this.productor_edited.id_qr) productor_data.id_qr = this.productor_edited.id_qr

                console.log({productor_data})
                this.$emit('updateProductor', this.productor.id, productor_data)
            },
            handleFileChange(event){
                this.selectedFile = event.target.files[0];
                const img = document.getElementById('EditImgPreviewProductor');
                const reader = new FileReader()
                reader.onload = function (e) {
                    img.src = e.target.result;
                }
                reader.readAsDataURL(event.target.files[0])
                console.log(this.selectedFile.name)
            },
            activateInput(elementId){
                document.getElementById(elementId).click()
            },
            cancelImgUpdate(){
                this.selectedFile = null
                this.$refs.fileImageInputImgProductor.value = null
                const img = document.getElementById('EditImgPreviewProductor');
                img.src = this.uriImg(this.productor.img)
            },
            uriImg(nameImg){
                let randomSuffix = Math.random().toString(36).substring(7)
                const uriImgProductores = 'https://api.puntotuxtleco.com.mx/img/productores/'
                return `${uriImgProductores}${nameImg}?${randomSuffix}`
            },
        },
        computed: {
            hasFileSelected: function(){
                return (this.selectedFile===null) ? false : true
            },
            activeActualizar: function(){
                if(this.productor.nombre !== this.productor_edited.nombre || 
                    this.productor.descripcion !== this.productor_edited.descripcion ||
                    this.productor.telefono !== this.productor_edited.telefono || 
                    this.productor.correo !== this.productor_edited.correo ||
                    this.productor.img !== this.productor_edited.img ||
                    this.productor.activo != this.productor_edited.activo ||
                    this.productor.id_qr !== this.productor_edited.id_qr){
                    return true;
                }else{
                    return false;
                }
            },
            estadoProductorActivo: function () {
                return (this.productor_edited.activo) ? 'Activo' : 'Inactivo'
            },
            lengthOfDescripcion: function(){
                return this.productor_edited.descripcion.length
            }
        }
    }
</script>

<template>
    <!-- <form :class="['form']">
        <h2 :class="['title-form']">Agregar un nuevo productor</h2>
        <p :class="['description-form']">Los productores son aquellos por los que se asocia a un grupo de productos</p>

        <div class="line-material-form">
            <div :class="['input-material-form']">
                <input v-model=" productor.nombre " :class="['input-form']" type="text" title="Por favor, ingrese nombre del productor" required>
                <label :class="['label-form']">Nombre productor</label>
            </div>

            <div :class="['input-material-form']">
                <input v-model=" productor.descripcion " :class="['input-form']" type="text" title="Por favor, ingrese una descripcion del productor" required>
                <label :class="['label-form']">Descripcion productor</label>
            </div>
        </div>

        <div class="line-material-form">
            <div :class="['input-material-form']">
                <input v-model=" productor.correo " :class="['input-form']" type="text" title="Por favor, ingrese correo del productor" required>
                <label :class="['label-form']">Correo productor</label>
            </div>

            <div :class="['input-material-form']">
                <input v-model=" productor.telefono " :class="['input-form']" type="text" title="Por favor, ingrese telefono del productor" required>
                <label :class="['label-form']">Telefono productor</label>
            </div>
        </div>
        
        <input type="file" title="Por favor, seleccione la imagen del productor">
        <input type="file" title="Por favor, seleccione QR del productor">
    </form> -->

<div :class="['template_form']">
    <form enctype="multipart/form-data">
        <input
            :class="['offscreen']"
            type="file" 
            id="fileimgproductor"
            ref="fileImageInputImgProductor" 
            accept="image/*" 
            @change="handleFileChange"
        />
        <section
            :class="['preview-upload-img']"
        >
            <img 
                :src="uriImg(productor.img)"
                id="EditImgPreviewProductor" 
                alt="Imagen productor" 
                loading="lazy"
                @click="activateInput('fileimgproductor')"
            />

            <div
                v-show="hasFileSelected"
                :class="['button-container','end']"
            >
                <button
                    :class="['normal-btn','round']"
                    type="button"
                    @click="updateImgProductor"
                >
                    Actualizar
                </button>
                <button
                    :class="['delete-btn','round']"
                    type="button"
                    @click="cancelImgUpdate"
                >
                    Cancelar
                </button>
            </div>
        </section>
        <section :class="['edit_section']">
            <section
                :class="['option-check']"
            >
                <label :class="['label_check']">Estado: <strong>{{ estadoProductorActivo }}</strong> </label>
                <div :class="['check-field']">
                    <input 
                        v-model="productor_edited.activo"
                        id="activo_productor" 
                        :class="['offscreen','original_check']" 
                        type="checkbox"
                    >
                    <label for="activo_productor" :class="['new_check']"></label>
                </div>
            </section>

            <div :class="['field_textarea','field-cyan']">
                <input
                    v-model="productor_edited.nombre"
                    :class="['input_field','round']"
                    type="text"
                    id="nombre_productor"
                    placeholder="..."
                >
                <label :class="['msg','m-l']" for="nombre_productor">Nombre</label>
            </div>

            <div :class="['container-field-input']">
                <div :class="['field_textarea','field-cyan']">
                    <input
                        v-model="productor_edited.telefono"
                        :class="['input_field','round']"
                        type="text"
                        id="telefono_productor"
                        maxlength="10"
                        placeholder="..."
                    >
                    <label :class="['msg','m-l']" for="telefono_productor">Telefono</label>
                </div>
                <div :class="['field_textarea','field-cyan']">
                    <input
                        v-model="productor_edited.correo"
                        :class="['input_field','round']"
                        type="text"
                        id="correo_productor"
                        placeholder="..."
                    >
                    <label :class="['msg','m-l']" for="correo_productor">Correo</label>
                </div>
            </div>

            <div :class="['field_textarea','field-cyan']">
                <textarea 
                    v-model="productor_edited.descripcion" 
                    :class="['input_field','round']"
                    id="descripcion_productor"
                    maxlength="250"
                    placeholder="..."
                ></textarea>
                <label :class="['msg','m-l']" for="descripcion_productor">Descripcion</label>
                <span :class="['msg','m-r']"> {{ lengthOfDescripcion }}/250</span>
            </div>

            <section :class="['select-design']">
                <select 
                    :class="['select_options']" 
                    v-model="productor_edited.id_qr"
                >
                    <option 
                        disabled 
                        :value="0"
                    >
                        Seleccione un qr
                    </option>
                    <option
                        v-for="qr in qrs" :key="qr.id"
                        :value="qr.id"
                    >
                        {{ qr.name_qr }}
                    </option>
                </select>
            </section>
        </section>

        <div
            :class="['button-container','end']"
        >
            <button
                :class="[{'success-btn': activeActualizar, 'disabled-btn': !activeActualizar},'round']"
                type="button"
                :disabled="!activeActualizar"
                @click="updateDataProductor"
            >
                Actualizar
            </button>
        </div>
    </form>
</div>

</template>

<style scoped>

.offscreen{
    display: none;
}
.template_form{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-block: 0.5rem;
}

.template_form form{
    flex-basis: 700px;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.preview-upload-img{
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    justify-content: center;
    gap: 1rem 0.3rem;
}

.preview-upload-img img{
    align-self: center;
    max-width: 200px;
    min-width: 150px;
    max-height: 200px;
    padding: 0.3rem;
    object-fit: contain;

    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.50) 0px 5px 15px;
    cursor: pointer;
}

.select-design{
    display: flex;
    flex-flow: row wrap;
}
.select_options{
    flex-basis: 100%;

    margin-inline: 0.5rem;
    padding: 0.5rem 1rem;
    border: solid 1px #e3e5e7;
    background-color: white;
    font-family: 'Roboto', sans-serif;
    border-radius: 0.3rem;
    font-size: 0.9rem;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.option-check{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding-inline: 1rem;
}

.label_check{
    font-family: 'Roboto';
    font-size: 1rem;
}

.label_check strong{
    color: #096981;
}

.check-field{
    margin-block: 0.5rem;
}
.check-field .new_check{
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
    background-color: #888;
    color: transparent;
    border-radius: 26px;
    cursor: pointer;
    margin: 0;
    transition: background-color 300ms ease 300ms;
}

.check-field .new_check::after{
    content: ' ';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #fff;
    left: 3px;
    top: 3px;
    transition: transform 300ms ease;
}

.check-field input[type='checkbox']:checked + .new_check::after{
    transform: translateX(24px);
}

.check-field input[type='checkbox']:checked + .new_check{
    background-color: #0dabd3;
}

.button-container{
    margin-block: 0.5rem;
}

</style>