<template>
    <div :class="['pedidos-container']">
        <CircleLoader 
            :activo="stateCharge" 
        />
        <PedidoPost 
            v-for="(pedido, index) in  pedidos" :key="index" 
            :pedido="pedido"
        />
    </div>
</template>

<script>
import PedidoPost from '@/components/AdminComponents/PedidoPost.vue'
import CircleLoader from '../CircleLoader.vue'
import OrdenService from '@/services/OrdenService'


    export default{
        name: 'PedidoList',
        components:{
            PedidoPost,
            CircleLoader
        },
        mounted(){
            this.getPedidos()
        },
        data(){
            return{
                pedidos: [],
                stateCharge: false
            }
        },
        methods: {
            async getPedidos(){
                this.stateCharge = true
                const response = await OrdenService.getPedidos(this.$cookies.get('token'))
                if (response.success) {
                    this.pedidos = response.pedidos.data
                }
                this.stateCharge = false
            }
        }
    }
</script>

<style scoped>
    .pedidos-container{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 0.5rem;
        align-content: flex-start;
        
        padding: 1rem;
    }

    @media (max-width: 900px){
        .pedidos-container{
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media (max-width: 600px){
        .pedidos-container{
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 500px){
        .pedidos-container{
            grid-template-columns: repeat(1, 1fr);
        }
    }
</style>