<template>
    <article :class="['domicilio-post-detail', { 'domicilio-post-detail-active': is_cheked }]" @click="sendDomicilio">
        <input :class="['input-radio']" type="radio" name="domicilio" :checked="is_cheked">
        <span :class="['domicilio-text']">{{ textbase }} </span>
    </article>
</template>

<script>
export default {
    name: 'DomicilioPost',
    props: {
        domicilio: {
            required: true,
        },
        is_cheked: {
            required: true,
        }
    },
    data(props) {
        return {
            textbase: 'Calle: ' + this.domicilio.calle + ' #ext: ' + this.domicilio.num_ext + ' #int: ' + this.domicilio.num_int + ', Col. ' + this.domicilio.colonia + ', C.P.: ' + this.domicilio.c_p + ', Ciudad: ' + this.domicilio.ciudad + ',  Estado: ' + this.domicilio.estado
        }
    },
    mounted() {

    },
    emits: ['setDomicilio'],
    methods: {
        sendDomicilio() {
            if (!this.is_cheked) {
                this.$emit("setDomicilio", this.domicilio)
            } else {
                console.log('El mismo')
            }

        }
    }
}
</script>
