import axios from "axios";
import store from '@/store';
const apiUrl = store.state.apiRender;
const apiUrlProductor = `${apiUrl}productor/`

     async function getProductores(){
        try {
            // Realizar la solicitud GET a la API
            const respons = await axios.get(apiUrlProductor)
            const data = respons.data
            return data
        } catch (error) {
            console.log('Error al obtener los datos de los Productores '+error)
        }
    }

    async function getTinyProductores(){
        try {
            const response = await axios.get(apiUrlProductor+'tiny/')
            return {
                productoresTiny: response.data,
                success: true
            }
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

    async function createProductor(token, formData){
        try {
            const response = await axios.post(apiUrlProductor,formData,
                {
                    headers:{
                        Authorization: token
                    }
                })
            return {creation: response.data, success: true}
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

    async function updateProductor(token, id_productor, new_data_productor){
        try{
            const response = await axios.patch(apiUrlProductor+id_productor, new_data_productor, 
                {
                    headers: {
                        Authorization: token
                    }
                })
            return {modified: response.data, success: true}
        } catch (e){
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }

    async function deleteProductor(token, id_productor){
        try {
            const response = await axios.delete(apiUrlProductor+id_productor,
                {
                    headers: {
                        Authorization: token
                    }
                });

            return {deleted: response.data, success: true}
        } catch (e) {
            return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
        }
    }
    
    export  {
        getProductores,
        getTinyProductores,
        createProductor,
        updateProductor,
        deleteProductor
    };