<template>
    <!--Inicio navbar-->
    <b-navbar fixed="top" toggleable="lg" type="dark" id="navbar" class="navbar navbar-expand-lg">

        <!--Inicio menu de opciones-->
        <div class="container_main">
            <!--Imagen de flor-->
            <b-navbar-brand href="/" class="navbar-brand">
                <img class="logo_flor_img" alt="logo_flor_img" src="../../assets/menu_img/logo_flor.png">
            </b-navbar-brand>

            <div class="navbar_container_menu">

                <!--Contenedor de opciones (b-collapse para almacenamiento en btn hamburguesa)-->
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav class="navbar_nav_menu ">
                        <b-nav-item class="navbar_container_collapse_link" :to="{ name: 'home' }"
                            exact>Home</b-nav-item>
                        <b-nav-item class="navbar_container_collapse_link"
                            :to="{ name: 'productos', params: { categoria: this.$route.params.categoria ? this.$route.params.categoria : 'Todos' } }">Productos</b-nav-item>
                        <b-nav-item class="navbar_container_collapse_link"
                            :to="{ name: 'conocenos' }">Conocenos</b-nav-item>
                        <b-nav-item class="navbar_container_collapse_link" :to="{ name: 'tiendas_fisicas' }">Tiendas
                            fisicas</b-nav-item>
                        <b-nav-item class="navbar_container_collapse_link"
                            :to="{ name: 'productores' }">Productores</b-nav-item>
                        <b-nav-item class="navbar_container_collapse_link"
                            :to="puedeAcceder ? '/compras' : '/login'">Compras</b-nav-item>
                        <!-- this.$router.fullPath -->
                    </b-navbar-nav>
                </b-collapse>
            </div>
            <div class="navbar_container_search">
                <!-- busqueda-->
                <b-navbar-nav class="navbar_container_collapse_search">
                    <b-form class="navbar_container_collapse_search_from" @submit.prevent="searchProducts">
                        <b-form-input autocomplete="off" @input="searchProducts" v-model="searchQuery" class="mr-sm-2"
                            id="input_search" placeholder="Search"></b-form-input>
                        <b-button id="btn_buscar" type="submit"><img class="Buscar_img" alt="Buscar_img"
                                src="../../assets/menu_img/buscar.png"></b-button>
                    </b-form>
                </b-navbar-nav>
                <ul class="lista_busqueda" v-if="searchQuery">
                    <li class="producto_busqueda" v-if="searchQuery" v-for="product in filteredProducts"
                        :key="product.id" @click="redireccion_producto(product.id)">
                        {{ product.nombre }} {{ product.cantidad_medida }}{{ product.unidad_medida }}
                    </li>
                    <li class="producto_busqueda" v-if="filteredProducts.length === 0" style="color: red;">
                        No se encontraron resultados
                    </li>
                </ul>
            </div>

            <div class="navbar_container_cart_avatar">
                <div class="navbar_container_cart_avatar_cart">
                    <span class="navbar_container_cart_avatar_cart-notification">{{ this.num_productos }}</span>
                    <img id="show-button-car" @click="Acceso_car()" class="Carrito_shop_img" alt="Carrito_shop_img"
                        src="../../assets/menu_img/carrito_shop.png">
                </div>
                <a :href="'/login'"> <img class="Usuario_img" alt="Usuario_img"
                        src="@/assets/menu_img/usuario.png"></a>

                <div class="div_cerrar_sesion">
                    <img v-if="isvisible_cerrar_sesion" class="btn_cerrar_sesion" @click="cerrar_sesion()"
                        :src="require('@/assets/home_img/cerrar_sesion.png')">
                </div>
            </div>
            <!--Btn hamburguesa de opciones-->
            <b-navbar-toggle target="nav-collapse" id="buttom_toggle"></b-navbar-toggle>

            <!--lista del carrito-->
            <div v-if="!$store.state.loading" class="div_list_car">
                <h1>CARRITO</h1>
                <div class="div_productos_car" v-for="producto in responsCarrito" :key="producto.id">
                    <div class="card_productos_car">
                        <div class="card-body"
                            :style="{ borderColor: producto.cantidad > producto.stock_prod ? 'red' : '' }">
                            <p class="txt_stock_insuficiente"
                                :style="{ display: producto.cantidad > producto.stock_prod ? 'flex' : 'none' }">STOCK
                                INSUFICIENTE, ESTE PRODUCTO NO SE CONTARA EN SU LISTA</p>
                            <div class="div_controls">
                                <div class="div_info_producto">
                                    <p style="margin: 0;width: 100% ;text-align: center; cursor: pointer;"
                                        @click="redireccion_producto(producto.id_producto)"><b>{{
                            primeraLetraMayuscula_text(producto.nom_producto) }}</b><br>({{
                            producto.cantidad_medida }}{{ producto.unidad_medida }})</p>
                                </div>
                                <div class="div_eliminar">
                                    <img class="btn_eliminar"
                                        @click="Eliminar_producto(producto.id_carrito, producto.id_producto, producto.cantidad, producto.stock_prod)"
                                        :src="require('@/assets/menu_img/borrar.png')">
                                </div>
                            </div>
                            <div class="div_contenido">
                                <div class="div_img">
                                    <img v-if="producto.imagenes" @click="redireccion_producto(producto.id_producto)"
                                        alt="punto_tuxtleco_img"
                                        :src="`https://api.puntotuxtleco.com.mx/img/productos/${producto.imagenes[0].uri_img}`">
                                    <div class="div_info_producto">
                                        <p
                                            style="white-space: nowrap; margin-top: 1rem;color: orangered; border: solid 2px orangered; border-radius: .5rem; text-align: center;">
                                            ${{ producto.precio }} MXN</p>
                                    </div>
                                </div>

                                <div class="div_controls">
                                    <div class="div_cantidad">
                                        <div class="div_botones">
                                            <button @click="Disminuir_cantidad(producto)">–</button>
                                        </div>
                                        <p style="margin: 0%;text-align: center; color: rgb(49, 49, 49);width: 1rem;">
                                            <b>{{
                            producto.cantidad }}</b></p>
                                        <div class="div_botones">
                                            <button @click="Aumentar_cantidad(producto)">+</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="text_total_pagar"><b>Subtotal:</b> ${{ this.totalCarrito }} MXN</p>
                <div class="div_btn_finalizar">
                    <button id="finalizar_btn" @click="finalizarCompra">Finalizar compra</button>
                </div>
            </div>
        </div>

        <!--Fin menu de opciones-->

    </b-navbar>
    <!--Fin navbar-->
</template>

<script>
import {
    getCars_details, getJoinProductos,
    getProducto, updateCarrito_detalle, updateStock,
    Delete_Producto_carrito
} from '@/services/ProductosService.js'
import { getOrdenarPalabras } from '@/services/MetodosAux.js'
import { primeraLetraMayuscula } from '@/services/MetodosAux.js'
import { mapState, mapMutations } from 'vuex';


export default {
    name: 'Menu_navbar',
    divVisible_carrito: 'none',
    watch: {
        'cantidad_productos'(to, from) {
            this.toggleDiv();
        }
    },
    created() {
        this.$root.$on('ocultar_carrito', () => {
            this.ocultar_car();
        });
    },
    computed: {
        ...mapState(['cantidad_productos']),
    },
    data() {
        return {
            responsCarrito: {},
            responsProductos: {},
            totalCarrito: 0,
            num_productos: 0,
            puedeAcceder: false,
            isvisible_cerrar_sesion: true,
            cantidad_db_prod: 0,
            searchQuery: '',
            responseProducto: [],
            filteredProducts: [], // Agrega esta línea   
        }
    },
    methods: {
        primeraLetraMayuscula_text(texto) {
            // Usa la función importada para capitalizar la primera letra
            return primeraLetraMayuscula(texto);
        },
        redireccion_producto(idProducto) {
            const rutaActual = this.$router.currentRoute.fullPath;
            const partesDeRuta = rutaActual.split('/');
            if (partesDeRuta.includes('productos')) {
                const nuevaRuta = this.$router.resolve({
                    name: 'productos', params: {
                        id_producto: idProducto
                    }
                }).href;
                window.location.href =  nuevaRuta;
                // console.log('entro a productos---URL: ')
            } else {

                window.location.href = 'https://puntotuxtleco.com.mx/productos/Todos/' + idProducto;
            }

        },
        async searchProducts() {
            try {
                // Aquí puedes agregar lógica adicional si es necesario            
                const productos = await getJoinProductos()
                this.responseProducto = getOrdenarPalabras(productos)
                const query = this.normalizeString(this.searchQuery);
                this.filteredProducts = this.responseProducto.filter(product => this.normalizeString(product.nombre).includes(query));
            } catch (e) {
                console.log('Error de busqueda');
            }
        },
        ...mapMutations(['setCantidadProductos']),
        normalizeString(str) {
            // Remover acentos y convertir a minúsculas
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        },
        cerrarAlerta() {
            try {
                // Actualizar el estado en el componente padre        
                this.$store.commit('setactivar_alerta', false);
            } catch (error) {
            }
        },
        cerrar_sesion() {
            try {
                this.$store.commit('setLoading', true);
                this.$cookies.remove('id_user')
                this.$cookies.remove('token')
                this.$cookies.remove('id_carrito')
                this.$cookies.remove('role')
                this.num_productos = 0
                this.puedeAcceder = false
                this.isvisible_cerrar_sesion = false
                window.history.pushState({}, '', '/');
    window.history.replaceState({}, '', '/');
                window.location.href = '/';
                this.$store.commit('setLoading', false);
            } catch (error) {
                this.$store.commit('setLoading', false);
            }

        }
        ,
        async Eliminar_producto(idCarrito, idProducto, cantidad) {
            try {
                this.$store.commit('setLoading', true);
                const token = this.$cookies.get('token')
                this.responsProductos = await getProducto(idProducto)
                // await updateStock(idProducto, (cantidad + this.responsProductos.cantidad), token)
                this.num_productos--
                await Delete_Producto_carrito(idCarrito, idProducto, token)
                this.$store.commit('setLoading', false);
                // window.location.href = window.location.href
            } catch (e) {
                console.log('Error al eliminar el producto del carrito \n' + e)
                this.$store.commit('setLoading', false);
            }
        },
        async Aumentar_cantidad(producto) {
            try {
                const token = this.$cookies.get('token')
                this.responsProductos = await getProducto(producto.id_producto)
                if (this.responsProductos.cantidad > producto.cantidad) {
                    this.$store.commit('setLoading', true);
                    producto.cantidad += 1
                    await updateCarrito_detalle(producto.id_carrito, producto.id_producto, producto.cantidad, this.$cookies.get('token'))
                    // await updateStock(producto.id_producto, (this.responsProductos.cantidad - 1), token)
                    this.toggleDiv()
                    this.$root.$emit('fetchdata');
                } else {
                    this.$store.commit('setactivar_alerta', true);
                    this.$store.commit('setmensaje_alerta', 'El producto no cuenta con suficiente stock');
                }
                this.$store.commit('setLoading', false);
            } catch (e) {
                console.log('Error al aumentar la cantidad: ' + e)
                this.$store.commit('setLoading', false);
            }
        },
        async Disminuir_cantidad(producto) {
            try {
                const token = this.$cookies.get('token')
                this.responsProductos = await getProducto(producto.id_producto)
                if (producto.cantidad > 1) {
                    this.$store.commit('setLoading', true);
                    producto.cantidad -= 1
                    await updateCarrito_detalle(producto.id_carrito, producto.id_producto, producto.cantidad, token)
                    // await updateStock(producto.id_producto, (this.responsProductos.cantidad + 1), token)
                    this.toggleDiv()
                    this.$root.$emit('fetchdata');
                }
                this.$store.commit('setLoading', false);
            } catch (e) {
                console.log('Error al aumentar la cantidad' + e)
                this.$store.commit('setLoading', false);
            }
        },
        finalizarCompra() {
            if (this.totalCarrito != 0) {
                this.$router.push({ name: 'desfile_pago' });
            } else {
                this.$store.commit('setactivar_alerta', true);
                this.$store.commit('setmensaje_alerta', 'Ingresa algun producto');
            }
        },
        inicio_sesion() {
            const id_user = this.$cookies.get('id_user')
            if (id_user) {
                this.puedeAcceder = true
            } else {
                this.puedeAcceder = false
            }
        },
        async Acceso_car() {
            this.token = this.$cookies.get('token')
            this.id_user = this.$cookies.get('id_user')
            if (this.id_user != null || this.token != null) {
                this.responsCarrito = await getCars_details(this.$cookies.get('id_carrito'), this.$cookies.get('token'))
                if (this.responsCarrito) {
                    // Obtener las claves del objeto como un array y asi tener el numero de productos
                    const claves = Object.keys(this.responsCarrito);
                    // this.cantidad_productos = claves.length;
                    this.setCantidadProductos(claves.length)
                    // console.log(this.cantidad_productos)
                    this.num_productos = this.cantidad_productos

                    let total = 0
                    this.responsCarrito.forEach(element => {
                        if (element.cantidad <= element.stock_prod) {
                            total += element.precio * element.cantidad;
                        }
                    });
                    this.totalCarrito = total
                    this.divVisible_carrito = !this.divVisible_carrito;
                } else {
                    this.$store.commit('setactivar_alerta', true);
                    this.$store.commit('setmensaje_alerta', 'Aun no has añadido un producto');
                }

            } else {
                window.location.href = 'https://puntotuxtleco.com.mx/login';
            }
        }
        ,
        async toggleDiv() {

            try {
                this.token = this.$cookies.get('token')
                this.id_user = this.$cookies.get('id_user')
                if (this.id_user != null || this.token != null) {//verifica que inicio sesion
                    this.responsCarrito = await getCars_details(this.$cookies.get('id_carrito'), this.$cookies.get('token'))
                    this.$store.commit('setLoading', true);
                    // Obtener las claves del objeto como un array y asi tener el numero de productos
                    const claves = Object.keys(this.responsCarrito);
                    // this.cantidad_productos = claves.length;
                    this.setCantidadProductos(claves.length)
                    // console.log(this.cantidad_productos)
                    this.num_productos = this.cantidad_productos

                    let total = 0
                    this.responsCarrito.forEach(element => {
                        if (element.cantidad <= element.stock_prod) {
                            total += element.precio * element.cantidad;
                        }
                    });
                    this.totalCarrito = total
                    this.divVisible_carrito = !this.divVisible_carrito;
                }
                if (this.$cookies.get('token') && this.$cookies.get('id_user')) {
                    this.isvisible_cerrar_sesion = true
                } else {
                    this.isvisible_cerrar_sesion = false
                }
                this.$store.commit('setLoading', false);
            } catch (e) {
                this.$store.commit('setLoading', false);
            }
        }
        ,
        ocultar_car() {
            try {

                const div_Categoria = document.querySelector('.div_list_car');
                if (!this.divVisible_carrito) {
                    div_Categoria.animate(
                        [
                            { opacity: 1 }, // Desde la opacidad 1
                            { opacity: 0 }, // Hasta la opacidad 0
                        ],
                        {
                            duration: 400, // Duración en milisegundos (0.7 segundos)
                            easing: 'ease-in-out', // Función de temporización
                            iterations: 1, // Número de repeticiones
                            onfinish: () => {
                                this.toggleDiv();
                                div_Categoria.style.display = 'none';
                            }
                        }
                    );
                    setTimeout(() => {
                        if (div_Categoria.style.display == 'flex') {
                            div_Categoria.style.display = 'none';
                            this.toggleDiv();
                        }
                    }, 350);
                }
            } catch (error) {

            }
        },
        ocultat_mostrar_car() {
            try {
                const div_Categoria = document.querySelector('.div_list_car');
                const validToggler = document.getElementById('buttom_toggle');
                const ocultaMenu = document.getElementById('nav-collapse');

                if (validToggler.getAttribute('aria-expanded') == "true") {
                    ocultaMenu.style.display = 'none'
                    ocultaMenu.classList.remove("show");
                    validToggler.classList.remove("not-collapsed");
                    validToggler.classList.add("collapsed")
                    validToggler.setAttribute('aria-expanded', 'false');
                }
                if (this.divVisible_carrito) {
                    div_Categoria.animate(
                        [
                            { opacity: 0 }, // Desde la opacidad 0
                            { opacity: 1 }, // Hasta la opacidad 1
                        ],
                        {
                            duration: 700, // Duración en milisegundos (0.7 segundos)
                            easing: 'ease-in-out', // Función de temporización
                            iterations: 1, // Número de repeticiones
                        }

                    );
                    div_Categoria.style.display = 'flex'; // Mostrar el div   
                } else {
                    div_Categoria.animate(
                        [
                            { opacity: 1 }, // Desde la opacidad 1
                            { opacity: 0 }, // Hasta la opacidad 0
                        ],
                        {
                            duration: 400, // Duración en milisegundos (0.7 segundos)
                            easing: 'ease-in-out', // Función de temporización
                            iterations: 1, // Número de repeticiones                        
                            onfinish: () => {
                                this.toggleDiv();
                                div_Categoria.style.display = 'none';
                            }
                        }
                    );
                    setTimeout(() => {
                        div_Categoria.style.display = 'none';
                    }, 350);
                }
            } catch (error) {

            }
        }
    },
    mounted() {
        //cerrar carrito cuando este en flex
        const ButtonCar = document.getElementById('show-button-car');
        const ButtonMenu = document.querySelector('.navbar_container_menu');
        const validToggler = document.getElementById('buttom_toggle');
        const nav_collapse = document.getElementById('nav-collapse');
        nav_collapse.addEventListener('click', () => {
            this.inicio_sesion();
        });
        validToggler.addEventListener('click', () => {
            this.ocultar_car();
        });
        ButtonCar.addEventListener('click', () => {
            this.ocultat_mostrar_car();
        });
        ButtonMenu.addEventListener('click', () => {
            this.ocultar_car();
        });
        this.toggleDiv();
        this.inicio_sesion();
    }
}
</script>

<style scoped>
.txt_stock_insuficiente {
    font-size: smaller;
    font-family: Arial, Helvetica, sans-serif;
    color: red;
    font-weight: 600;
}

.div_img img {
    /* border: solid px; */
    color: rgb(195, 223, 248);
    cursor: pointer;
    border-radius: 0.3rem;
    width: 6rem;
    height: 6rem;
    max-height: 7rem;
    max-width: 7rem;
}

.div_info_producto {
    width: 100%;
    /* white-space: nowrap; */
    text-align: justify;
    margin-left: .3rem;
    margin-right: .3rem;
}

.div_contenido {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.div_cerrar_sesion img {
    margin-left: 15px;
    width: 28px;
    cursor: pointer;
}

.div_cantidad {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin: 0;
    width: 100%;
}

.div_controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-direction: row;
    width: 100%;
}

.div_eliminar {
    width: auto;
    display: flex;
    align-items: center;
}

.div_eliminar img {
    width: 2rem;
    height: 2rem;
}

.div_list_car {
    border: solid .2rem;
    border-radius: .5rem;
    position: fixed;
    width: 300px;
    max-height: 300px;
    height: 400px;
    max-height: 400px;
    top: 3.5rem;
    right: 0.1rem;
    background: #e7e7e7;
    flex-direction: column;
    overflow-y: auto;
    display: none;
}

.div_list_car h1 {
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
    border-bottom: solid;
    border-right: solid;
    width: 90%;
    display: flex;
    justify-content: center;
    align-self: center;
    margin-top: .3rem;
    margin-left: .5rem;
    margin-right: .5rem;
    background-color: aliceblue;
    border-radius: 0.3rem;
}

.div_productos_car {
    flex-flow: column wrap;
    gap: 0.5rem;
}

.div_productos_car .card-body {
    background: white;
    border: solid 1px;
    margin: 0.5rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: .5rem;
}

.text_total_pagar {
    background-color: aliceblue;
    border-radius: 0.3rem;
    border-bottom: solid 3px;
    margin-left: .5rem;
    /* font-weight:600; */
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    margin-right: .5rem;
}

#finalizar_btn {
    border-radius: 5px;
    margin-bottom: 0.5rem;
    background-color: #4DD3F4;
    width: 100%;
}

#finalizar_btn:hover {
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    transform: scale(1.1);
}

/*Configuracion Gerneral*/

.header_tittle {
    background-color: #4DD3F4;
    width: 100%;
    height: 3.5rem;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    justify-items: center;
}

.container_main {
    width: 100% !important;
    display: flex !important;
    padding: 0 !important;
    height: 3.5rem !important;
    margin: 0 !important;
    justify-content: space-between !important;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgb(255, 255, 255);
}


/*Configuracion opciones y img flor*/
.navbar_container_menu {
    display: flex;
    margin: auto;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.navbar_container_collapse_link {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    transition-property: border color;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
}

.navbar_container_collapse_link:hover {
    color: #fff;
    background-color: rgba(0, 0, 255, 0.1);
    transition-property: border color;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    text-decoration: none;
    transform: scale(1.1);
}

nav a.router-link-exact-active {
    color: black !important;
    font-weight: 800;
    background-color: white;
}

@media (max-width: 1150px) {
    .navbar_container_collapse_link {
        font-size: 78%;
        font: bold;
    }
}

.navbar_nav_menu {
    width: auto;
}

.navbar-brand {
    display: flex !important;
    align-items: center !important;
    /* Centra verticalmente los elementos */
    margin-left: 15px;
    margin-right: 15px;
    padding: 0 !important;
}

.logo_flor_img {
    width: 50px;
}

.logo_flor_img:hover {
    animation-name: girab;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
}


#nav-collapse {
    opacity: 50%;
    width: 100%;
}

/*Configuracion Busqueda*/
#input_search {
    width: 100%;
    font-size: 75%;
}

.navbar_container_collapse_search {

    padding: 0;
    margin: 0;
    flex-direction: row;
    width: 100%;
}

.navbar_container_collapse_search_from {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    /* Centra verticalmente los elementos */
}

.navbar_container_search {
    margin: auto;
    margin-top: 0;
    margin-bottom: 0%;
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Buscar_img {
    width: 30px;
}

#btn_buscar {
    padding: 0;
    margin: 0;
    width: 8%;
    background-color: #4DD3F4;
    border: none;
}

.div_lista_busqueda {
    position: relative;
    display: flex;
}

.lista_busqueda {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 32%;
    height: auto;
    max-height: 20rem;
    top: 3rem;
    background-color: aliceblue;
    cursor: pointer;
    margin: 0;
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow-y: scroll;
}

#finalizar_btn,
.producto_busqueda,
.div_productos_car {
    font-family: 'Roboto', 'sans-serif';
}

.producto_busqueda {
    width: 100%;
    display: flex;
    background-color: aliceblue;
    font-size: 1rem;
    margin-bottom: .3rem;
    margin-top: .3rem;
    padding-right: .5rem;
    padding-left: .5rem;
    border-radius: 5px;
}

.producto_busqueda:hover {
    transition-duration: 0s;
    transition-timing-function: ease-in-out;
    transform: scale(1.01);
    background-color: #00b0c37a;

    cursor: pointer;
}

/*Configuracion Carrito y avatar*/
.navbar_container_cart_avatar {
    margin: auto;
    margin-right: 15px;
    margin-left: 25px;
    display: inline-flex;
    width: auto;
    justify-content: space-between;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
}

.navbar_container_cart_avatar_cart {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    /* overflow: hidden; */
    transition: transform 0.3s ease;
}

.Carrito_shop_img {
    position: relative;
    width: 30px;
    cursor: pointer;
}

.Buscar_img:hover,
.Usuario_img:hover,
.navbar_container_cart_avatar_cart:hover,
.btn_eliminar:hover,
.div_botones button:hover,
.div_cerrar_sesion img:hover {
    transform: scale(1.2);
    z-index: 1;
}

.div_botones button {
    display: flex;
    background-color: darkcyan;
    color: aliceblue;
    margin: .5rem;
    width: 1.8rem;
    height: 1.8rem;
    font-size: x-large;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    border-radius: 1rem;
}

.btn_eliminar {
    cursor: pointer;
}

#finalizar_btn {
    width: 10rem;
    background-color: darkcyan;
    color: aliceblue;
}

.Usuario_img {

    margin-left: 15px;
    width: 30px;
    cursor: pointer;
}

.navbar_container_cart_avatar_cart-notification {
    background-color: orangered;
    width: 15px;
    height: 12px;
    border-radius: 16px;
    position: absolute;
    top: -4px;
    right: 1px;
    font-size: 0.6em;
    text-align: center;
    color: white;
    z-index: 2;
}

@media (max-width: 100px) {
    .navbar_container_menu {
        width: 55%;
    }

    .navbar_container_collapse_link {
        font-size: 65%;
        font-weight: 1000;
    }
}

@media (max-width: 990px) {
    .navbar_container_menu {
        width: 90%;
    }
}

@media (max-width: 990px) {

    /*Configuracion Gerneral*/

    .navbar {
        height: auto;
    }

    /*Configuracion opciones y img flor*/
    .navbar_container_menu {
        margin: 0%;
        position: absolute;
        width: 100%;
        top: 3.5rem;
        /* Ajusta esto según la altura de tu navbar */
    }

    #nav-collapse ul li {
        display: flex;
        justify-content: center;
        border: white solid 1px;
    }

    #nav-collapse ul li:hover {
        background-color: rgba(2, 45, 53, 0.863);
    }

    .logo_flor_img {
        width: 37px;
        height: 37px;
        order: 0;
    }

    .navbar_container_menu {
        background-color: #4DD3F4;
        order: 4;
    }

    /*Configuracion Busqueda*/
    #input_search {
        width: 90%;
        font-size: 75%;
    }

    .navbar_container_search {
        /*Principal contenedor */
        width: 100%;
        display: flex;
        order: 1;
        align-items: center !important;
        /* Centra verticalmente los elementos */
    }

    .Buscar_img {
        width: 25px;
    }

    /*Configuracion Carrito y avatar*/

    .navbar_container_cart_avatar {
        margin: 0%;

        order: 2;
    }

    .Carrito_shop_img {
        width: 25px;
        margin-left: 10px;
        cursor: pointer;
    }

    .navbar-toggler {
        order: 3;
    }

    .Usuario_img {
        margin-left: 10px;
        width: 25px;
    }

    .div_cerrar_sesion img {
        margin-left: 10px;
        width: 23px;
        margin-right: 15px;
    }

    /*animaciones*/
    .nav-link {
        width: 100%;
    }

    .navbar_container_collapse_link {
        background-color: #00191f;
        border-radius: 0%;
        transition-property: none;
    }

    .navbar_container_collapse_link:hover {
        background-color: #4DD3F4 !important;
    }

    nav a.router-link-exact-active {
        background-color: #4DD3F4;
        border: solid white 5px;
        border-radius: 0%;
    }
}

/*ANIMACION METODOS */
@keyframes girab {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes iluminar {

    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

}

@keyframes sacudir {

    0% {
        transform: rotate(7deg);
        opacity: 1;
    }

    25% {
        transform: rotate(-7deg);
        opacity: 0;
    }

    50% {
        transform: rotate(5deg);
        opacity: 1;
    }

    75% {
        transform: rotate(-5deg);
        opacity: 0;
    }

    100% {
        transform: rotate(2deg);
        opacity: 1;
        opacity: 0;
        opacity: 1;
    }

}
</style>