<script>
    import ProductoAgregarForm from "@/components/AdminComponents/ProductoComponents/ProductoAgregarForm.vue";
    import ProductoEditarForm from "@/components/AdminComponents/ProductoComponents/ProductoEditForm.vue";
    import CircleLoader from "@/components/CircleLoader.vue";


    /* Importando servicios necesarios  categorias, qr, imagenes */
    import {getTinyProductores} from "@/services/ProductoresService";
    import CapacidadServices from "@/services/CapacidadService";
    import EmpaqueService from "@/services/EmpaqueService";
    import CategoriasService from "@/services/CategoriasService";
    import ImgProdService from "@/services/ImgProductosService";
    import QRService from "@/services/QRService";

    import {createProducto, updateProducto } from "@/services/ProductosService"

    export default{
        name: 'ProductosDetail',
        components: {
            ProductoAgregarForm,
            ProductoEditarForm,
            CircleLoader
        },
        mounted(){
            this.getAllData()
        },
        props: {
            producto: Object
        },
        data(){
            return{
                productoresTiny: [],
                capacidades: [],
                empaques: [],
                categorias: [],
                imagenesprod: [],
                qrs: [],
                stateCharge: false
            }
        },
        methods: {
            async createProducto(new_producto){
                const response = await createProducto(this.$cookies.get('token'), new_producto);
                if (response.success){
                    this.$router.push({ name: 'admin_productos' })
                    alert(`El producto ${new_producto.nombre} se ha añadido`);
                }else {
                    alert(`Algo ha salido mal tratando de agregar al producto ${new_producto.nombre} :(, por favor intente de nuevo mas tarde`)
                }
            },
            async editProducto(id_producto, edited_producto){
                const response = await updateProducto(this.$cookies.get('token'), id_producto, edited_producto)
                if(response.success){
                    this.$router.push({ name: 'admin_productos' })
                    alert(`El producto con id: ${id_producto} se ha modificado`);
                }else {
                    alert(`Algo ha salido mal tratando de modificar al producto con id: ${id_producto} :(, por favor intente de nuevo mas tarde`);
                }
            },
            async getAllData(){
                this.stateCharge = true
                this.getTinyProductores();
                this.getCapacidades();
                this.getEmpaques();
                this.getCategorias();
                this.getImagenesprod();
                this.getQRs();
                this.stateCharge = false
            },
            async getTinyProductores(){
                const response = await getTinyProductores()
                if (response.success) {
                    this.productoresTiny = response.productoresTiny
                }
            },
            async getCapacidades(){
                const response = await CapacidadServices.getCapacidades()
                if (response.success){
                    this.capacidades = response.capacidades
                }
            },
            async getEmpaques(){
                const response = await EmpaqueService.getEmpaques()
                if(response.success){
                    this.empaques = response.empaques
                }
            },
            async getCategorias(){
                const response = await CategoriasService.getCategorias()
                this.categorias = response
            },
            async getImagenesprod(){
                const response = await ImgProdService.getImagenes()
                if (response.success){
                    this.imagenesprod = response.imagenes
                }
            },
            async getQRs(){
                const response = await QRService.getQRs()
                if (response.success){
                    this.qrs = response.qrs
                }
            }
        },
        computed: {
            activateEdit: function(){
                return this.$route.params.hasOwnProperty('id')
            },
            textoHeader: function(){
                return (this.activateEdit) ? 'Editar' : 'Agregar'
            }
        }
    }
</script>

<template>
    <div :class="['view_detail']">
        <header :class="['header-form']">
            <router-link 
                :to="{ name: 'admin_productos'}"
            >
                <i class="fa-solid fa-arrow-left"></i> Volver
            </router-link>

            <h1 :class="['titulo_vista','tsm']"> {{ textoHeader }} producto</h1>

            <CircleLoader :activo="stateCharge" />
        </header>
        <!-- <ProductoEditarForm
            v-if="activateEdit"
            :producto="producto"
        /> -->
        <ProductoAgregarForm
            :producto="producto"
            :productoresTiny="productoresTiny"
            :categorias="categorias"
            :capacidades="capacidades"
            :empaques="empaques"
            :qrs="qrs"
            :imagenesprod="imagenesprod"
            @createProducto="createProducto"
            @editProducto="editProducto"
        />
    </div>
</template>

<style scoped>
    .view_detail {
        display: flex;
        flex-flow: column wrap;
        padding: 0rem 0.5rem;
        justify-content: flex-start;
    }

    .view_detail .header-form{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 0.3rem 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 7px 5px -3px;
        margin-bottom: 1rem;
    }

    .header-form a{
        color: black;
        font-family: 'Quicksand';
        font-weight: bold;
        font-size: 1.2rem;
    }
</style>