<template>
    <article class="container-pedido">
        <section :class="['header-pedido']">
            <h2> {{ nombreUser }} </h2>
            <span>ID: {{ pedido.id }} • {{ pedido.estado_pedido.estado }}</span>
        </section>
        <section :class="['body-pedido']">
            <div :class="['fecha-container']">
                <p :class="['fecha']">Fecha entrega: <strong>{{ fechaEntrega }}</strong></p>
                <p :class="['fecha']">Fecha solicitud: <strong>{{ pedido.fecha_solicitud }}</strong></p>
            </div>
            <p :class="['domicilio']">
                {{ domicilioToString }}
            </p>
            <footer>
                <p :class="['total-pedido']">Total pedido: <strong> {{ changeFormat(pedido.total) }}</strong></p>
                <router-link 
                    :to="{name: 'pedidoDetail', params: {id: pedido.id, pedido: pedido}}" 
                    :class="['btn-detail']"
                >
                    <span>
                        DETALLES <i class="fa-solid fa-circle-arrow-right fa-lg"></i>
                    </span>
                </router-link>
            </footer>
        </section>
    </article>
</template>

<script>

    export default {
        name: 'PedidoPost',
        props: {
            pedido: Object
        },
        data(){
            return{

            }
        },
        methods: {
            changeFormat(number){
                const formatter = new Intl.NumberFormat('es-MX',{
                    style: 'currency',
                    currency: 'MXN'
                })

                return formatter.format(number)
            }
        },
        computed: {
            domicilioToString: function (){
                const objDomicilio = this.pedido.domicilio
                let domicilio = `Ciudad: ${objDomicilio.ciudad}, Colonia: ${objDomicilio.colonia}, Calle: ${objDomicilio.calle}, #Ext: ${objDomicilio.num_ext}, #Int: ${objDomicilio.num_int}, CP: ${objDomicilio.c_p}`;

                return domicilio
            },
            nombreUser: function (){
                return `${this.pedido.usuario.nombre} ${this.pedido.usuario.apellido_p} ${this.pedido.usuario.apellido_m}`
            },
            fechaEntrega: function(){
                return (this.pedido.fecha_entrega===null) ? 's/f' : this.pedido.fecha_entrega
            }
        }
    }
</script>

<style scoped>
p {
    margin: 0; 
}

.container-pedido{
    align-self: start;

    background-color: #0DABD3;
    font-family: 'Roboto';
    border-radius: 0.5rem;
    border: solid 1px #e1e3e1;
    overflow: hidden;

    transition: 0.3s box-shadow ease-in;
}

.container-pedido:hover{
    box-shadow: 5px 5px 3px #e1e3e1;
}
        .header-pedido{
            color: white;
            font-weight: 600;
            padding: 0 0.5rem;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
        }

            .header-pedido h2{
                padding: 0;
                margin: 0;
                font-size: 0.95rem;
            }

            .header-pedido span{
                font-size: 0.95rem;
                text-transform: capitalize;
            }
        .body-pedido{
            background-color: white;

            display: flex;
            flex-flow: row wrap;
            padding: 0 0.5rem;
        }
        
            .body-pedido .fecha-container{
                margin-bottom: 0.3rem;

                flex-grow: 1;

                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                gap: 0 0.5rem;

            }

                .fecha-container .fecha{
                    align-items: center;
                    font-size: 0.9rem;
                }

            .body-pedido .domicilio{
                text-align: left;
                font-family: 'QuickSand';
                font-weight: 500;
                font-size: 0.9rem;
            }
            
            .body-pedido footer{
                margin-bottom: 0.3rem;

                flex-grow: 1;

                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
                align-items: center;
                gap: 0 0.5rem;
            }

                .body-pedido footer .total-pedido{
                    margin: 0;
                    font-size: 0.9rem;
                }

                .body-pedido footer .btn-detail{
                    background-color: #4dd3f4;
                    border-radius: 1rem;
                    border: solid 2px #0DABD3;
                    color: #000;
                    
                    font-weight: 600;
                    font-size: 0.8rem;
                    
                    padding: 0.3rem 0.5rem;

                    transition: .5s ease all;
                    position: relative;
                    overflow: hidden;
                }

                .btn-detail:hover{
                    text-decoration: none;
                }

                    .btn-detail span {
                        position: relative;
                        z-index: 2;
                        transition: .5s ease-in color;
                    }

                    .btn-detail:hover span{
                        color: aliceblue;
                    }

                .btn-detail::after{
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: #0DABD3;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    right: -200px;
                    transition: .5s ease-in-out all;
                }
                .btn-detail:hover::after{
                    right: 0;
                }
</style>