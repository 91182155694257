<script>
    export default{
        name: 'ImageForm',
        mounted(){
            this.getAllProductores() 
        },
        props:{
            productores: Array,
            imagenProd: Object,
        },
        data(){
            return{
                selectedFile: null,
                productor: {
                    id: 0,
                    nombre: ''
                },
                viejoNombreImg: '',
                nombreImg: '',
                extension: '',
                idProductor: ''
            }
        },
        emits:['getAllProductores','createImagenProd', 'updateNameImagenProd','updateImagenProd','deleteImagenProd'],
        methods: {
            async getAllProductores(){
                this.$emit('getAllProductores');
            },
            async createImagenProd(){
                if (this.selectedFile) {
                    const formData = new FormData();
                    formData.append('id', this.productor.id);
                    formData.append('uri_img', `${this.productor.id}/${this.selectedFile.name}`);
                    formData.append('file', this.selectedFile)

                    console.log({ formData })
                    this.$emit('createImagenProd',formData)
                    this.cancelUpload()
                }
            },
            async updateNameImgProd(){
                this.nombreImg = `${this.nombreImg}.${this.extension}`
                this.viejoNombreImg = `${this.viejoNombreImg}.${this.extension}`
                this.$emit('updateNameImagenProd', this.imagenProd.id, this.idProductor, this.nombreImg, this.viejoNombreImg)

                this.cancelUpload()
            },
            async updateImgProd(){
                this.viejoNombreImg = `${this.viejoNombreImg}.${this.extension}`
                const formData = new FormData();
                formData.append('id_productor',this.idProductor)
                formData.append('name_img',this.viejoNombreImg)
                formData.append('file',this.selectedFile)
                console.log({ formData })

                this.$emit('updateImagenProd',formData)
                this.cancelUpload()
            },
            async deleteImgProd(){
                if(confirm(`Esta seguro que desea eliminar la imagen con id ${this.imagenProd.id}?`)){
                    this.$emit('deleteImagenProd',this.imagenProd.id);
                    this.cancelUpload()
                }
            },
            handleFileChange(event){
                this.selectedFile = event.target.files[0];
                const img = document.getElementById('imgPreviewProducto');
                const reader = new FileReader()
                reader.onload = function (e) {
                    img.src = e.target.result;
                }
                reader.readAsDataURL(event.target.files[0])
                console.log(this.selectedFile.name)
            },
            activateInput(elementId){
                document.getElementById(elementId).click()
            },
            cancelUpload(){
                this.selectedFile = null
                this.$refs.fileImageInputImgProd.value = null
                this.productor = {
                    id: 0,
                    nombre:''
                }
            },
            splitNombreImg(){
                const splitUri = this.imagenProd.uri_img.split('/')
                this.idProductor = splitUri[0]
                if(splitUri[1]){
                    const splitNombreImg = splitUri[1].split('.')
                    this.nombreImg = splitNombreImg[0]
                    this.viejoNombreImg = splitNombreImg[0]
                    this.extension = splitNombreImg[1]
                }
            },
            hello(nameComp='empty component'){
                console.log(`hi form ${nameComp}`)
            }
        },
        computed:{
            hasFileSelected: function(){
                return (this.selectedFile===null) ? false : true
            },
            isEnableUpload: function(){
                return this.hasFileSelected&&(this.productor.id!==0)
            },
            titleBtnUpload: function() {
                return (this.isEnableUpload)?'de click para subir una nueva imagen':'por favor seleccione a un productor para habilitar la carga'
            },
            isEnableEditOptionImagen: function(){
                this.splitNombreImg()
                return this.imagenProd.id!==0
            },
            isDisableBtnEditName: function(){
                return this.viejoNombreImg === this.nombreImg
            }
        }
    }
</script>

<template>
    <form enctype="multipart/form-data">
        <input 
            :class="['input-file-img']"
            type="file" 
            id="fileimgprod" 
            ref="fileImageInputImgProd" 
            accept="image/*" 
            @change="handleFileChange"
        >
        <button
            v-show="!hasFileSelected"
            :class="['normal-btn','bbg']" 
            type="button"
            @click="activateInput('fileimgprod')"
        >
            Cargar imagen
        </button>

        <section
            v-show="hasFileSelected"
            :class="['preview-upload-img']"
        >
            <img 
                id="imgPreviewProducto" 
                alt="Imagen cargada" 
                loading="lazy"
                @click="activateInput('fileimgprod')"
            />
            <select v-model="productor">
                <option 
                    disabled 
                    :value="{id:0,nombre:''}"
                >
                    Seleccione un productor
                </option>
                <option 
                    v-for="productor in productores" :key="productor.id" 
                    :value="productor"
                > 
                    {{ productor.nombre }} 
                </option>
            </select>
            <div 
                :class="['button-container','start']"
            >
                <button 
                    :class="[{'normal-btn':isEnableUpload, 'disabled-btn':!isEnableUpload},'round']" 
                    type="button"
                    :title="titleBtnUpload"
                    :disabled="!isEnableUpload"
                    @click="createImagenProd"
                >
                    <i class="fa-solid fa-cloud-arrow-up"></i> Guardar
                </button>

                <button 
                    :class="['delete-btn','round']" 
                    type="button"
                    @click="cancelUpload"
                >
                    Cancelar
                </button>
            </div>
        </section>
        <div 
            v-show="hasFileSelected" 
            :class="['separator-line']"
        ></div>
        <section 
            v-show="isEnableEditOptionImagen"
            :class="['container-options']"
        >
            <h2 :class="['titulo_vista']">Opciones de imagen</h2>

            <div :class="['field_textarea','field-cyan']">
                <input
                    :class="['input_field','round']" 
                    type="text" 
                    v-model="nombreImg" 
                    id="nombre_imagen_prod"
                    placeholder="..."
                    title="Se muestra el nombre de la imagen"
                >
                <label :class="['msg','m-l']" for="nombre_imagen_prod">Nombre imagen</label>
                <span :class="['msg','m-r']">ext: .{{ extension }} </span>
            </div>
            
            <div
                :class="['button-container','start']"
            >
                <button 
                    :class="[{'normal-btn':!isDisableBtnEditName, 'disabled-btn':isDisableBtnEditName},'round']" 
                    type="button"
                    :disabled="isDisableBtnEditName"
                    @click="updateNameImgProd"
                >
                    Cambiar nombre
                </button>
                <button 
                    :class="[{'normal-btn':hasFileSelected, 'disabled-btn':!hasFileSelected},'round']" 
                    type="button"
                    :disabled="!hasFileSelected"
                    @click="updateImgProd"
                >
                    Actualizar Imagen
                </button>
                <button 
                    :class="[{'delete-btn':true, 'disabled-btn':false},'round']" 
                    type="button"
                    title="Elimina la imagen seleccionada"
                    @click="deleteImgProd"
                >
                    Eliminar
                </button>
            </div>
            <div :class="['separator-line']"></div>
        </section>
    </form>
</template>

<style scoped>
    form .input-file-img {
        display: none;
    }

    .preview-upload-img{
        display: flex;
        flex-flow: column wrap;
        align-content: center;
        justify-content: center;
        gap: 1rem 0.3rem;
    }

    .preview-upload-img img{
        align-self: center;
        max-width: 200px;
        min-width: 150px;
        padding: 0.3rem;
        
        border-radius: 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.50) 0px 5px 15px;
        cursor: pointer;
    }

    .preview-upload-img select{
        padding: 0.3rem;
        border: solid 1px #e3e5e7;
        background-color: white;
        font-family: 'Quicksand', sans-serif;
        border-radius: 0.3rem;
        font-size: 0.9rem;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    }

    .container-options .titulo_vista{
        text-align: left;
        padding: 0.3rem 0rem;
        
    }
    
    .separator-line{
        height: 2px;
        background-color: #ddd;
        margin-block: 0.3rem;
        
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.40);
    }
</style>