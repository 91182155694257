<template>
    <div :class="['header-container']">
        <header>
            <h1 :class="['title-view']">PuntoTuxtleco</h1>

            <div :class="['stage']">
                <li :class="['normal', { 'active': estado >= 1 }]"><span><i class="fa-solid fa-user fa-lg"></i></span>
                    Identificacion</li>
                <li :class="['normal', { 'active': estado >= 2 }]"><span><i class="fa-solid fa-box fa-lg"></i></span> Resumen
                </li><!--  -->
                <li :class="['normal', { 'active': estado >= 3 }]"><span><i class="fa-solid fa-credit-card fa-lg"></i></span>
                    Pago</li><!--  -->
            </div>
        </header>
    </div>
</template>

<script>
export default {
    name: 'HeaderPayComponent',
    props: {
        estado: {
            required: true
        }
    },
    data(props) {
        return {

        }
    }
}
</script>

<style scoped></style>