<template> 
    <!--Inicio navbar-->
    <b-navbar toggleable="md"  type="dark" id="navbar" class="navbar navbar-expand-md">
        <!--Inicio menu de opciones-->
        <div class="container_main">
            <p class="txt_derecho">© Derechos reservados 2024 – Punto Tuxtleco.</p>
            <div class="div_URLS">
                <a href="https://www.instagram.com/puntotuxtleco/" class="URL_instagram">
                    <img style="width: 30px;" class="instagram_img" alt="logo_flor_img" src="../../assets/inferior_navbar_img/Instagram.png">
                </a>
                <a href="https://www.facebook.com/puntotuxtleco" class="URL_facebook">
                    <img  style="width: 39px;" class="facebook_img" alt="logo_flor_img" src="../../assets/inferior_navbar_img/Facebook.png">
                </a>
                <!-- <a href="" class="URL_twiter">
                    <img style="width: 34px;" class="twitter_img" alt="logo_flor_img" src="../../assets/inferior_navbar_img/X-twitter.png">
                </a> -->
            </div>
        </div>
        <!--Fin menu de opciones-->
    </b-navbar>
    <!--Fin navbar-->
</template>

<script>
export default {
    name: 'Menu_navbar',
}
</script>

<style >
/*Configuracion Gerneral*/

#navbar {
    background-color: #4DD3F4;
    width: 100%;
    height: 3.5rem;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    justify-items: center;
}

.container_main {
    width: 100% !important;
    display: flex !important;
    padding: 0 !important;
    height: 3.5rem !important;
    margin: 0 !important;
    justify-content: space-between !important;
}
.txt_derecho{
    padding: 0%;    
    margin: 0%;
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-right: 15px;      
  font-family: 'Josefin Sans', sans-serif;  
}
.div_URLS{
    padding: 0%;    
    margin: 0%;
    display: flex;
    align-items: center;
    margin-right: 15px;
}
.URL_instagram:hover {
  transform: scale(1.2);
}
.URL_twiter:hover {
  transform: scale(1.2);
}
.URL_facebook:hover {
  transform: scale(1.2);
}
@media (max-width: 767px) {

    /*Configuracion Gerneral*/

    .navbar {
        height: auto;
    }

}</style>

