<template>
    <table :class="['component-table']">
        <thead>
            <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Descripcion</th>
                <th scope="col">Telefono</th>
                <th scope="col">Correo</th>
                <th scope="col">Imagen</th>
                <th scope="col">QR</th>
                <th scope="col">Opciones</th>
            </tr>
        </thead>
        
        <tbody>
            <tr v-for="element in productores" :key="element.id">
                <!-- EStas son las filas de mitabla entonces en el tr se hace el for -->
                <td><strong> {{ element.nombre }} </strong></td>
                <td :class="['text-left']"> <p :class="['descripcion-text']">{{ element.descripcion }}</p> </td>
                <td> {{ element.telefono }} </td>
                <td> {{ element.correo }} </td>
                <td :class="['element-img']"><img :src="uriImg(element.img)" alt="imagen_productor" loading="lazy"></td>
                <td :class="['element-img']"><img :src="uriImgQR(element.name_qr)" alt="imagen_productor" loading="lazy"></td>
                <td :class="['container-hiper']">
                    <router-link 
                        :class="['hiper_vin-warning']"
                        :to="{ name: 'editar_productor', params: {id: element.id, productor: element}}"
                    >
                        Editar
                    </router-link>
                    <a 
                        :class="['hiper_vin-error']"
                        @click="deleteProductor(element.id)"
                        href="#"
                    >
                        Eliminar
                    </a>
                    <!-- :class="['outline-btn','bbg','round']"  -->
                    
                </td>
            </tr>
            <tr>
                <td colspan="7">
                    <CircleLoader :activo="stateCharge" />
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import CircleLoader from "@/components/CircleLoader.vue";
import { getProductores, deleteProductor } from '../../../services/ProductoresService.js'

    export default{
        name: 'C-ProductorTable',
        components: {
            CircleLoader
        },
        data(){
            return{
                productores:[],
                productor: {},
                stateCharge: false
            }
        },
        mounted(){
            this.getInfoProductores()
        },
        methods: {
            async getInfoProductores(){
                /*Obtenemos info productores desde API*/
                this.stateCharge = true
                const response = await getProductores()
                this.productores = response
                this.stateCharge = false
            },
            async deleteProductor(id_productor){
                if(confirm(`Esta seguro que desea eliminar al producto con id ${id_productor}?`)){
                    this.stateCharge = true
                    const response = await deleteProductor(this.$cookies.get('token'), id_productor)
                    if (response.success) {
                        this.getInfoProductores()
                        alert(`Se elimino al productor con id ${id_productor}`);
                    }else{
                        alert('Algo ha salido mal :(, por favor intente de nuevo mas tarde...')
                    }
                    this.stateCharge = false
                }
            },
            uriImg(nameImg){
                const randomSuffix = Math.random().toString(36).substring(7)
                const uriImgProductores = 'https://api.puntotuxtleco.com.mx/img/productores/'
                return `${uriImgProductores}${nameImg}?${randomSuffix}`
            },
            uriImgQR(nameImgQR){
                const randomSuffix = Math.random().toString(36).substring(7)
                const uriImgQR = 'https://api.puntotuxtleco.com.mx/img/qr/'
                return `${uriImgQR}${nameImgQR}?${randomSuffix}`
            }
        }
    }
</script>

<style scoped>
    .element-img img{
        border-radius: 0.3rem;
        border: solid 1px #ccc;
    }

    .descripcion-text{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-wrap: pretty;
    }
</style>