import axios from "axios";
import store from '@/store'

const apiUrl = store.state.apiRender;//'http://localhost:3000/';
const apiUrlEstados = `${apiUrl}estado/`;

async function getEstados(token){
    try {
        const response = await axios.get(apiUrlEstados,
            {
                headers: {
                    Authorization: token
                }
            }
        )
        return {estados: response, success: true}
    } catch (e) {
        return { message: (e.response.data.message) ? e.response.data.message : 'Error', success: false }
    }
}

export default {
    getEstados,
}