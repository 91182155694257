<template>
    <!-- Alerta personalizada -->
    <div id="miAlerta" class="custom-alert" >
        <div class="pestaña_superior">
            <span class="close-btn" @click="cerrarAlerta"><i class="fa-solid fa-xmark"></i></span>
        </div>
        <div class="texto">
            <p>{{mensaje}}</p>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        // Función para cerrar la alerta
        cerrarAlerta() {
            try {                
            this.$emit('cerrarAlerta');
            } catch (error) {
            }
        }
    },
    props: {
//   activo: Boolean,
  mensaje: String
},
    }
</script>
<style scoped> /* Estilos para la alerta personalizada */
.custom-alert {    
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  color: black;
  border: 1px solid #999;
  border-radius: 5px;
  z-index: 999;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.texto{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 100%;
}
.texto p{
    width: auto;
    height: auto;
    padding: 0.5rem;
    font-family: 'Roboto';
    font-size: large;
    margin: 0;
}

.pestaña_superior{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
    padding-inline: 0.5rem;
    margin: 0;
    background-color: #0dabd3;
}
/* Estilos para el botón de cerrar */
.custom-alert .close-btn {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.3rem;
  color: black;
}
</style>